import { useEffect, useState } from "react"
import { useParams, useLocation } from "react-router-dom" // srini - 22-01-2025
import { Typography, Divider, Grid, IconButton, useMediaQuery } from '@mui/material'

import './blogdetail.css'

import imageOne from '../../../ui-assets/blog_art2.webp'
import imageOneA from '../../../ui-assets/blog_art2a.webp'
import imageTwo from '../../../ui-assets/blog_art5.webp'
import imageTwoA from '../../../ui-assets/blog_art5a.webp'
import imageThree from '../../../ui-assets/blog_art7.webp'
import imageThreeA from '../../../ui-assets/blog_art7a.webp'
import imageFour from '../../../ui-assets/blog_art8a.webp'
import imageFourD from '../../../ui-assets/blog_art8d.webp'
import imageFive from '../../../ui-assets/blog_art9.webp' // srini - 22-01-2025
import imageFiveD from '../../../ui-assets/blog_art9a.webp' // srini - 22-01-2025


import iconOne from '../../../ui-assets/blog_soc_one.png'
import iconTwo from '../../../ui-assets/blog_soc_two.png'
import iconThree from '../../../ui-assets/blog_soc_three.png'
import iconFour from '../../../ui-assets/blog_soc_four.png'
import iconFive from '../../../ui-assets/blog_soc_five.png'
import iconSix from '../../../ui-assets/blog_soc_six.png'
import iconSeven from '../../../ui-assets/blog_soc_seven.png'


import RegistrationBlog from "../Articles/RegistrationBlog";
import ExcellenceBlog from "../Articles/ExcellenceBlog";
import NavigateStock from "../Articles/NavigateStock"
import SimplyInvest from "../Articles/SimplyInvest"
import BestOnlineTradingplatform from "../Articles/BestOnlineTradingplatform" // srini - 22-01-2025
import Footer from '../../landingpage/fotter'

const BlogDetail = () => {
    const isSmallScreen = useMediaQuery('(min-width: 750px)')
    const is500px = useMediaQuery('(max-width: 500px)')
    const is960px = useMediaQuery('(min-width: 960px)')
    const { blogId } = useParams()
    const location = useLocation() // srini - 22-01-2025

    const [showSocialIcons, setShowSocialIcons] = useState(false)

    const blogs =
        [
            {
                id: 1,
                title: 'BullForce Registration Process: Open your account in Just 6 Minutes!',
                author: 'BULLFORCE',
                followers: 130,
                date: 'October 23, 2024',
                content: {
                    intro: 'In the competitive world of trading, time is of the essence. BullForce has streamlined its registration process, allowing you to sign up in just six minutes! With our innovative AI-driven approach, we ensure that your entry into the trading world is quick, efficient, and user-friendly. This blog will guide you through the registration process, highlight the benefits of joining BullForce, and explain why our platform is the ideal choice for traders.',
                    whatIsBullforce: 'In the competitive world of trading, time is of the essence. BullForce has streamlined its registration process, allowing you to sign up in just six minutes! With our innovative AI-driven approach, we ensure that your entry into the trading world is quick, efficient, and user-friendly. This blog will guide you through the registration process, highlight the benefits of joining BullForce, and explain why our platform is the ideal choice for traders.',
                    keyFeatures: [
                        'AI-Driven Insights: Our platform uses artificial intelligence to analyze market trends and provide actionable insights.',
                        'User-Friendly Interface: Designed for ease of use, our platform allows traders to navigate effortlessly.',
                        'Real-Time Market Data: Access comprehensive market data to make informed trading decisions.',
                        'Robust Security Measures: We prioritize the safety of your information and funds with advanced security protocols',
                    ],
                    steps: [
                        {
                            step: 'Visit the BullForce Website',
                            description: 'Start by navigating to the official BullForce website. The homepage is designed to be intuitive, guiding you directly to the registration section.',
                        },
                        {
                            step: 'Click on "Register"',
                            description: 'Locate the "Register" button prominently displayed on the homepage. A single click will lead you to the registration form.',
                        },
                        {
                            step: 'Fill Out Your Information',
                            description: 'Our AI-driven system will assist you in entering your details, such as your name, email address, and phone number. This step is quick and straightforward, ensuring you don’t feel overwhelmed.',
                        },
                        {
                            step: 'Verification',
                            description: 'Once you’ve filled out your information, our AI will verify it in real-time. You’ll receive a verification code via email or SMS—simply enter it into the designated field to proceed.',
                        },
                        {
                            step: 'Set Up Your Profile',
                            description: 'After verification, you can customize your profile by providing additional information about your trading preferences. This helps BullForce tailor your experience and recommendations.',
                        },
                        {
                            step: 'Review and Submit',
                            description: 'Before finalizing your registration, take a moment to review all entered information. Once satisfied, click the "Submit" button. Congratulations! You’re now part of the BullForce community.',
                        },
                    ],
                    benefits: [
                        'Speed and Efficiency: Complete your registration in just six minutes—no lengthy forms or complicated processes.',
                        'AI Integration: Our AI technology ensures quick and accurate processing of your information.',
                        'User-Friendly Design: Our platform is designed with simplicity in mind, making navigation easy for everyone',
                        'Personalized Experience: By gathering your preferences during registration, we can offer tailored recommendations that enhance your trading journey.',
                    ],
                    whyChoose: 'Choosing BullForce means opting for a platform that prioritizes user experience while leveraging advanced technology. Our commitment to innovation ensures that you have access to the best tools for successful trading. With a focus on community and support, we empower our users to make informed decisions and achieve their financial goals.',
                    callAction: 'Ready to experience a hassle-free registration process? Join BullForce today and complete your registration in just six minutes! Visit our website now and take the first step towards unlocking new trading opportunities.',
                    conclusion: 'BullForce is dedicated to providing an efficient and user-friendly experience for all our users. Our AI-driven registration process not only saves time but also enhances security and accuracy. Don’t let cumbersome processes hold you back—register with BullForce today and dive into a world of trading possibilities!',
                },
                image: is500px ? imageOneA : imageOne,
            },
            {
                id: 2,
                title: '25 Years of Excellence: How BullForce Redefines Client Experience in Broking.',
                author: 'BULLFORCE',
                followers: 130,
                date: 'October 28, 2024',
                content: {
                    intro: 'In the fast-paced world of finance, where market dynamics shift rapidly, the importance of a superior client experience cannot be overstated. BullForce, representing Maliram Makharia Finstock Private Limited (MMFPL), has established itself as a leader in the Indian broking industry over the past 25 years. This blog explores how the firm has redefined client experience through innovative practices, personalized services, and a commitment to excellence.',
                    aLegacyOfTrust: 'Founded in 1996, BullForce has built a reputation for reliability and professionalism in the Indian equity and derivative markets. With over two decades of experience, the firm has navigated various market cycles, gaining invaluable insights into client needs and preferences. This extensive experience allows the company to provide tailored solutions that resonate with both novice and seasoned investors.',
                    understandingClientNeeds: 'At the heart of the firm"s approach is a deep understanding of client needs. The organization employs a client-centric philosophy, ensuring that every interaction is meaningful and productive. By actively listening to clients and soliciting feedback, the company continually refines its services to align with evolving expectations. This commitment to understanding client needs has fostered long-lasting relationships built on trust and transparency',
                    personalizedInvestmentSolutions: 'Recognizing that each investor has unique goals and risk appetites, the firm offers personalized investment solutions. Clients benefit from customized portfolios that reflect their individual financial objectives, whether it"s wealth accumulation, retirement planning, or capital preservation. Experienced advisors work closely with clients to develop strategies that suit their specific circumstances, ensuring that they feel valued and understood.',
                    leveragingTechnology: 'In today\'s digital age, technology plays a crucial role in enhancing client experience. BullForce has invested in cutting-edge trading platforms that offer real-time data, advanced analytics, and user-friendly interfaces. These technological advancements empower clients to make informed decisions and execute trades efficiently. Furthermore, the integration of mobile trading applications allows clients to manage their investments on the go, providing convenience and flexibility.',
                    comprehensiveResearch: 'To navigate the complexities of the stock market, investors require access to reliable information and insights. The firm provides comprehensive research reports, market analysis, and expert commentary to help clients stay informed about market trends and opportunities. This wealth of information not only aids in decision-making but also instills confidence in clients, knowing they have the support of knowledgeable professionals.',
                    proactiveCustomerSupport: 'Exceptional client experience is not just about the services offered; it also includes the support provided throughout the investment journey. The organization prides itself on its proactive customer support. The dedicated team is readily available to address queries, resolve issues, and provide guidance whenever needed. This commitment to responsive service ensures that clients feel supported at every stage of their investment journey.',
                    educationalInitiatives: 'Understanding that informed clients are empowered clients, the firm conducts regular educational workshops, webinars, and seminars aimed at enhancing financial literacy. These initiatives cover a wide range of topics, from basic investing principles to advanced trading strategies. By equipping clients with knowledge, the organization fosters a culture of informed investing, enabling clients to make sound financial decisions.',
                    buildingACommunity: 'Beyond transactions, the firm aims to build a community of investors. It encourages clients to engage with one another through forums and social media platforms, facilitating discussions on market trends and investment strategies. This sense of community not only enhances the client experience but also allows investors to learn from one another, fostering collaboration and shared knowledge.',
                    conclusion: 'As BullForce celebrates 25 years of excellence in broking, its commitment to redefining client experience remains unwavering. Through personalized services, technological innovation, comprehensive research, and proactive support, the firm continues to set the standard for client satisfaction in the financial services industry. For investors seeking a partner that prioritizes their needs and empowers them on their investment journey, this organization stands out as a trusted choice. As the firm looks to the future, it remains dedicated to enhancing the client experience and helping investors achieve their financial aspirations.',
                    callAction: 'For more information on how BullForce can assist you in your investment journey, visit our website or contact our team of experts today.',
                },
                image: is500px ? imageTwoA : imageTwo
            },
            {
                id: 3,
                title: "Essential Tips for New Investors in the Indian Stock Market",
                author: "BULLFORCE",
                followers: 130,
                date: "December 18, 2024",
                content: {
                    intro: "The Indian stock market, with its dynamic nature and potential for wealth creation, can be both exciting and intimidating for new investors. As the market continues to evolve, understanding the fundamentals and adopting strategic approaches becomes crucial for success. Here are essential tips to help new investors navigate the Indian stock market effectively.",
                    educateYourself: "Before diving into the stock market, it is vital to educate yourself about its workings. Familiarize yourself with key concepts such as stocks, bonds, mutual funds, and derivatives. Understanding the differences between these investment vehicles can help you make informed decisions. Numerous online resources, books, and courses are available to enhance your knowledge.",
                    setClearInvestmentGoals: "Establishing clear investment goals is a critical first step. Determine what you want to achieve through your investments—whether it's long-term wealth accumulation, saving for retirement, or funding a child's education. Your goals will guide your investment strategy and help you stay focused during market fluctuations.",
                    understandYourRiskTolerance: "Every investor has a unique risk tolerance, which is influenced by factors such as age, financial situation, and investment goals. Assess your risk appetite before investing. Generally, younger investors can afford to take more risks, while those nearing retirement may prefer safer investments. Knowing your risk tolerance will help you select suitable investment options.",
                    createADiversifiedPortfolio: "Diversification is a fundamental principle of investing. By spreading your investments across various asset classes and sectors, you can mitigate risks associated with market volatility. A well-diversified portfolio may include a mix of equities, fixed income, and alternative investments. This strategy helps balance potential losses in one area with gains in another.",
                    conductThoroughResearch: "Before investing in any stock, conduct thorough research. Analyze the company’s financial statements, management team, industry position, and market trends. Fundamental analysis, which evaluates a company's intrinsic value, is essential for making informed investment decisions. Additionally, keep an eye on macroeconomic factors that may impact the market.",
                    adoptALongTermPerspective: "The stock market can be volatile in the short term, with prices fluctuating based on various factors. New investors should adopt a long-term perspective, focusing on the potential for growth over time rather than short-term gains. Historically, the stock market has yielded positive returns over extended periods, making it essential to remain patient and avoid impulsive decisions.",
                    utilizeTechnicalAnalysis: "Technical analysis involves studying price charts and patterns to identify potential entry and exit points for trades. While it may seem complex, learning the basics of technical analysis can enhance your trading strategy. Key indicators such as moving averages, Relative Strength Index (RSI), and support and resistance levels can provide valuable insights into market trends.",
                    implementRiskManagementStrategies: "Protecting your capital is crucial in the stock market. Implement risk management strategies, such as setting stop-loss orders, which automatically sell a stock when it reaches a predetermined price. This approach helps limit losses and preserves your investment capital. Additionally, regularly review and adjust your portfolio to align with changing market conditions.",
                    stayInformedAboutMarketTrends: "Keeping abreast of market news and trends is essential for successful investing. Follow reputable financial news sources, subscribe to market analysis reports, and participate in investment forums. Understanding market sentiment and economic indicators can help you make timely investment decisions.",
                    seekProfessionalAdvice: "If you are uncertain about navigating the stock market, consider seeking professional advice. Financial advisors can provide personalized investment strategies based on your goals and risk tolerance. They can also help you stay disciplined and focused, especially during turbulent market conditions.",
                    conclusion: "Navigating the Indian stock market requires a combination of knowledge, strategy, and discipline. By educating yourself, setting clear goals, diversifying your portfolio, and staying informed, you can enhance your chances of success. Remember, investing is a journey that demands patience and continuous learning. With the right approach, new investors can unlock the potential of the Indian stock market and work towards achieving their financial aspirations.",
                    callAction: "For those looking for guidance and support in their investment journey, consider partnering with BullForce, where experienced professionals are ready to assist you in making informed decisions and achieving your financial goals."
                },
                image: is500px ? imageThreeA : imageThree
            },
            {
                id: 4,
                title: "Simplify Investing with BullForce’s Comprehensive Financial Calculators!",
                author: "BULLFORCE",
                followers: 131,
                date: "November 17, 2024",
                content: {
                    intro: "In today's fast-paced financial world, making informed investment decisions is crucial. BullForce provides a suite of powerful calculators designed to help you navigate your investment journey with ease. Whether you're looking to invest through a Systematic Investment Plan (SIP), make a lumpsum investment, or understand your brokerage fees, BullForce has you covered. In this blog, we’ll explore the features and benefits of the BullForce SIP Calculator, StepUp SIP Calculator, Lumpsum Calculator, and Brokerage Calculator.",
                    understandingTheBullForceSIPCalculator: "The BullForce SIP Calculator is an essential tool for investors looking to build wealth over time through regular investments. SIPs allow you to invest a fixed amount at regular intervals in mutual funds, making it easier to manage your finances without the pressure of timing the market.",
                    sipExample: "Suppose you plan to invest ₹25,000 monthly in a mutual fund with an expected annual return of 12%. Using the BullForce SIP Calculator, you can determine that after 10 years, your investment could grow to approximately ₹3 lakhs. And Estimated returns will be ₹28,08,477, so Total value ₹58,08,477. This calculator not only shows your potential returns but also helps you understand how small, consistent investments can lead to significant wealth accumulation over time.",
                    stepUpSIPCalculator: "The StepUp SIP Calculator takes your investment strategy a notch higher by allowing you to increase your SIP amount at regular intervals. This feature is particularly beneficial for individuals expecting salary increments or increased disposable income.",
                    stepUpSIPExample: "Imagine starting with a monthly SIP of ₹25,000 and planning to increase it by 10% every year. Over 10 years, with an expected annual return of 12%, your total investment will be ₹47,81,227, Estimated returns ₹36,54,588 and Total value ₹84,35,81. The StepUp SIP Calculator empowers you to visualize how incremental increases in your investment can lead to substantial growth.",
                    lumpsumCalculator: "For those who prefer making one-time investments rather than regular contributions, the Lumpsum Calculator is an invaluable tool. It helps you assess the potential returns on a single investment based on different time horizons and expected rates of return.",
                    lumpsumExample: "If you decide to invest ₹1 lakh in a mutual fund with an anticipated return of 12% per annum for 10 years, the Lumpsum Calculator will show that your investment amount ₹1,00,000, Estimated returns ₹2,10,585 and Total value ₹3,10,585. This instant insight allows investors to make quick decisions about their lump-sum investments.",
                    brokerageCalculator: "Investing isn't just about returns; understanding the costs involved is equally important. The Brokerage Calculator helps you estimate the fees associated with buying and selling stocks or mutual funds. Knowing these costs can significantly impact your overall returns.",
                    brokerageExample: "If you're planning to buy shares worth ₹50,000 and your brokerage fee is 0.5%, the calculator will indicate that you'll incur a fee of ₹250. By factoring in these costs before making trades, you can ensure that your investment strategy remains profitable.",
                    callToAction: "Ready to take control of your financial future? Start using the BullForce calculators today! Whether you're investing through SIPs or making lump-sum investments, our tools will guide you every step of the way. Sign up now at BullForce.co and unlock your path to financial success!"
                },
                image: is500px ? imageFourD : imageFour
            },
            // start, srini - 22-01-2025
            {
                id: 5,
                title: "Bullforce: The Best Online trading platform in India for Beginners to Kickstart Their Investment Journey",
                author: "BULLFORCE",
                followers: 131,
                date: "January 03, 2025",
                content: {
                    intro: "Bullforce, powered by Maliram Makharia Finstock Private Limited, is revolutionizing the way beginner traders approach online trading and investments. With over 25 years of experience, Bullforce offers a range of services tailored to help newcomers navigate the world of investing with ease. Bullforce provides a user-friendly ecosystem to ensure that every step of your trading journey is smooth, secure, and successful.",
                    WhatMakesBullforcePerfectforBeginners: "Bullforce is designed with beginner traders in mind, focusing on simplifying the often daunting world of investing. The platform combines cutting-edge technology with user-centric features that break down complex investment processes into manageable steps. Whether you’re new to the stock market or looking to expand your portfolio, Bullforce ensures that all investors, regardless of background, can grow and succeed.",
                    Theplatformofferssimplified: "The platform offers simplified investment processes, from onboarding to settlements, ensuring seamless transactions. With a focus on retail investor growth, Bullforce welcomes new traders and provides the right tools and education to guide them every step of the way. The platform is backed by experienced founders, such as Srinath U.K., an investment advisor with over 25 years of experience, ensuring that every investor receives expert advice and customer-centric services.",
                    InitialPublicOfferings: "Initial Public Offerings (IPOs) are a great way to invest in companies at their growth stage and potentially gain high returns. Bullforce simplifies the process of investing in IPOs, making it accessible even for beginners. With an intuitive platform and seamless integration, buying IPOs has never been easier.",
                    HowBullforceSIPCalculator: "For beginner investors, planning financial goals is crucial, and that’s where Bullforce’s SIP (Systematic Investment Plan) calculator comes into play. The SIP calculator is a tool that helps you estimate the potential growth of your investments over time, making it easier to plan your financial future.",
                    steps: [
                        'Enter monthly investment amounts to see how your regular investments can grow.', 'Input expected annual return rates to simulate different market conditions.', 'Select your preferred investment period and watch your investment potential unfold.'
                    ],
                    calculators: "The calculator gives you a visual representation of your financial journey, helping you make informed decisions and set achievable milestones. Whether you're saving for retirement or a big purchase, Bullforce’s SIP calculator makes it easy to align your investments with your goals. Along with the SIP calculator, Bullforce also has Lumpsum Calculator, Step up SIP Calculator and Online Brokerage Calculator to assist investors in calculating returns and brokerage in various investment strategies.",
                    demataccount: "A demat account is a crucial tool for anyone interested in online trading, as it allows you to store securities electronically. Bullforce simplifies the process to open a demat account, ensuring that even the most inexperienced traders can get started with minimal hassle.",
                    tradingSteps: [
                        'Visit the Bullforce website and click on “Register”.',
                        'Fill in your basic details, such as your name, phone number, and email.',
                        'Complete the KYC verification by securely uploading identification documents.',
                        'Link your bank account for easy and secure transactions.'
                    ],
                    BullforceOffers: "Bullforce offers a range of benefits to those opening a demat account, to help you maximize your profits and multi-exchange trading to diversify your portfolio. The platform's user-friendly interface is perfect for beginners, ensuring that your trading experience is both intuitive and straightforward.",
                    bullforcesTrading: "Bullforce's trading platform stands out as one of the best for new investors, combining advanced tools with a simple and easy-to-use interface. Whether you're just learning the ropes or building your portfolio, Bullforce makes it easy to trade and track your investments.",
                    standoutSteps:[
                        'Multi-exchange trading, allowing you to trade across various exchanges effortlessly and diversify your portfolio.',
                        'Real-time insights with live stock market online charts and performance updates to help you stay informed and make better decisions.',
                        'Educational resources, including free training materials from the Bullforce Academy, helping you become a more knowledgeable trader.',
                        'Expert advice, Bullforce offers personalized investment guidance from experienced professionals, helping beginners make informed decisions and trade with confidence.'
                    ],
                    mutualFunds: "Mutual funds are an excellent way for beginners to grow their wealth while mitigating risk, and Bullforce makes investing in them simple and efficient. The platform provides direct access to top online mutual funds and allows you to easily open a mutual fund account.",
                    mutualFundsSip: "Bullforce’s SIP calculator further simplifies mutual fund investments by helping you set up automatic, regular contributions. Tracking your investments is also easy with the dedicated online mutual fund investment app, ensuring that you always know where your money is going.",
                    BullforcesEducation: "Bullforce isn’t just about tools; it's about building a community of knowledgeable, empowered investors. The Bullforce Academy and Learning Centre offers free, structured training programs designed for traders at every level. The Academy includes:",
                    beginnersSteps:[
                        'Beginner’s Program, which covers the basics like stock screening and part-time trading.','Advanced Program, focusing on using technical indicators and market patterns to enhance your trading strategies.', 'Mentorship Program, diving into complex topics like options trading and stock volatility.'
                    ],
                    bullforceDiplomats: "Bullforce also introduces the Bullforce Diplomats, a select group of experienced investors and influencers who act as ambassadors for the platform. These Diplomats provide exclusive insights, offer guidance, and lead community events to further enhance your investment journey. By engaging with the Bullforce Diplomats, you'll gain access to valuable mentorship and broaden your understanding of advanced trading techniques, helping you elevate your trading skills and network with like-minded individuals.",
                    comprehensiveSteps:[
                        'Comprehensive tools: From SIP calculators to live market insights, Bullforce provides everything you need to succeed.', 'Educational support: Free resources and expert guidance make the learning curve much easier.','Trusted brand: With experienced founders and an expert advisory board, you can rely on Bullforce for reliable advice and support.'
                    ],
                    readyToStart: "If you're looking to enter the world of online stock trading and share market investments, Bullforce is the ideal platform for beginners. With its easy-to-use tools, expert guidance, and low-cost trading options, Bullforce ensures that your trading experience is as smooth and successful as possible. Don’t wait—unlock your investment potential with Bullforce today and start your journey toward financial independence! Register Now!!"
                },
                image: is500px ? imageFiveD : imageFive
            }
            // End, srini - 22-01-2025
        ]

    const blog = blogs.find(ele => ele.id === parseInt(blogId, 10))

    useEffect(() => {
        window.Changemytab(5)
        // srini - 22-01-2025
        return () => {
            if (location.pathname !== '/blog-detail') {
                window.Changemytab(0)
            }
        }
    }, [location.pathname])

    const handleToggleSocialIcons = () => {
        setShowSocialIcons(!showSocialIcons)
    }

    return (
        <Grid container bgcolor='#fff' justifyContent='center' alignItems='center' >
            {/* Centered Container for Content */}
            <Grid container width={is960px ? '85%' : 'auto'} justifyContent='center' spacing={4}>
                <Grid item xs={12} display='flex' justifyContent='center' alignItems='center' gutterBottom>
                    <img src={blog.image} alt="blogImage" className="wblgdtimg" />
                </Grid>

                <Grid container width={isSmallScreen ? '90%' : '80%'} justifyContent='space-between' alignItems="center" mt='5px'>
                    <Grid item display='flex'>
                        <Typography className='wgrctxt'>
                            10 Min read
                        </Typography>
                        <Divider orientation="vertical" flexItem sx={{ mx: 1, borderColor: '#999', borderWidth: '1px' }} />
                        <Typography className='wgrctxt'>
                            {blog.date}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {isSmallScreen ?
                            <>
                                <IconButton>
                                    <a
                                        href="https://www.facebook.com/BullForceMMFPL/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={iconOne} alt='facebook' className="wblscics" />

                                    </a>
                                </IconButton>
                                <IconButton>
                                    <a
                                        href="mailto:customercare@bullforce.co"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={iconTwo} alt='gmail' className="wblscics" />
                                    </a>
                                </IconButton>
                                <IconButton>
                                    <a
                                    >
                                        <img src={iconThree} alt='telegram' className="wblscics" />
                                    </a>
                                </IconButton>
                                <IconButton>
                                    <a
                                    >
                                        <img src={iconFour} alt='whatsapp' className="wblscics" />
                                    </a>
                                </IconButton>

                                <IconButton>
                                    <a
                                        href="https://www.linkedin.com/company/bullforce/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={iconFive} alt='linkedin' className="wblscics" />
                                    </a>
                                </IconButton>

                                <IconButton>
                                    <a
                                    >
                                        <img src={iconSix} alt='x' className="wblscics" />
                                    </a>
                                </IconButton>
                            </>
                            :
                            <>
                                <IconButton onClick={handleToggleSocialIcons}>
                                    <img src={iconSeven} alt='social' className="wblscics" />
                                </IconButton>

                                {showSocialIcons && (
                                    <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: '60%', right: '10%', transform: 'translate(30%, -60%)', zIndex: 10, }}>
                                        <IconButton>
                                            <a
                                                href="https://www.facebook.com/BullForceMMFPL/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={iconOne} alt='facebook' className="wblscics" />

                                            </a>
                                        </IconButton>
                                        <IconButton>
                                            <a
                                                href="mailto:customercare@bullforce.co"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={iconTwo} alt='gmail' className="wblscics" />
                                            </a>
                                        </IconButton>
                                        <IconButton>
                                            <a
                                            >
                                                <img src={iconThree} alt='telegram' className="wblscics" />
                                            </a>
                                        </IconButton>
                                        <IconButton>
                                            <a
                                            >
                                                <img src={iconFour} alt='whatsapp' className="wblscics" />
                                            </a>
                                        </IconButton>

                                        <IconButton>
                                            <a
                                                href="https://www.linkedin.com/company/bullforce/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={iconFive} alt='linkedin' className="wblscics" />
                                            </a>
                                        </IconButton>

                                        <IconButton>
                                            <a
                                            >
                                                <img src={iconSix} alt='x' className="wblscics" />
                                            </a>
                                        </IconButton>
                                    </div>
                                )}
                            </>

                        }
                    </Grid>
                </Grid>
                {/* srini - 22-01-2025 */}
                <Grid width={is960px ? '90%' : '75%'} >
                    {/* Blog Title */}
                    <Grid item xs={12} >
                        <h1 className='wblgtit' mb='25px' style={{ 'margin-block-start': is960px && '10px' }}>
                            {blog.title}
                        </h1>
                    </Grid>

                    {/* Blog Introduction */}
                    <Grid item xs={12}>
                        {blog.content?.intro && (
                            <h2 className='wblgintr' mb='25px'>
                                {blog.content.intro}
                            </h2>
                        )}
                    </Grid>

                    {blogId == 1 && <RegistrationBlog blog={blog} />}

                    {blogId == 2 && <ExcellenceBlog blog={blog} />}

                    {blogId == 3 && <NavigateStock blog={blog} />}

                    {blogId == 4 && <SimplyInvest blog={blog} />}
                    {/* srini - 22-01-2025 */}
                    {blogId == 5 && <BestOnlineTradingplatform blog={blog} />}


                    <Grid item xs={12}>
                        <h2 className='wblgintr' paragraph xs={12}>
                            Risk Disclosure -
                            <a href="https://lnkd.in/g2D7ibmX" target="_blank" rel="noopener noreferrer">
                                https://lnkd.in/g2D7ibmX
                            </a>
                        </h2>
                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{ width: '100%', background: '#0B1420', marginTop: '30px' }}>
                <Footer />
            </Grid>
        </Grid>

    )
}

export default BlogDetail