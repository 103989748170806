import { useState } from "react";
import { useMediaQuery } from "@mui/material"
import MobileView from "./MobileView";
import WebView from "./WebView";
export default function Academy() {

  const isSmallScreen = useMediaQuery('(max-width:960px)')
  const [defaultBasicCheckBox, setDefaultBasicCheckBox] = useState(true)
  const [defaultProfessionalCheckBox, setDefaultProfessionalCheckBox] = useState(true)
  const [defaultCompleteCheckBox, setDefaultCompleteCheckBox] = useState(true)



  return (
    <>
      {isSmallScreen ?
        <MobileView defaultBasicCheckBox={defaultBasicCheckBox} defaultProfessionalCheckBox={defaultProfessionalCheckBox} defaultCompleteCheckBox={defaultCompleteCheckBox} setDefaultBasicCheckBox={setDefaultBasicCheckBox} setDefaultCompleteCheckBox={setDefaultCompleteCheckBox} setDefaultProfessionalCheckBox={setDefaultProfessionalCheckBox} />
        :
        <WebView defaultBasicCheckBox={defaultBasicCheckBox} defaultProfessionalCheckBox={defaultProfessionalCheckBox} defaultCompleteCheckBox={defaultCompleteCheckBox} setDefaultBasicCheckBox={setDefaultBasicCheckBox} setDefaultCompleteCheckBox={setDefaultCompleteCheckBox} setDefaultProfessionalCheckBox={setDefaultProfessionalCheckBox} />}
    </>
  )

}