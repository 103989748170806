import React, { Component } from 'react'

import { withStyles, } from "@mui/styles";
import stepconmp3 from '../../../ui-assets/step_con.mp3'
import cong_bull from '../../../ui-assets/new_bull.svg'
import ani_gif from '../../../ui-assets/Bullbg.gif'
//dev-trupti on 21-11-2024
import app_downloads from '../../../ui-assets/app_downloads_qr.png' 
import backendHosts from '../../../ui-utils/apiConfig';

import {
    Typography, Grid, Button,Modal,Box
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};
const styles = theme => ({
  

    page1card: {
       width: '100%',
       paddingLeft: '20%',
       background: '#1B2636',
       "@media only screen and (max-device-width: 480px)": {
           width: '100%',
           padding: '90px 16px',

       }
   },
   card_tit: {
       color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

       "@media only screen and (max-device-width: 480px)": {
           fontSize: '24px !important',
       },

   },
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },


   cong_card: {
    position: 'absolute',
    top: '50%', //dev-trupti on 21-11-2024
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: 'auto',

    outline: 'none',
    p: 4,
    "@media only screen and (max-device-width: 480px)": {
        top: '18%', //dev-trupti on 21-11-2024
        left: '0%',
        transform: 'none'
    }
},
cong_tit1: {
    //dev-trupti on 21-11-2024
    textAlign: 'center', color: '#BCD171', fontFamily: 'cardiuma-Bold !important', fontSize: '4.5vh !important',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '32px !important'
    },


},
cong_tit2: {//dev-trupti on 21-11-2024
    textAlign: 'center', color: '#ffffff !important', fontFamily: 'cardiuma-regular !important', fontSize: '2.8vh !important',width:'50%',fontWeight:'400',
    "@media only screen and (max-device-width: 900px)": {
        fontSize: '20px !important',
        // marginTop: '40%',
        padding:'5px 16px 0px 16px ',
        width:'90%'
    },
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '16px !important',
        // marginTop: '40%',
        padding:'5px 16px 0px 16px ',
        width:'90%'
    },
},
cong_tit3: {
    textAlign: 'center', color: '#ffffff !important', fontFamily: 'cardiuma-regular !important', fontSize: '2.6vh !important',width:'50%',fontWeight:'400',
    "@media only screen and (max-device-width: 900px)": {
        fontSize: '20px !important',
        // marginTop: '40%',
        padding:'5px 16px 0px 16px ',
        width:'90%'
    },
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '16px !important',
        marginTop: '10%',
        padding:'5px 16px 0px 16px ',
        width:'80%'
    },
},
cong_card1: {
    marginTop: '2%', width: '100%', display: 'flex', justifyContent: 'center',

    "@media only screen and (max-device-width: 480px)": {
        display: 'none',
    },

},
cong_card2: {

    display: 'none',
    "@media only screen and (max-device-width: 480px)": {
        marginTop: '2%', width: '100%', display: 'inline'
    },

},

cong_card3: {
    width: '100%', marginTop: '1%',display:'flex',justifyContent:'center',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', marginTop: '3%'
    },
},
cong_card4: {//dev-trupti on 09-12-2024
    width: '100%', marginTop: '0.1%',display:'flex',justifyContent:'center',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', marginTop: '0%'
    },
},

cong_card5: {
    width: '100%', marginTop: '0.2%',display:'flex',justifyContent:'center',
    "@media only screen and (max-device-width: 480px)": {
       display:'none'
    },
},

cong_card6: {
    width: '100%', marginTop: '0.2%',display:'flex',justifyContent:'center',
    "@media only screen and (min-device-width: 480px)": {
       display:'none'
    },
},


   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },

})
const beforeUnloadHandler = (event) => {
    // Recommended
        window.open('/')
    event.preventDefault();
    console.log("Clear navigation history. ")
    // Included for legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
    
  };
 class congratulations extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
          
            audio_stepcon : new Audio(stepconmp3),
            showang:1,
            fullname:"" //dev-trupti on 09-12-2024
        }



    }
 
    componentDidMount = async () => {
        //aded on hande back button on 11/08/24
        //added on 30/08/24 to Clear navigation history. 

        window.addEventListener("beforeunload", beforeUnloadHandler);
               //end
        //dev-trupti on 09-12-2024
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        this.setState({fullname:rd2.fullname})
        setTimeout(
            function() {
                this.state.audio_stepcon.play()
            }
            .bind(this),
            1000
        );
        setTimeout(
            function() {
                this.setState({showang:0})
            }
            .bind(this),
            1000
        );
        }
        
  render() {

    const { classes } = this.props;
    const { fullname } = this.state //dev-trupti on 09-12-2024
    return (
      <div className={classes.cong_card}> 
           <Grid style={{ width: '100%', display:'flex',justifyContent:'center',position:'relative',top:0 }}>
           {/* dev-trupti on 21-11-2024 */}
<img style={{position:'relative' , width:'23.7vh', height:'27.7vh'}} src={cong_bull} alt="congbull"></img>
{this.state.showang===1 && 
<img style={{position:'absolute',  width:'23.7vh', height:'27.7vh'}} src={ani_gif} alt="congbull"></img>
  }

</Grid>
         <Grid style={{width:'100%'}}>
   
    <Grid style={{ width: '100%', marginTop: '1%' }}>
        <Typography className={classes.cong_tit1} >Congratulations!</Typography>
    </Grid>
    <Grid className={classes.cong_card3}>
        {/* dev-trupti on 13-12-2024 */}
        <Typography className={classes.cong_tit2} >We’re excited to have you, <span style={{fontWeight:'900'}}>{fullname}</span>.</Typography>
    </Grid>
    <Grid className={classes.cong_card4}>
        <Typography className={classes.cong_tit2} >Your account opening process has started</Typography>
    </Grid>
    <Grid className={classes.cong_card4}>
        <Typography className={classes.cong_tit3} >You will recieve an email with your login ID and Password within 24hrs to access our trading platform.</Typography>
    </Grid>
    <Grid className={classes.cong_card5}>
        <Typography className={classes.cong_tit3}>In the meantime, please download the Bullforce app by scanning the below QR code.</Typography>
    </Grid>
    <Grid className={classes.cong_card5}>
        <img style={{width:'16vh', height:'16vh', marginTop:'10px'}} src={app_downloads} alt="qrcode"></img>
    </Grid>
    <Grid className={classes.cong_card6}>
        <Typography className={classes.cong_tit3}>Download the <span style={{fontSize:'18px' , color:'#BCD171'}} onClick={()=>window.open(`${backendHosts.APP_DWNLDS}`)}>Bullforce app </span> now and take the first step towards smarter investing!</Typography>
    </Grid>
    
   
</Grid>
</div>
    )
  }
}
export default (withStyles(styles)((congratulations)));