import { IconButton, Snackbar, SnackbarContent, Grid, Typography, Button, useMediaQuery } from "@mui/material"
import { useState } from "react"
import academy_logo from '../../../ui-assets/academy_logo.svg'
import academy_head from '../../../ui-assets/academy_head.svg'
import t1 from '../../../ui-assets/t1.svg'
import t2 from '../../../ui-assets/t2.svg'
import t3 from '../../../ui-assets/t3.svg'
import "./css/MobView.css"
import { Close } from "@mui/icons-material"
import MobileModal from "./MobileModal"
export default function MobileView({ defaultBasicCheckBox, defaultCompleteCheckBox, defaultProfessionalCheckBox, setDefaultBasicCheckBox, setDefaultProfessionalCheckBox, setDefaultCompleteCheckBox }) {
  const [showModal, setShowModal] = useState(false)
  const [shower, setShower] = useState(false)
  const [error, setError] = useState("")

  const snackeror = {
    myerror: {
      backgroundColor: "#ffffff",
      borderLeft: "8px solid #b60000",
      flexWrap: "nowrap",
      color: "#005100",
      borderTop: "1px solid #b60000",
      borderRight: "1px solid #b60000",
      borderBottom: "1px solid #b60000",
    },
    mysucc: {

      backgroundColor: "#ffffff",
      borderLeft: "8px solid #8db600",
      flexWrap: "nowrap",
      color: "#005100",
      borderTop: "1px solid #8db600",
      borderRight: "1px solid #8db600",
      borderBottom: "1px solid #8db600",
    },

  };



  const [mysnack, setMySnack] = useState(snackeror.mysucc)




  const closeContactModal = () => {
    setShower(false)
  }

  const handleShowModal = () => {
    setShowModal(true)

  }

  return (

    <>
      {showModal && <MobileModal setShowModal={setShowModal} setShower={setShower} setMySnack={setMySnack} snackeror={snackeror} setError={setError} defaultBasicCheckBox={defaultBasicCheckBox} defaultProfessionalCheckBox={defaultProfessionalCheckBox} defaultCompleteCheckBox={defaultCompleteCheckBox} setDefaultBasicCheckBox={setDefaultBasicCheckBox} setDefaultCompleteCheckBox={setDefaultCompleteCheckBox} setDefaultProfessionalCheckBox={setDefaultProfessionalCheckBox} />}
      <Grid container className="mobacademymaincard">
        <Grid className="mobacademyimgcard">
          <img
            className="mobacademyimg"
            src={academy_head}
            loading="lazy"
            alt="academy_head"
          />
        </Grid>
      </Grid>
      <Grid style={{ background: "#0b1420", paddingTop: "0px", marginTop: "0px" }}>
        <Grid className="mobacademyimgcard1">
          <img
            className="mobacademyimg1"
            src={academy_logo}
            loading="lazy"
            alt="academy_logo"
          />
        </Grid>

        <Grid className="mobacademymainheadcard">
          <h1 className="mobacademymainhead">
            Bullforce Academy and Learning Centre
          </h1>
        </Grid>

        <Grid className="mobacademymainsubcard">
          <Typography className="mobacademymainsub">
            Bullforce is committed to building an enlightened investor community.
            In this context, three levels of educational programs have been launched.
            These programs are absolutely complimentary from Bullforce. These programs are trainer-assisted
            online courses with bidirectional communication and conducted in batches.
          </Typography>
        </Grid>

        <Grid container className="mobacademysubimagecard">
          <Grid className="mobacademysubimagecard1">
            <Grid className="moblearnImage" style={{ width: '100%' }}>
              <img src={t1} alt="traning" loading="lazy" />
            </Grid>
            <Grid className="moblearncard">
              <h3 className="mobacademysubtit">Beginners Program</h3>
              <Grid className="mobacademyDispGrid">
                <Typography className="mobacademydisp">
                  ‣  Swing Trading v/s Day Trading
                </Typography>
                <Typography className="mobacademydisp">
                  ‣  How part-time trading works
                </Typography>
                <Typography className="mobacademydisp">
                  ‣  Finding the best stock to Swing Trade
                </Typography>
                <Grid style={{ display: 'flex' }}>
                  <Typography className="mobacademydisp">
                    ‣  Stock screening criteria
                  </Typography>

                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="mobadvancedCard">
            <Grid className="moblearnImage" style={{ width: '100%' }}>
              <img src={t2} alt="t2" loading="lazy" />
            </Grid>
            <Grid className="moblearncard">
              <h3 className="advanceTitle">Advanced Program</h3>
              <Grid className="mobacademyDispGrid">
                <Typography className="mobacademydisp">
                  ‣  Trading strategies based on technical indicators
                </Typography>
                <Typography className="mobacademydisp">
                  ‣  Trading strategies based on patterns
                </Typography>
                <Typography className="mobacademydisp">
                  ‣  Trading with special patterns
                </Typography>
                <Grid style={{ display: 'flex' }}>
                  <Typography className="mobacademydisp">
                    ‣  Trading strategies based on Elliot Wave
                  </Typography>

                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="mobmentorCard">
            <Grid className="moblearnImage" style={{ width: '100%' }}>
              <img src={t3} alt="t3" loading="lazy" />
            </Grid>
            <Grid className="moblearncard">
              <h3 className="mobacademysubtit2">Mentor Program</h3>
              <Grid className="mobacademyDispGrid">
                <Typography className="mobacademydisp">
                  ‣  Course Introduction
                </Typography>
                <Typography className="mobacademydisp">
                  ‣  Options and the Stock Volatility, the VIX and more
                </Typography>
                <Typography className="mobacademydisp">
                  ‣  The basics of how Options work (Tickers, Call and Put)
                </Typography>
                <Grid style={{ display: 'flex' }}>
                  <Typography className="mobacademydisp">
                    ‣  Pricing a Long or Short Call or Put
                  </Typography>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          style={{

            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <Grid className="mobacademybutcard">
            <Button
              style={{
                backgroundColor: '#F74542',

              }}
              fullWidth
              className="mobjoinDiplomtsBtn"
              onClick={handleShowModal}
            >
              Join Us
            </Button>
          </Grid>
        </Grid>

      </Grid>



      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={shower}
        autoHideDuration={3000}
        onClose={closeContactModal}
      >
        <SnackbarContent
          style={mysnack}
          message={
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: "#333333",
                fontSize: "12px",
                fontFamily: "cardiuma-M"
              }}
            >
              {error}
            </span>
          }
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShower(false)}
              >
                <Close fontSize="small" />
              </IconButton>
            </>
          }
        />
      </Snackbar>
    </>
  );
}