import React, { Component } from 'react'
import { withStyles,styled} from "@mui/styles";
import { alpha } from '@mui/material';
import stepconmp3 from '../../../ui-assets/step_con.mp3'
import logo from '../../../ui-assets/icon.png'
import {
    Typography, Grid, Button,Table,TableBody,TableRow,TableCell,
    Switch, FormControlLabel,FormGroup,
    Stack,Checkbox
} from '@mui/material'
import { httpRequest } from '../../../ui-utils/api'
import backendHosts from '../../../ui-utils/apiConfig.js'

const GoldenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#F3D45E",
      '&:hover': {
        backgroundColor: "#F3D45E"
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#F3D45E",
    },
    '& .MuiSwitch-track': {
      backgroundColor: "#999999",
    },
  }))

  const GoldenCheckbox = styled(Checkbox)(({ theme }) => ({
        color: "#F3D45E !important",
        '&.Mui-checked': {
          color: "#F3D45E !important",
        }
  }))

  const CustomFormLabel = styled(FormControlLabel)(({ theme }) => ({
    color : "#F3D45E", 
    "& .MuiTypography-root" : {
        fontSize : "13px"
    }
}))

const styles = theme => ({
  
    cong_card: {
     position: 'absolute',
     top: '60%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: '100%',
     height: 'auto',
     outline: 'none',
   
     p: 4, 
     "@media only screen and (max-device-width: 480px)": {
         top: '3%',
         left: '0%',
         transform: 'none',
         background:'#1B2636', 
     },
 },
    table_text : {
     color : "#FFFFFF",
     fontSize : "14px !important",
     fontFamily : "cardiuma-regular !important",
     width : "90% !important",
     "@media only screen and (max-device-width: 480px)": {
         width: '100%  !important'
     },
   },
 
   table_text1 : {
     color : "#F3D45E",
     fontSize : "13px !important",
     fontFamily : "cardiuma-regular !important",
     "@media only screen and (max-device-width: 480px)": {
         marginTop: '20px  !important'
     },
   },
 
 cong_tit1: {
     textAlign: 'start', color: '#FFFFFF', fontFamily: 'cardiuma-Bold !important',fontSize: '23px !important',
     "@media only screen and (max-device-width: 480px)": {
         fontSize: '25px !important',
         marginTop : "30px !important"
     },
 },
 main_card:{
    width:'63%', display : "flex" ,justifyContent : "center",
    "@media only screen and (max-device-width: 480px)": {
        width:'100%',
    },
 },
 joinDiplomtsBtn: {
    background: "#F74542 !important",
    padding: "10px 45px !important",
    borderRadius: "20px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-M !important"
},
sub_card:{
display:'flex',justifyContent:'center', 
"@media only screen and (max-device-width: 480px)": {
    marginTop:'20px'
},
},
tableCell : {
    padding : "11px !important",
    // width : "60% !important"
},
tableCell1 : {
    padding : "11px !important",
    width : "25% !important",
//     "@media only screen and (max-device-width: 480px)": {
//     width :'100% !important'
// },
},
mainScrollGrid : {
    height : "90vh",
    overflowY : "auto",
    "&::-webkit-scrollbar": {
    width: "5px !important"
  },
  "&::-webkit-scrollbar-track" :{
  background: "#1B2636"
    },
    "&::-webkit-scrollbar-thumb" : {
    background: "#999999" 
  }
},
checkboxes : {
    "@media only screen and (max-device-width: 480px)": {
    marginTop:'10px'
},
}
 })
 class congratulations extends Component {
    constructor(props) {
        super(props)

       
        this.state = {        
          
            audio_stepcon : new Audio(stepconmp3)
        }

        this.state = {
            switchA : false,
            switchB : true,
            automaticCredit : true,
            pledge : true,
            accStmReq : ["monthly", "weekly"],  //dev-trupti on 13-12-2024
            eleTrscHolidingStm : true,
            rta : true,
            bsda : false,
            annualReport : "electronic" ,
            ecs : true,
            runAccAuth : "quaterly"
        }


    }
    Continue5 = async () => {
        const {switchA}=this.state
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
let mobileno=rd2.mobileno
     let payload = {
            "emailid": emailid,
            "mobileno": mobileno,
            "dis_slip":switchA,
            
        }
    
    let changes = {}    

        //dev-Ayush 18-07-2024
            if(!this.state.automaticCredit){
                changes.automaticCredit = "No"
            } 
            if(!this.state.pledge){
                changes.pledgeInstructions = "No"
            }
            if(this.state.accStmReq.length === 1){ //dev-trupti on 13-12-2024
                changes.acc_statement_requirment = this.state.accStmReq
            }
            if(!this.state.accStmReq.includes("weekly")){
                changes.acc_statement_requirment = this.state.accStmReq
            }
            if(this.state.accStmReq.length >2 ){
                changes.acc_statement_requirment = this.state.accStmReq
            }
            if(!this.state.eleTrscHolidingStm){
                changes.electronic_transaction_cum_holding_stm = "No"
            }
            if(!this.state.rta){
                changes.share_email_with_rta = "No"
            }
            if(this.state.bsda){
                changes.bsda_facility = "Yes"
            }
            if(this.state.annualReport !== "electronic"){
                changes.require_annualReport = this.state.annualReport
            }
            if(!this.state.ecs){
                changes.recieve_dividend_through_ecs = "No"
            }
            if(this.state.runAccAuth != "quaterly"){
                changes.settle_my_funds_and_securities = this.state.runAccAuth
            }

            if(this.state.automaticCredit && this.state.pledge && this.state.accStmReq.includes("monthly") && this.state.accStmReq.includes("weekly") && this.state.accStmReq.length === 2 && this.state.eleTrscHolidingStm && this.state.rta && !this.state.bsda && this.state.ecs && this.state.annualReport == "electronic" && !this.state.switchA && this.state.runAccAuth == "quaterly"){ 
                payload.dis_slip = false
            }else{
                payload.dis_slip = true
                if(Object.keys(changes).length !== 0){
                    payload.changes = changes
                }
            }
            
        //dev-Ayush 18-07-2024
    
try {
    const disslip = await httpRequest({
        endPoint: `/api/disslip`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload,
    });
if(disslip.status===200)
{

    let rd1 =  JSON.parse(localStorage.getItem('reg_data') || '{}')
    
    if(rd1.citizen===0)
    {
    window.ChangePageNo (12)
    }else{
        window.ChangePageNo (17.2)
    }
}
    
} catch (error) {
    
}

    }
//dev-trupti on 13-12-2024
    handleCheckboxChange = (value) => {
        this.setState(prevState => {
            const newAccStmReq = [...prevState.accStmReq];
            if (newAccStmReq.includes(value)) {
                const index = newAccStmReq.indexOf(value);
                newAccStmReq.splice(index, 1);
            } else {
                newAccStmReq.push(value);
            }
            return { accStmReq: newAccStmReq };
        });
    }
  render() {
    const handleChange = () =>{
        this.setState({switchA : !this.state.switchA ,switchB : !this.state.switchB  })

    }
  

    const { classes } = this.props;
    return (  
      <div> <Grid >
        <Grid className={classes.mainScrollGrid}> 
         <Grid style={{display:'flex',justifyContent:'center' }}>
                <img src={logo} height={20} style={{marginTop : "20px"}} alt="congbull"></img>
        </Grid>
               <Typography className={classes.cong_tit1} sx={{margin : "10px 0px 0px 40px"}} >DIS Depository</Typography>
      
           <Grid  style={{display : "flex" ,justifyContent : "center", margin : "0px 30px 0px 30px" }}>
           
           <Table style={{background:'#1B2636'}} > 
          <TableBody > 
              <TableRow  style={{padding:'25px'}} >
                {/*dev-Ayush 22-07-2024 inner width changes*/}
                  {window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59"}}>
                      <Typography className={classes.table_text}>
                  I/ We instruct the DP to receive each and every credit in my / our account 
                  (If not marked, the default option would be 'Yes')
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}}  >
                  {window.innerWidth <= 480 && 
                      <Typography className={classes.table_text}>
                  I/ We instruct the DP to receive each and every credit in my / our account 
                  (If not marked, the default option would be 'Yes')
                      </Typography>}
                    <>
                      <Typography className={classes.table_text1}> 
                          Automatic Credit
                      </Typography>
                                <FormGroup row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.automaticCredit} onChange={()=>this.setState({automaticCredit : true})} />} label="Yes" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {!this.state.automaticCredit} onChange={()=>this.setState({automaticCredit : false})} />} label="No" />
                                </FormGroup> 
                                </>
                  </TableCell>
                  
              </TableRow>
               <TableRow >
                  {window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59"}}  >
                  <Typography className={classes.table_text} >
                  I/ We would like to instruct the DP to accept all the pledge instructions in my / our account without any other further instruction from my / our end (If not marked,  the default option would be "No")
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}}  >
                  {window.innerWidth <= 480 && 
                  <Typography className={classes.table_text} >
                  I/ We would like to instruct the DP to accept all the pledge instructions in my / our account without any other further instruction from my / our end (If not marked,  the default option would be "No")
                      </Typography>
                    }
                  <FormGroup className={classes.checkboxes} row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.pledge} onChange={()=>this.setState({pledge : true})} />} label="Yes" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {!this.state.pledge} onChange={()=>this.setState({pledge : false})} />} label="No" />
                                </FormGroup> 
                  </TableCell>
              </TableRow>
              <TableRow >
                  {window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59" }} >
                  <Typography className={classes.table_text}>
                  Account Statement Requirement
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}} >
                  {window.innerWidth <= 480 && 
                  <Typography className={classes.table_text}>
                  Account Statement Requirement
                      </Typography>
                  }
                                {/* dev-trupti on 13-12-2024 */}
                                {/* <FormGroup className={classes.checkboxes} row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.accStmReq == "as per sebi regulation"} onChange={()=>this.setState({accStmReq : "as per sebi regulation"})} />} label="As per SEBI Regulation" />
                                </FormGroup>  */}
                                <FormGroup row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked={this.state.accStmReq.includes("daily")} onChange={() => this.handleCheckboxChange("daily")} />} label="Daily" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked={this.state.accStmReq.includes("weekly")} onChange={() => this.handleCheckboxChange("weekly")} />} label="Weekly" />
                                </FormGroup> 
                                <FormGroup row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked={this.state.accStmReq.includes("fortnight")} onChange={() => this.handleCheckboxChange("fortnight")} />} label="Fortnight" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked={this.state.accStmReq.includes("monthly")} />} label="Monthly" />
                                </FormGroup> 
                  </TableCell> 
              </TableRow>
              <TableRow>
                  {window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59"}} >
                  <Typography className={classes.table_text}>
                  I/ We request you to send Electronic Transaction-cum-Holding Statement at the email
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}} >
                  {window.innerWidth <= 480 && 
                  <Typography className={classes.table_text}>
                  I/ We request you to send Electronic Transaction-cum-Holding Statement at the email
                      </Typography>
                 }
                  <FormGroup className={classes.checkboxes} row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.eleTrscHolidingStm} onChange={()=>this.setState({eleTrscHolidingStm : true})} />} label="Yes" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {!this.state.eleTrscHolidingStm} onChange={()=>this.setState({eleTrscHolidingStm : false})} />} label="No" />
                                </FormGroup> 
                  </TableCell>
              </TableRow>
              <TableRow >
                  {window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59"}} >
                  <Typography className={classes.table_text}>
                  I/ We would like to share the email ID with the RTA
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}} >
                  {window.innerWidth <= 480 && 
                  <Typography className={classes.table_text}>
                  I/ We would like to share the email ID with the RTA
                      </Typography>
                  }
                  <FormGroup className={classes.checkboxes} row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.rta} onChange={()=>this.setState({rta : true})} />} label="Yes" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {!this.state.rta} onChange={()=>this.setState({rta : false})} />} label="No" />
                                </FormGroup> 
                  </TableCell>
              </TableRow>
              <TableRow >
                  {window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59"}} >
                  <Typography className={classes.table_text}>
                  I/We wish to avail the BSDA facility for the new account for which we have submitted my / our account opening form
                      </Typography>
                  </TableCell>} 
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}} >
                  {window.innerWidth <= 480 && <Typography className={classes.table_text}>
                        I/We wish to avail the BSDA facility for the new account for which we have submitted my / our account opening form
                      </Typography>}
                  <FormGroup className={classes.checkboxes} row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.bsda} onChange={()=>this.setState({bsda : true})} />} label="Yes" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {!this.state.bsda} onChange={()=>this.setState({bsda : false})} />} label="No" />
                                </FormGroup> 
                  </TableCell>
              </TableRow>
               <TableRow >
                { window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59"}} >
                  <Typography className={classes.table_text}>
                  I/ We would like to receive the Annual Report 
                  (Tick the applicable box. If not marked the default option would be in Physical)
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}} >
                  { window.innerWidth <= 480 && <Typography className={classes.table_text}>
                  I/ We would like to receive the Annual Report 
                  (Tick the applicable box. If not marked the default option would be in Physical)
                      </Typography>}
                  <FormGroup row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.annualReport == "physical"} onChange={()=>this.setState({annualReport : "physical"})} />} label="Physical" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.annualReport == "electronic"} onChange={()=>this.setState({annualReport : "electronic"})} />} label="Electronic" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.annualReport == "physical and electronic"} onChange={()=>this.setState({annualReport : "physical and electronic"})} />} label="Both Physical and Electronic" />
                                </FormGroup> 
                  </TableCell>
              </TableRow> 
              <TableRow >
                  { window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59"}} >
                  <Typography className={classes.table_text} >
                  Do you wish to receive dividend / interest directly in to your bank account given below through ECS? (If not marked, the default option would be 'Yes')
                  [ECS is mandatory for locations notified by SEBI from time to time]
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}} >
                  { window.innerWidth <= 480 && <Typography className={classes.table_text} >
                  Do you wish to receive dividend / interest directly in to your bank account given below through ECS? (If not marked, the default option would be 'Yes')
                  [ECS is mandatory for locations notified by SEBI from time to time]
                      </Typography>}
                  <FormGroup row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.ecs} onChange={()=>this.setState({ecs : true})} />} label="Yes" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {!this.state.ecs} onChange={()=>this.setState({ecs : false})} />} label="No" />
                                </FormGroup> 
                  </TableCell>
              </TableRow>
              <TableRow >
                  {  window.innerWidth > 480 &&<TableCell className= {classes.tableCell}  style={{borderColor : "#404B59"}} >
                  <Typography className={classes.table_text}  >
                  I/We require the Delivery Instruction Slip (DIS)
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}} >
                  { window.innerWidth <= 480 &&  <Typography className={classes.table_text}  >
                  I/We require the Delivery Instruction Slip (DIS)
                      </Typography>}
                  <GoldenSwitch className={classes.checkboxes} size='small' checked = {this.state.switchA} onChange={handleChange}/>
                  </TableCell>
              </TableRow>
              <TableRow >
                  { window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59"}} >
                  <Typography className={classes.table_text} >
                  I/We do not require the Delivery Instruction Slip (DIS) for the time being, since I/We have issued a POA/DDPI/EDIS executed in favour of Maliram Makharia Finstock Pvt. Ltd.( MMFPL ) for executing delivery instructions for setting stock exchange trades [settlement related transactions] and margin purpose effected through MMFPL. However, the Delivery Instruction Slip (DIS) booklet should be issued to me / us immediately on my / our request at any later date.
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}} >
                  { window.innerWidth <= 480 && <Typography className={classes.table_text} >
                  I/We do not require the Delivery Instruction Slip (DIS) for the time being, since I/We have issued a POA/DDPI/EDIS executed in favour of Maliram Makharia Finstock Pvt. Ltd.( MMFPL ) for executing delivery instructions for setting stock exchange trades [settlement related transactions] and margin purpose effected through MMFPL. However, the Delivery Instruction Slip (DIS) booklet should be issued to me / us immediately on my / our request at any later date.
                      </Typography>}
                  {/* <Typography className={classes.table_text1}> */}
                  <GoldenSwitch className={classes.checkboxes} size='small' checked = {this.state.switchB} onChange={handleChange}/>
                      {/* </Typography> */}
                  </TableCell>
              </TableRow>
              
          </TableBody>
       </Table>
        
           </Grid>
           <Typography className={classes.cong_tit1} sx={{margin : "30px 10px 0px 40px"}} >Running Account Authorisation</Typography>

            <Grid sx={{margin : "0px 30px 0px 30px"}}>
           <Table> 
           <TableBody>
           <TableRow >
                  { window.innerWidth > 480 && <TableCell className= {classes.tableCell} style={{borderColor : "#404B59"}} >
                  <Typography className={classes.table_text} >
                  I/We have read the circular and request you to settle my fund and securities account as 
                      </Typography>
                  </TableCell>}
                  <TableCell className= {classes.tableCell1} style={{borderColor : "#404B59"}} >
                  {  window.innerWidth <= 480 && <Typography className={classes.table_text} >
                  I/We have read the circular and request you to settle my fund and securities account as 
                      </Typography>
                  }
                  <FormGroup row>
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.runAccAuth == "monthly"} onChange={()=>this.setState({runAccAuth : "monthly"})} />} label="Montlhy" />
                                    <CustomFormLabel  control={<GoldenCheckbox checked = {this.state.runAccAuth == "quaterly"} onChange={()=>this.setState({runAccAuth : "quaterly"})} />} label="Quaterly" />
                                </FormGroup> 
                  </TableCell>
              </TableRow>
              </TableBody>
              </Table>
              </Grid>
           </Grid>   
           <Grid style={{display : "flex" , justifyContent : "center" , alignItems : "center" , height : "10vh"}}>
        <Button className={classes.joinDiplomtsBtn}
        
        onClick={() =>  this.Continue5()}
        >
             Agree & Proceed 
            
            </Button> 
       </Grid>
</Grid></div>
    )
  }
}
export default (withStyles(styles)((congratulations)));