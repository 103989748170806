import React, { Component } from 'react'
import { Grid, Typography, } from '@mui/material'
import { withStyles, styled } from "@mui/styles";
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Privacy Policy | BullForce',
    description: 'Bullforce Privacy Policy: Secure your demat account and personal data. Understand how we safeguard your information while trading stocks, bonds, and IPOs. Your data security is our priority.',
    canonical: 'https://bullforce.co/Privacy_Policy',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, Privacy, Policy, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

const styles = theme => ({

    heading: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-M !important', fontSize: '24px !important', // h1 tags margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0, marginBlockEnd: 0, fontWeight: 'normal',
        "@media only screen and (max-device-width: 480px)": {
            color: '#FFFFFF !important', fontFamily: 'cardiuma-M !important', fontSize: '20px !important'
        }

    },
    p_body: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important', lineHeight: "22px !important",
        "@media only screen and (max-device-width: 480px)": {
            color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', lineHeight: "20px !important",
        }

    },
    p_1:{
        padding: '20px 0px 0px 0px',
        "@media only screen and (max-device-width: 480px)": {
            padding: '2px 0px 0px 0px',
        }


    }

})

class privacy_policy extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div style={{ height: 'auto' }}>
                <DocumentMeta {...meta} />
                {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                <Grid className={classes.p_1}  >
                    <h1 className={classes.heading}  >

                        Maliram Makharia Finstock Private Limited
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        In the course of using this website or any of the websites under the 'BullForce' domain or availing the products and services vide the
                        online application forms and questionnaires, online consents and such other details required from time to time on any of   Maliram Makharia Finstock Private Limited
                        (and/or its affiliates) web platforms or mobile applications,  Maliram Makharia Finstock Private Limited and/or its affiliates may become privy to some of Your personal
                        information, including which may or may not be of confidential nature.  Maliram Makharia Finstock Private Limited is strongly committed to protecting the privacy of
                        its users/clients and has taken all necessary and reasonable measures to protect the confidentiality of any customer information.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        For the purpose of these Privacy Policy, wherever the context so mentions “Covered Persons”, "Client","You" or "Your”, it shall
                        mean any natural or legal person who has visited this website/platform and/or has agreed to or has enquired open an account and/or
                        initiated the process of opening an account with  Maliram Makharia Finstock Private Limited (also defined as “Covered Persons”).
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Maliram Makharia Finstock Private Limited allows any person to use/visit/browse the website (www.bullforce.co) without registering on the website. The term "We", "Us" , "Our" and
                        "Team  Maliram Makharia " shall mean Maliram Makharia Finstock Private Limited. Maliram Makharia Finstock Private Limited
                        (hereinafter shall be referred together as 'MMFPL'). MMFPL is a Trading Member of NSE and BSE, registered
                        with Securities & Exchange Board of India ("SEBI") and having Registration No. INZ000295634. This website is  operated
                        and maintained by MMFPL, a Company incorporated under the Companies Act, 2013 having CIN:
                        U67120MH1996PTC098037, and having its Registered Office at (Maliram Makharia Finstock Pvt. Ltd) 911, Tulsiani Chambers, 9th Floor, Free Press Journal Marg, Nariman Point, MUMBAI, MAHARSHTRA - 400021. Access to Your MMFPL(Bullforce) account and the usage of Your account is subject to Your compliance with all the terms and
                        conditions set forth herein, read along with all documents, including but not limited to; applications forms and undertakings, signed by you during account opening, and any terms/consents/policies included on MMFPL’s(Bullforce) website/domain, i.e. https://bullforce.com/,
                        including its “Terms and Conditions”, “Policies & Procedures”, and “Disclosure”.
                    </Typography>


                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Scope of this Policy
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Any natural or legal person who has visited the MMFPL(Bullforce) website/platform and/or the mobile application, and/or who may or may not
                        have further agreed to initiate the process of opening an account with MMFPL(Bullforce) shall come under the purview of the scope of this
                        Privacy Policy. MMFPL(Bullforce) shall at all times follow all such rules prescribed under the Information Technology Act, 2000, and rules &
                        procedural guidelines set by the Securities Exchange Board of India (“SEBI”) and/or any of its regulated entities/intermediaries that
                        MMFPL(Bullforce) is a member of.
                    </Typography>


                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Collection and use of your personal information
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        MMFPL may or may not collect your Personal Information to provide services on its web based platform or mobile application.
                        While opening a trading and demat account with MMFPL, you may be asked for certain Sensitive Personal Data or Information (as
                        defined under the Information Technology Act, 2000) to complete your KYC (as per the ‘Know Your Client’ requirements set by
                        SEBI) and as per the Prevention of Money Laundering Act, 2002.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        MMFPL(Bullforce), may or may not, request for sensitive information from You at the time of account opening, which would include but not
                        be limited to, (i) setting a password; (ii) financial information such as Bank account or credit card or debit card or other payment
                        instrument details; (iii) information such as name, contact number, gender, date of birth, residence and employment addresses,
                        father’s/mother’s and spouses names, bank account details, scans/copies of document proofs of address & identity such as Photograph,
                        PAN, passport, drivers license, Aadhaar (voluntarily) etc. (iv) uploading signature proof/photo etc. All such information would be for
                        the purpose of: identity verification and background checking for account opening as per KYC & AML Rules, personalising services
                        offered to you, keeping you updated of our products and services relevant to you, information pertaining to our services you’ve
                        subscribed to, and for any legal and regulatory / audit purposes.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Under Regulation 5 of the Information Technology (Reasonable Security and Procedures and Sensitive Personal Data or Information)
                        Rules, 2011, MMFPL is obliged to provide every registered user of MMFPL with a readable copy of the personal information you
                        have provided us
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        We would save such information as required by the law, and as directed by the regulators along with such intermediaries regulated by
                        them, for the purpose of offering You our services, and for such further regulatory, audit, and business purposes. We collect PersonalInformation from you only when you voluntarily use the services provided by MMFPL, and/or enquire to begin an association with
                        MMFPL and/or complete the process of opening an account with MMFPL.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        With regard to security, we respect all information provided to us, and take all reasonable steps towards protection of the same. We
                        have implemented technology and policies, with the objective of protecting your privacy from unauthorized access and improper use,
                        and periodically review the same. We maintain procedural safeguards to protect the confidentiality and security of personally
                        identifiable information transmitted to us.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Aadhaar user consent policy
                    </h1>
                    <h3 className={classes.heading} style={{ padding: '10px 0px 0px 0px' }} >

                        Voluntary Act
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The act of providing your Aadhaar is voluntary in nature, which will only be required for completing your account opening procedure
                        online and for digitally signing the MMFPL account opening form. Your Aadhaar may also be used as a proof of address as an
                        alternative choice to drivers license or Passport copy, which is a voluntary decision to be made by You.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        You may alternatively choose to go through the offline route of account opening by physically signing all such account opening
                        documents, which does not require you to share your Aadhaar information at all.
                    </Typography>


                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }}>

                        MMFPL does not store any Aadhaar information
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        During your online account opening process, you do not input any Aadhaar details except the Aadhar number on the MMFPL platform. You would be issuing
                        your Digital Aadhaar to Digilocker, from where MMFPL (MMFPL is registered as a Requester on the Digilocker platform) would
                        receive information, based on your consent to allow Digilocker to share such information with MMFPL, limited to: (1) The last four
                        digits of your Aadhaar / VID (Virtual ID created on the UIDAI website), (2) Full Name, (3) Date of Birth, (4) Gender, (5) Address,
                        and (6) Your Photo.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Digilocker is a part of the National eGovernance Division under the Ministry of Electronics & Information Technology (MeitY),
                        Government of India. The Digital Aadhaar issued within DigiLocker is the same as the eAadhaar issued by UIDAI
                        (https://eaadhaar.uidai.gov.in). DigiLocker has partnered with UIDAI to make it available automatically to its users when they link
                        their DigiLocker account with Aadhaar. This act of issuing an eAadhaar within Digilocker and subsequently Digilocker sharing the
                        information limited to the above points, leads to behaving as a physical KYC. MMFPL only receives information limited to the
                        above points for the purposes of completing KYC of clients online.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        All clients may alternatively follow the offline route of account opening, which does not require sharing any Aadhaar related
                        information.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        During your online account opening process, you would also be sharing your Aadhaar with Digiotech Solutions Pvt. Ltd. for the
                        purpose of digitally signing the MMFPL account opening form. Digiotech Solutions Pvt. Ltd. does not share your Aadhaar with
                        MMFPL. After the account opening form has been digitally signed by you, a copy of the digitally signed form would be emailed to
                        you and to MMFPL for reference/audit purposes. Digiotech Solutions Pvt. Ltd. is a licensed e-Sign ASP (Application Services
                        Provider) with CDAC, compliant with the guidelines laid down by the Controller of Certifying Authorities (CCA), India and UIDAI,
                        to enable you to eSign the account opening forms online.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Collection and use of your non-personal information
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        MMFPL may from time to time collect information, i.e. Non-personal information which cannot be used to identify you personally,
                        while You are using the website or mobile application. MMFPL may from time to time collect information such as Non-personal
                        information such as IP (internet protocol) addresses, browser types, the name of your ISP (internet service provider), information
                        about a third party link/URL from where You arrive to our website/mobile application, which pages/URLs do You click on the
                        MMFPL website / mobile application, and the amount of time spent on each.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        MMFPL uses Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses first-party cookies to report
                        on user interactions with our web-based services. These cookies store non-personally identifiable information.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Usage of Cookies
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        MMFPL may from time to time use cookies. Cookies are small data files that a website stores on Your computer. MMFPL may use
                        persistent cookies which are permanently placed on your computer to store non-personal (Browser, ISP, OS, Clickstream information
                        etc) and profiling information (age, gender, income etc). While cookies have unique identification nos, personal information (name,
                        a/c no, contact nos etc) shall not be stored on the cookies. We will use the information stored in the cookies to improve Your browsing
                        experience on our website, by throwing up relevant content where possible. We will also use the cookies to store visitor preferences to
                        ease visitor navigation on the site.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Disclosure and transfer of collected information
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        MMFPL does not share any of your information with third parties except: (i) with respect to providing the information or services
                        that you have requested earlier, (ii) or with your express permission for sharing such information, or (iii) with
                        intermediaries/applications who require such information as per regulatory requirements and procedure laid down by
                        SEBI/Exchanges.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Under no circumstances will we sell or rent your personal information to anyone, for any reason, at any time. MMFPL has taken best
                        and reasonable steps to ensure prevention of any unauthorised disclosure of your sensitive personal information.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Disclosure for regulatory compliances: MMFPL will share your information with judicial, administrative and regulatory entities to
                        comply with any legal and regulatory requirements.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Disclosures with your prior consent: If, at any time, you choose to utilise the integrated services of third party service partners through
                        us, we will share your information with these third party service partners with your prior consent.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Disclosure for provision of services: MMFPL will share your information with third party vendors of MMFPL as necessary for the
                        provision of services. Authorized third party vendors are bound by the same standards of data security practices and procedures as we
                        are under law and contract. They are subject to the same penalties as we are for the unauthorised disclosure of your personal
                        information.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Privacy Statement as per EU-GDPR
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        MMFPL shall implement and fulfil all its obligations under the EU General Data Protection Regulation 2016/679 (“GDPR”).
                        MMFPL shall process any personal data we collect from you in accordance with Data Protection Legislation and the provisions of
                        this Policy.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        In case You are subjected to the above stated Regulation, You would have the below stated rights:
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >

                        <ul>
                            <li>You may request your data be deleted or corrected;</li>
                            <li>You may request for access of any data that has been stored by MMFPL for providing You services;</li>
                            <li>You may request storable & readable data to be transferred to another party, this shall be done by MMFPL to the
                                maximum extent possible;</li>
                            <li>As and where any data is requested, it would be taken only post your prior consent.</li>
                        </ul>
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        We are permitted to process your personal data in compliance with Data Protection Legislation by relying on one or more of the
                        following lawful grounds:
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >

                        <ul>
                            <li> You have explicitly agreed to us processing such information for a specific reason.</li>
                            <li>The processing is necessary to perform the agreement we have with you or to take steps to enter into an agreement with
                                you.</li>
                            <li>The processing is necessary for compliance with a legal obligation we have.</li>
                            <li>The processing is necessary for the purposes of a legitimate interest pursued by us, which might be: to provide services to
                                you; to ensure that Your trading & demat account products/services are running smoothly;</li>
                            <li>To prevent, detect, investigate and prosecute fraud and alleged fraud, money laundering and other crimes and to verify your
                                identity in order to protect our business and to comply with laws that apply to us and/or where such processing is a
                                contractual requirement of the services you have requested;
                            </li>
                            <li>to ensure that complaints are investigated;</li>
                            <li>to evaluate, develop or improve our services; or</li>
                            <li>to keep our customers informed about relevant services, unless you have indicated at any time that you do not wish us to do
                                so</li>
                        </ul>
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies.
                        However, if you select this setting you may be unable to access certain parts of our website. Unless you have adjusted your browser
                        setting so that it will refuse cookies, our system will issue cookies when you log on to our site.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        After giving a written notice, a user, who is subject to GDPR, shall have the right to receive Personal Information and Non-Personal
                        Information, which is provided to MMFPL during the usage of MMFPL services, such that, this information may be used by
                        another entity which is technically feasible by all parties to the maximum extent possible. For sending across any notice pertaining to
                        removal or requesting to receive all such Personal Information and Non Personal Information, such that, this information may be
                        transferred to another entity/controller of such data as per the GDPR, all subject users are required to write to
                        “Compliance@bullforce.co”. The designated Data Protection Officer for the purposes of this GDPR shall be Mr Ganesh Maheshwari.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Security
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Our hosting services and servers maintain its systems in accordance with industry standards and best practices to reasonably secure the
                        information of its customers, such as using SSL encryption in certain places to prevent eavesdropping, and employing up-to-date
                        software on the server. However, no data transmission over the Internet can be guaranteed to be 100% secure. “Perfect security” does
                        not exist on the Internet, and therefore You use the website and mobile application at your own risk.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Your data is transmitted between your device and our servers using HTTPS protocol for encryption. HTTPS is the technology used to
                        create secure connections for your web browser, and is indicated by a padlock icon in your browser.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        MMFPL follows industry best practices, using open and known principles when transferring and storing your data. We believe the
                        biggest threat to the security and privacy of your data is if someone gains access to any of your devices. Remember to keep your
                        password safe and secret to prevent unauthorised access to your MMFPL account. If you think that the security of your MMFPL
                        account has been compromised, change your password and contact us immediately for further assistance.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Correction/Updating or Access to Personal Information
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        In case of any changes with respect to any of your information, especially Personal Information has been changed or in case you
                        would not like to continue the services of MMFPL, then you would be required to either update your Personal Information or
                        unsubscribe from any updates / information with respect to notifications on the products/services that you had earlier utilised or
                        enquired upon at MMFPL. In case you would like to modify / delete any details with respect to your Personal / Non-personal
                        information, or to access your information in readable format, you would have to write to: (i) “CustomerCare@bullforce.co”; and (ii)
                        “Compliance@bullforce.co” or to the address: (Maliram Makharia Finstock Pvt. Ltd) 911, Tulsiani Chambers, 9th Floor, Free Press Journal Marg, Nariman Point, MUMBAI, MAHARSHTRA - 400021
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >

                        Subject to Change
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        This Privacy Policy is subject to change and may be updated or revised from time to time, without any prior notification to the User, at
                        the sole discretion of MMFPL. It is the responsibility of the User to periodically review the Privacy Policy to determine whether
                        there have been any revisions or updates. The last updated date of the Policy has been mentioned above for the reference of the User.</Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 50px 0px' }} >
                        If you do not agree with the terms of this Privacy Policy or any changes made to this Policy, please stop using all products and
                        services provided by MMFPL immediately and write to us at email ID stated above.
                    </Typography>

                </Grid>
            </div >
        )
    }
}
export default (withStyles(styles)((privacy_policy)));