import { Grid, Typography, IconButton, AppBar, Toolbar, Modal, Box, SwipeableDrawer, Divider, Popover ,List,ListItemButton ,ListItemText ,Collapse} from '@mui/material'
import React, { Component, } from 'react'



import MenuIcon from '@mui/icons-material/Menu';

import { withStyles, } from "@mui/styles";

import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

import mobilemenu from '../../ui-assets/menu1.svg'
import backoffice_icon from '../../ui-assets/backoffice_icon.svg'
import trading_icon from '../../ui-assets/trading_icon.svg'

import { ExpandLess , ExpandMore } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Close } from '@mui/icons-material';

const styles = theme => ({
    // adjust logo size - 27/09/24 -dev srini
    webLogo: {
          
        "@media screen and (max-width:1200px)": {
            width:'85% !important'
        }
    },

    buttonlabel: {
        color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '15px !important',
        "&:hover": {
            color: "#00ACFE !important",
            cursor: 'pointer'

        },
    },
    buttonlabel_bold: {
        color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important'

    },
    buttonlabel_bold1: {
        cursor: 'pointer', color: '#000000', fontFamily: 'cardiuma-regular !important', fontSize: '14px',

        "&:hover": {
            color: "#00ACFE !important",
            cursor: 'pointer'

        },

    },

    menu_label: {
        color: '#ffffff !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important', fontWeight: '600 !important',

        "&:hover": {
            color: "#F3D45E !important",
            cursor: 'pointer'

        },
        // adjust - 27/09/24 -dev srini
        "@media screen and (max-width:1200px)": {
            fontSize: '14px !important'
        },

        "@media screen and (max-width:960px)": {
            color: '#ffffff !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '400 !important',
            padding: '14px',
            "&:hover": {
                color: "#F3D45E !important",
                cursor: 'pointer'

            },

        }

    },
    menu_label1: {
        color: '#ffffff !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '600 !important',

        "&:hover": {
            color: "#F3D45E !important",
            cursor: 'pointer'

        },

        "@media screen and (max-width:960px)": {
            color: '#ffffff !important', fontFamily: 'cardiuma-Bold !important', fontSize: '12px !important', fontWeight: '400 !important',
            padding: '14px',
            "&:hover": {
                color: "#F3D45E !important",
                cursor: 'pointer'

            },

        },
        /*dev-Ayush 16/7/2024 */
        "@media screen and (max-width:375px)": {
            color: '#ffffff !important', fontFamily: 'cardiuma-Bold !important', fontSize: '12px !important', fontWeight: '400 !important',
            padding: '4px 12px !important',
        }
        /*dev-Ayush 16/7/2024 */
    },

    menu_labelabc: {
        color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '600 !important',



        "@media screen and (max-width:960px)": {
            color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '400 !important',
            padding: '14px',
            "&:hover": {
                color: "#F3D45E !important",
                cursor: 'pointer'

            },

        }

    },
    menu_sublabel: {
        color: '#ffffff !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '600 !important',

        "&:hover": {
            color: "#F3D45E !important",
            cursor: 'pointer'

        },

        "@media screen and (max-width:960px)": {
            color: '#DFE0EB !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '400 !important',
            padding: '14px',
            "&:hover": {
                color: "#F3D45E !important",
                cursor: 'pointer'

            },

        }

    },

    menu_label2: {
        color: '#7DAB1C !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '600 !important',



        "@media screen and (max-width:960px)": {
            color: '#7DAB1C !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '400 !important',
            padding: '14px',


        }

    },

    menu_label3: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '600 !important',



        "@media screen and (max-width:960px)": {
            color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '400 !important',
            padding: '14px',


        }

    },
    uitlabel:
    {
        color: '#3E3E3E !important',
        fontFamily: 'cardiuma-regular !important',
        fontsize: '14px !important',
        fontstyle: 'normal',
        fontweight: '400 !important',

        "&:hover": {
            color: "#00ACFE !important",
            cursor: 'pointer'

        },
    },
    uitlabel1:
    {
        color: '#999999 !important',
        fontFamily: 'cardiuma-regular !important',
        fontsize: '14px !important',
        fontstyle: 'normal',
        fontweight: '400 !important',


    },
    maintollbar: {
        width: '50%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center',
        // adjust - 27/09/24 -dev srini
        "@media screen and (max-width:1200px)": {
            width: '55%',
        },

        "@media screen and (max-width:960px)": {
            display: 'none'

        }
    },
    //dev-srini on 09-12-2024
    maintollbar_new: {
        width: '60%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center',
        "@media screen and (max-width:1440px)": {
            width: '70%',
        },
        "@media screen and (max-width:1200px)": {
            width: '75%',
        },

        "@media screen and (max-width:960px)": {
            display: 'none'

        }
    },
    maintollbar1: {
        // adjuste width - 27/09/24 -dev srini
        width: '30%', display: 'flex', justifyContent: 'flex-end',
        "@media screen and (max-width:960px)": {
            display: 'none'

        }
    },
    menuicon: {

        "@media screen and (max-width:960px)": {
            display: 'none'

        }
    },
    menuicon1: {
        display: 'none',
        "@media screen and (max-width:960px)": {
            display: 'flex',alignItems:'center'

        }
    },

    menudivider: {
        borderColor: "#0B1420", margin: "6px 0px 2px 0px"
    },
    /*start, added by dev-Ayush 17-10-2024 */
    calculatorTxt: {
        color: "#0B1420",
        lineHeight: "2.5 !important",
        cursor: "pointer",
        "&:hover": {
            fontWeight: "bold !important"
        }
    },
    arrowIcon : {
        marginRight : "5px", fontSize : "20px !important"
    }
    /* end */
})

function HideOnScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}
const toggleDrawer = (anchor, open) => (event) => {
    if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
    ) {
        return;
    }

    // this.state({ ...state, [anchor]: open });
};


class main extends Component {
    constructor(props) {
        super(props)


        console.log(props)
        this.state = {


            open: false,
            mystyle: 0,
            pageno: 0,
            uitiopen: false,
            showanchor: false,
            anchor: 'top',
            submenu: 0,
            anchorEl1: null, //added by dev-Ayush 17-10-2024
            openCalculatorList : false //added by dev-Ayush 17-10-2024
        }
    }

    handleCalculators = (event) => { //added by dev-Ayush 17-10-2024
        this.setState({ anchorEl1: event.currentTarget })
    }

    handleCalculatorsList = () =>{ //added by dev-Ayush 17-10-2024
        this.setState({ openCalculatorList: !this.state.openCalculatorList })
    }

    handleClick = (event) => {

        this.setState({ anchorEl: event.currentTarget, open: !this.state.open })
    };
    handleClose = () => {
        this.setState({ open: false })
    };

    uitihandleClose = () => {
        this.setState({ uitiopen: false })
    };
    componentDidMount = async () => {
        window.Changemytab = (myval) => {
            console.log('Changemytab', myval)

            this.setState({ mystyle: myval });

        }



    }



    backoffice()
    {
        window.open("https://backoffice.bullforce.co/Webgroup/","_blank")
        this.setState({open:false})
    }

    trade()
    {
        window.open("https://trade.bullforce.co","_blank")
        this.setState({open:false})
    }
    

    render() {

        const open1 = Boolean(this.state.anchorEl1); //added by dev-Ayush 17-10-2024
        const id = open1 ? 'simple-popover' : undefined; //added by dev-Ayush 17-10-2024

        const { classes } = this.props;
        const { mystyle, showanchor, anchor, submenu } = this.state

        const list = (anchor) => (
            // adjusted mobile height - 26-07-24 - Dev Srini
            <Box
                sx={{ width: "auto", backgroundColor: "#1B2636", height: '100%' }}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "7px 23px 7px 10px",
                        background: '#0B1420'
                    }}
                >
                    <IconButton>
                        <a href='/'>    <img src="ui-assests/images/icon.png"
                            alt="logo"
                            style={{ marginLeft: '2%' }}
                        />
                        </a>
                    </IconButton>
                    <Close style={{ color: "#fff" }}

                        onClick={() => this.setState({ showanchor: false })}
                    >



                    </Close>
                </div>


                <div
                    id="menudiv"
                    style={{

                        alignItems: "center",
                        padding: "1px 16px",
                    }}
                >


                </div>



                <div style={{ padding: '16px' }}>
                    <a href='/login' className={classes.menu_label2}>Login</a>
                </div>
                <Divider className={classes.menudivider} />

                <div style={{ padding: '16px' }}>
                    <a href='/aboutus' className={classes.menu_label}  >About Us</a>
                </div>
                {/*<Divider className={classes.menudivider} />
                 <div style={{ padding: '16px' }}>
                    <a href='/ourproducts' className={classes.menu_label}  >Our Products</a>
                </div>
                <Divider className={classes.menudivider} />*/}
                <div style={{ padding: '16px',display:'none' }}>
                    <a className={classes.menu_label} onClick={() => this.setState({ submenu: 1 })} >Utilities</a>

                </div>

                {

                    submenu === 1 &&
                    <div style={{ paddingLeft: '32px' }}>
                        <div style={{ padding: '16px' }}>
                            <a href='/brokerage' className={classes.menu_sublabel} onClick={() => this.setState({ submenu: 0 })} >Brokerage calculator</a>

                        </div>
                        <div style={{ padding: '16px' }}>
                            <a href='/margin' className={classes.menu_sublabel} onClick={() => this.setState({ submenu: 0 })} >Margin calculator</a>

                        </div>

                    </div>
                }
    {/* added Our products in mobile view - 23/09/24 - dev-Srini */}
                <Divider className={classes.menudivider} />

                <div style={{ padding: '16px' }}>
                    <a href='/products' className={classes.menu_label}>Products</a>
                </div>

                <Divider className={classes.menudivider} />

                <div style={{ padding: '16px' }}>
                    <a href='/pricing' className={classes.menu_label}>Pricing</a>
                </div>
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                    <a href='/contactus' className={classes.menu_label} >Support</a>
                </div>
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                <a href='/payment' className={classes.menu_label} >Payment</a>
                </div>
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                    <a href='/diplomats' className={classes.menu_label}>Diplomats</a>
                </div>
                <Divider className={classes.menudivider} />

                <div style={{ padding: '16px' }}>
                <a href='/holiday_calendar' className={classes.menu_label} style={{ paddingTop: '15px' }} >Holiday Calendar</a>
                </div>
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                <a href='/circular' className={classes.menu_label} >Circular</a>
                </div>
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                <a href='/issue_disclosures' className={classes.menu_label} >Issue Disclosures</a>
                </div>
                {/* added - srini - 03-12-24 */}
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                    <a href='/blogs' className={classes.menu_label}  >Blogs</a>
                </div>
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                <a href='/faqs' className={classes.menu_label} >FAQs</a>
                </div>
                {/* added - srini - 03-12-24 */}
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                <a style={{ marginTop: '5px' }} className={classes.menu_label}
                                        href='/account_closure'>Account Closure</a>
                     </div>            
                <Divider className={classes.menudivider} />

                {/* start ,calculator list added on 17-10-2024 by dev-Ayush*/}
                {/* <div style={{paddingLeft : "16px" }}> */}
                    {/* <a href='/brokerage' className={classes.menu_label} >Brokerage calculator</a> */}
                    {/* <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent' , width : "100%"}}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton sx={{width : "150px"}} onClick={this.handleCalculatorsList}>
                            <ListItemText  primaryTypographyProps={{color: '#ffffff !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', fontWeight: '200 !important'}} primary="Calculators" />
                            {this.state.openCalculatorList ? <ExpandLess sx = {{color : "#ffffff"}} /> : <ExpandMore sx = {{color : "#ffffff"}}/>}
                        </ListItemButton>
                        <Collapse in={this.state.openCalculatorList} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                
                                
                                
                                <a style = {{display : "flex"}} className={classes.menu_label}
                        href='/sip-calculator'> <ArrowForwardIcon className={classes.arrowIcon}/> 
                        <Typography sx={{fontSize : "14px"}}>
                        SIP Calculator
                        </Typography>
                        </a>
                                 <Divider className={classes.menudivider} />
                                
                                <a style = {{display : "flex"}} className={classes.menu_label}
                        href='/stepup-sip-calculator'> <ArrowForwardIcon className={classes.arrowIcon}/>
                        <Typography sx={{fontSize : "14px"}}>
                        StepUp Sip Calculator
                        </Typography>
                        </a>
                                 <Divider className={classes.menudivider} />
                                
                                <a style = {{display : "flex"}} className={classes.menu_label}
                        href='/lumpsum-calculator'> <ArrowForwardIcon className={classes.arrowIcon}/>
                        <Typography sx={{fontSize : "14px"}} >
                        Lumpsum Calculator
                        </Typography>
                        </a>

                        <Divider className={classes.menudivider} />
                        <a style = {{display : "flex"}} className={classes.menu_label}
                        href='/brokerage'><ArrowForwardIcon className={classes.arrowIcon} />
                        <Typography sx={{fontSize : "14px"}}>
                         Brokerage Calculator
                        </Typography>
                         </a>
                                 
                                 
                            </List>
                        </Collapse>
                    </List> */}
                    {/*end */}
                {/* </div> */}
                {/* Added calculaor route by dev-trupti on 07-01-2025 */}
                <div style={{ padding: '16px' }}> 
                    <a href='/calculators' className={classes.menu_label} style={{ paddingTop: '15px' }} >Calculators</a>
                </div>
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                    <a href='/Privacy_Policy' className={classes.menu_label} style={{ paddingTop: '15px' }} >Privacy Policy</a>
                </div>
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                    <a href='/refund_policy' className={classes.menu_label} style={{ paddingTop: '15px' }} >Refund Policy</a>
                </div>
                <Divider className={classes.menudivider} />
                <div style={{ padding: '16px' }}>
                    <a href='/termsandconditions' className={classes.menu_label} style={{ paddingTop: '15px' }} >Rights & Obligations (Terms and Conditions)</a>
                </div>
                <div style={{ padding: '16px' }}>
                    <a href='/cdsl' className={classes.menu_label} style={{ paddingTop: '15px' }} >Rights & Obligations (CDSL)</a>
                </div>
                {/* Risk disclosure route added - 26-07-24 - Dev Srini */}
                <div style={{ padding: '16px' }}>
                    <a href='/risk_disclosure' className={classes.menu_label} style={{ paddingTop: '15px' }}>Risk Disclosure</a>
                </div>
                <Grid style={{ marginTop: '25px' }}></Grid>

            </Box>
        );

        return (

            <div style={{ display: mystyle === 5 ? 'None' : 'inline' }}>
                <HideOnScroll {...this.props}>
                    <AppBar elevation={0} style={{ backgroundColor: mystyle === 1 ? 'transparent' : mystyle === 5 ? 'red' : "#111", }}>
                        <Toolbar className={classes.toolbar}>
                            <Grid style={{ width: '100%', display: 'flex', alignItems:'center'}}>
                                <Grid style={{ width: '20%', cursor: 'pointer', }}>
                                    <a href='/'>
                                        <img src="ui-assests/images/iconnew.png"
                                            //Added width for logo fix by dev-trupti on 14-10-2024
                                            width='120px !important'
                                            alt="logo"
                                            style={{ paddingTop:'15px', marginLeft: '15%', visibility: mystyle === 5 ? 'hidden' : 'visible' }}
                                            // className={classes.webLogo}
                                        />
                                    </a>
                                </Grid>

                                <Grid style={{ visibility: mystyle > 2 ? 'hidden' : 'visible' }}
                                    //dev-trupti on 09-12-2024
                                    className={classes.maintollbar_new}
                                >

                                    <a href='/aboutus' className={classes.menu_label}  >About Us</a>
                                    {/* added Our products in web view - 23/09/24 - dev-Srini */}
                                    <a href='/products' className={classes.menu_label}>Products</a>

                                    {/* Utilities commented */}
                                    {/* <a className={classes.menu_label}
                                        onClick={() => this.setState({ uitiopen: !this.state.uitiopen })}

                                    >Utilities</a> */}

                                    <a href='/pricing' className={classes.menu_label}>Pricing</a>

                                    <a href='/contactus' className={classes.menu_label} >Support</a>
                                    <a href='/payment' className={classes.menu_label} >Payment</a>
                                    {/* start ,added by dev-Ayush 17-10-2024 updated the route by dev-trupti on 07-01-2025*/}
                                    <a aria-describedby={id} href='/calculators' className={classes.menu_label}>Calculators</a>
                                    {/* added - srini - 03-12-24 */}
                                    <a href='/blogs' className={classes.menu_label}>Blogs</a>
                                    <Popover
                                        sx={{
                                            ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                                                backgroundColor: "transparent !important",
                                                boxShadow: "none !important"
                                            },
                                            ".css-1dmzujt": {
                                                backgroundColor: "transparent !important",
                                                boxShadow: "none !important"
                                            }
                                        }}
                                        id={id}
                                        open={open1}
                                        anchorEl={this.state.anchorEl1}
                                        onClose={() => this.setState({ anchorEl1: null })}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Grid sx={{ padding: "24px", backgroundColor: "#FFFFFF", width: "230px", borderRadius: "16px", border: "2px solid #DFE0EB" }}>
                                            <a href='/sip-calculator'><Typography className={classes.calculatorTxt}>Sip Calculator</Typography></a>
                                            <a href='/stepup-sip-calculator'><Typography className={classes.calculatorTxt}>StepUp Sip Calculator</Typography></a>
                                            <a href='/lumpsum-calculator'><Typography className={classes.calculatorTxt}>Lumpsum Calculator</Typography></a>
                                            <a href='/brokerage'><Typography className={classes.calculatorTxt}>Brokerage Calculator</Typography></a>

                                        </Grid>
                                    </Popover>
                                    {/*end*/}
                                    {/* <a href='/faqs' className={classes.menu_label} >FAQs</a> */}
                                </Grid>
                            </Grid>
                            <Grid style={{ visibility: mystyle > 2 ? 'hidden' : 'visible' }}

                                className={classes.maintollbar1}

                            >

                                <Grid style={{ width: '80%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginRight: '5%' }}>



                                    <a href='/signup' className={classes.menu_label} style={{ background: '#F74542', padding: '4px 30px', borderRadius: '38px' }}>Register</a>
                                    <a href='/login' className={classes.menu_label} >Login</a>




                                </Grid>
                                <div className={classes.menuicon}>
                         
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                        onClick={() => this.setState({ open: !this.state.open })}


                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                            <div className={classes.menuicon1}>
                            <a href='/signup' className={classes.menu_label1} style={{ visibility: mystyle ===6 ? 'hidden' : 'visible',background: '#F74542', padding: '4px 20px', borderRadius: '24px',marginRight:'10%' }}>Register</a>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={() => this.setState({ showanchor: !this.state.showanchor })}

                                >
                                    <img src={mobilemenu} alt='menu' />
                                </IconButton>
                            </div>
                        </Toolbar>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={showanchor}
                            onClose={toggleDrawer(showanchor, false)}
                            onOpen={toggleDrawer(showanchor, true)}
                            style={{ color: 'red' }}

                        >
                            {list(anchor)}
                        </SwipeableDrawer>
                    </AppBar>

                </HideOnScroll>


                <Modal
                    sx={{ border: 'none' }}


                    disableBackdropClick={false}

                    open={this.state.open}



                    onClose={() => this.handleClose()}

                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '10%',
                            right: '5%',
                            width: '500px',
                            height: 'auto',
                            background: '#ffffff',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%',
                            border: '2px solid #DFE0EB'
                        }}
                    > 
                    <Grid style={{ width: '100%', display: 'flex', padding: '10px',justifyContent:'center' }}>
                    <Grid style={{ width: '48%', border: '1px solid #DFE0EB', borderRadius: '8px', padding: '5px', display: 'flex', alignItems: 'center' }}
                            
                            onClick={() =>  this.trade()}
                            
                            >
                                <Grid style={{ width: '25%' }}>
                                    <Grid style={{ height: '50px', width: '50px', borderRadius: '50%', background: '#F1F1F1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={trading_icon}></img>
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '75%', display: 'flex', justifyContent: 'center' }}>
                                    <Typography className={classes.buttonlabel}>Trading Platform</Typography>
                                </Grid>


                            </Grid>
                    </Grid>
                        <Grid style={{ width: '100%', display: 'flex', padding: '10px',justifyContent:'space-evenly' }}>
                       
                            <Grid style={{ width: '68%' }}>
                              
                                <Grid style={{ marginTop: '20px' }}>
                                    <a className={classes.buttonlabel_bold}>Policies</a>
                                </Grid>
                                <Grid style={{ marginTop: '5px' }}>
                                    <a href='/Privacy_Policy' className={classes.buttonlabel}>Privacy Policy</a>
                                </Grid>
                                <Grid style={{ marginTop: '5px' }}>
                                    <a href='/refund_policy' style={{ marginTop: '5px' }} className={classes.buttonlabel}>Refund Policy</a>
                                </Grid>

                                <Grid style={{ marginTop: '5px' }}>
                                    <a href='/termsandconditions' style={{ marginTop: '5px' }} className={classes.buttonlabel}>Rights & Obligations (terms and conditions)</a>
                                </Grid>
                                <Grid style={{ marginTop: '5px' }}>
                                    <a href='/cdsl' style={{ marginTop: '5px' }} className={classes.buttonlabel}>Rights & Obligations (CDSL)</a>
                                </Grid>
                                {/* Risk disclosure route added - 26-07-24 - Dev Srini */}
                                <Grid style={{ marginTop: '5px' }}>
                                    <a href='/risk_disclosure' style={{ marginTop: '5px' }} className={classes.buttonlabel}>Risk Disclosure</a>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '32%' }}>

                               {/* <Grid style={{ width: '90%', border: '1px solid #DFE0EB', borderRadius: '8px', padding: '5px', display: 'flex', alignItems: 'center' }}
                                
                                onClick={() => this.backoffice()}
                                >
                                    <Grid style={{ width: '25%' }}>
                                        <Grid style={{ height: '50px', width: '50px', borderRadius: '50%', background: '#F1F1F1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={backoffice_icon}></img>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ width: '75%', display: 'flex', justifyContent: 'center' }}>
                                        <Typography className={classes.buttonlabel}>Back office</Typography>
                                    </Grid>
                    </Grid>*/}
                                <Grid style={{ marginTop: '20px' }}>
                                    <a className={classes.buttonlabel_bold1}

                                        href='/holiday_calendar'>
                                        Holiday Calendar</a>
                                </Grid>
                                <Grid style={{ marginTop: '5px' }}>
                                    <a style={{ marginTop: '5px' }} className={classes.buttonlabel_bold1}
                                        href='/circular'>
                                        Circular</a>
                                </Grid>

                                <Grid style={{ marginTop: '5px' }}>
                                    <a style={{ marginTop: '5px' }} className={classes.buttonlabel_bold1}
                                        href='/issue_disclosures'>Issue Disclosures</a>
                                </Grid>
                                <Grid style={{ marginTop: '5px' }}>
                                    <a style={{ marginTop: '5px' }} className={classes.buttonlabel_bold1}
                                        href='/account_closure'>Account Closure</a>
                                </Grid>
                                <Grid style={{ marginTop: '5px' }}>
                                    <a style={{ marginTop: '5px' }} className={classes.buttonlabel_bold1}
                                        href='/faqs'>FAQs</a>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Box>
                </Modal>
                <Modal
                    sx={{ border: 'none' }}


                    disableBackdropClick={false}

                    open={this.state.uitiopen}



                    onClose={() => this.uitihandleClose()}

                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '10%',
                            right: '52%',

                            width: '20%',
                            height: 'auto',
                            background: '#ffffff',
                            outline: 'none',
                            p: 4,
                            borderRadius: '6px',

                            border: '2px solid #DFE0EB'
                        }}
                    >
                        <Grid style={{ width: '100%', padding: '24px' }}>
                            <div>
                                <a className={classes.uitlabel}
                                    href='/brokerage'>
                                    Brokerage calculator</a>
                            </div>
                            <div style={{ marginTop: '16px' }}>
                                <Typography className={classes.uitlabel1}
                                >
                                    Margin calculator</Typography>
                            </div>


                        </Grid>

                    </Box>
                </Modal>
            </div>

        )
    }
}
export default (withStyles(styles)((main)));
