import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import {
    Typography, Grid, Button, Box,
    
} from '@mui/material'


import fullbull from '../../ui-assets/fullbull.svg'

import aboutus1 from '../../ui-assets/aboutus1.svg'
import aboutus2 from '../../ui-assets/aboutus2.svg'
import aboutus3 from '../../ui-assets/aboutus3.svg'
import aboutus4 from '../../ui-assets/aboutus4.svg'

import funders_bull from '../../ui-assets/founders_bull.svg'
import funders_bull1 from '../../ui-assets/founders_bull1.svg'

import noimg from '../../ui-assets/noimage.svg'
import advimg1 from '../../ui-assets/adv_img1.png'
import advimg2 from '../../ui-assets/adv_img2.png'
import advimg3 from '../../ui-assets/adv_img3.png' //dev-trupti on 25-10-2024
import advimg4 from '../../ui-assets/adv_img4.png' //dev-trupti on 20-01-2025
import committeimg from '../../ui-assets/committeimg.png'  //dev-trupti on 02-12-2024
import executiveimg1 from '../../ui-assets/executiveimg1.png'
import executiveimg2 from '../../ui-assets/executiveimg2.png'
import executiveimg3 from '../../ui-assets/executiveimg3.png' //dev-trupti on 30-12-2024

import subbu from '../../ui-assets/subbu.png'
import axisbank from '../../ui-assets/axis.png'
import hdfcbank from '../../ui-assets/hdfc.png' //dev-trupti on 30-12-2024
import mlog from '../../ui-assets/maliram_logo.svg'
import Fotter from './fotter'
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'About us | BullForce',
    description: 'Bullforce: Leading NSE, BSE, CDSL trading platform. Invest in stocks, bonds, mutual funds, gold, IPOs. Trusted by NRIs, HNIs, and institutions for 25+ years.',
    canonical: 'https://bullforce.co/aboutus',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'aboutus,bullforce, NRIs, HNIs, and institutions, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs'
        }
    }
}

const styles = theme => ({

    back_img: {


        backgroundRepeat: "no-repeat !important",
        backgroundPosition: "center center !important",


        backgroundSize: "75%",





        height: '100vh',
        marginTop: '50px'



    },
    about_tit: {
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "20px !important",
        color: "#414141 !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        // h1 tags margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media only screen and (max-device-width: 480px)": {
            fontSize: "18px !important",
            
        }


    },
    about_info: {
        paddingTop: '15px',
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "18px !important",
        color: "#414141 !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-regular !important",
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "14px !important",
           
        }


    },
    Btn: {
        background: "#F74542 !important",
        padding: "16px 24px !important",
        borderRadius: "38px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "14px !important",
            padding: "12px 24px !important",
        }
    },
    founder_tit: {
marginTop:'75px !important',
        color: "#0B1420 !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "48px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        textAlign: 'center',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media only screen and (max-device-width: 480px)": {
            marginTop:'32px !important',
            fontSize: "24px !important",
            padding: "16p !important",
            textAlign: 'left',
        }
    },

    founder_disp: {

        color: "#0B1420 !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "16px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-regular !important",
        padding: '0px 100px 0px 100px',
        wordBreak: 'break-word',
        "@media only screen and (max-device-width: 480px)": {
            padding: '0px 16px',
            fontSize: "12px !important",
        }

    },


    founder_subtit: {

        color: "#F3B95B !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "26px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        textAlign: 'center',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media only screen and (max-device-width: 480px)": {
            fontSize: "18px !important",
        }
    },
    founder_subtit1: {

        color: "#F3B95B !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "16px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-regular !important",
        textAlign: 'center',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media only screen and (max-device-width: 480px)": {
            fontSize: "12px !important",
        }
    },
    founder_subtit2: {

        color: "#FFFFFF !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "26px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        textAlign: 'center',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media only screen and (max-device-width: 480px)": {
            fontSize: "18px !important",
        }
    },
    adv_tit: {
        color: "#FFFFFF !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "48px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media only screen and (max-device-width: 480px)": {
            fontSize: "24px !important",
        }
    },
    adv_subtit: {
        color: "#F3B95B !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "20px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        paddingTop:'20px',
        textAlign:'left',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    adv_subtit1: {
        color: "#FFFFFF !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-M !important",
        textAlign:'left',
        padding:'8px 0px',
        marginBlockStart: 0,
        marginBlockEnd: 0,

    },
    adv_subtit2: {
        color: "#FFFFFF !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-regular !important",
        textAlign:'left',
       
    },
    ex_subtit: {
        color: "#414141 !important30",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "20px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        padding: '24px 0px 2px 0px'
    },
    ex_subtit1: {
        color: "#414141 !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-regular !important",

    },
    Auditors_tit: {
        color: "#E1F1A9 !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "24px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        textDecoration: 'underline'
    },
    Auditors_subtit: {
        color: "#ffffff !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "20px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        paddingTop: '12px'

    },
    Auditors_subtit1: {
        color: "#ffffff !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-regular !important",


    },
    compsec_tit: {
        color: "#DCDFFF !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "24px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        textDecoration: 'underline'
    },
    Legal_tit: {
        color: "#FCBAC0 !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "24px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        textDecoration: 'underline'
    },
    bankname: {
        color: "#0B1420 !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "16px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        marginBlockStart: 0,
        marginBlockEnd: 0,
        textWrap:'nowrap !important',
        "@media only screen and (max-device-width: 300px)": {
            fontSize: "14px !important",
        }
    },
    about_main:{
         width: '100%', display: 'flex', padding: '90px 0px 0px 50px' ,
        "@media only screen and (max-device-width: 900px)": {
            display: 'none', padding: '50px 16px 10px 16px' ,
        }
    },
    about_main1:{
       width: '100%', display: 'none', alignItems: 'center',
        "@media only screen and (max-device-width: 900px)": {
            display: 'block', padding: '50px 16px' ,
        }
    },
    about_card1:{

        width: '60%', padding: '0px 0px 0px 50px' ,
        "@media only screen and (max-device-width: 900px)": {
            width: '100%', padding: '16px' ,
        }

    },

    about_card2:{

         width: '40%', padding: '0px 75px 10px 75px', display: 'flex', justifyContent: 'center', 
        "@media only screen and (max-device-width: 900px)": {
            display:'none'
        }

    },
    about_card3:{
        display:'none',
       
       "@media only screen and (max-device-width: 900px)": {
        width: '100%',  display: 'flex', justifyContent: 'center' ,
      

       }

   },
   about_txt:{

    color: '#BCD171 !important', fontSize: '32px !important', fontFamily: 'cardiuma-Bold !important',
    marginBlockStart: 0, marginBlockEnd: 0,
    // Adjust padding for About us - 30/09/24 -Dev Srini
    "@media only screen and (max-device-width: 900px)": {
        fontSize: '22px !important',
        marginTop : "25px !important"
       }
   },
   about_deta:{
    color: '#ffffff', 
    fontFamily: 'cardiuma-regular !important',
    fontSize: '22px !important',
    fontStyle: 'normal',
    fontWeight: '100',
    lineHeight: '32px',
    // Adjust padding for About us - 30/09/24 -Dev Srini
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '14px !important"',
        width:'100%'
       }
   },
   but_card:{
    marginTop: '10px', paddingLeft: '50px', width: '100%', display: 'flex', alignItems: 'center', 
    "@media only screen and (max-device-width: 900px)": {
        paddingLeft: '0px',
        width: '100%', display: 'flex', alignItems: 'center',justifyContent:'center' 
       }
   },
   miss_card:{
    marginTop: '75px', width: '100%', display: 'flex', justifyContent: 'center', background: '#ffffff' ,
    "@media only screen and (max-device-width: 480px)": {
        padding: '0px 0px 0px 16px', width: '100%', display: 'flex',  justifyContent: 'left',
    }
   },
   miss_card1:{
    padding: '15px ', width: '100%', display: 'flex', justifyContent: 'space-evenly', background: '#ffffff' ,
    "@media only screen and (max-device-width: 480px)": {
        padding: '16px', width: '100%', display: 'block',  justifyContent:'center'
    }

   },
   miss_card2:{
    padding: '20px', width: '24%', background: '#DCDFFF', border: '1px solid #D9D9D9', borderRadius: '12px',
    "@media only screen and (max-device-width: 480px)": {
        marginTop: '16px', width: '100%', justifyContent:'center'
    }

   },
   miss_card3:{
    padding: '20px', width: '24%', background: '#FCBAC0', border: '1px solid #D9D9D9', borderRadius: '12px',
    "@media only screen and (max-device-width: 480px)": {
        marginTop: '16px', width: '100%',   justifyContent:'center'
    }
   },
   miss_card4:{
    padding: '20px', width: '24%', background: '#E1F1A9', border: '1px solid #D9D9D9', borderRadius: '12px',
    "@media only screen and (max-device-width: 480px)": {
        marginTop: '16px', width: '100%',   justifyContent:'center'
    }
   },
   miss_card5:{
    padding: '20px', width: '24%', background: '#CBE9FF', border: '1px solid #D9D9D9', borderRadius: '12px',
    "@media only screen and (max-device-width: 480px)": {
        marginTop: '16px', width: '100%',   justifyContent:'center'
    }
   },
   img_card11:{
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',  display:'flex',justifyContent:'center' 
    }
   },
   img_card1:{
    background: '#ffffff', width: '91px', height: '91px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',
    "@media only screen and (max-device-width: 480px)": {
        width: '100px',     height: '100px'
    }

   },
   fcard:{
    paddingTop: '108px', background: '#FFFFFF',
    "@media only screen and (max-device-width: 480px)": {
        paddingTop: '32px', background: '#FFFFFF',
    }

   },
   fcard1:{
    width: '100%', padding: '8px 32px', background: '#FDF1DE' ,
    "@media only screen and (max-device-width: 480px)": {
        padding: '1px',display:'flex',justifyContent:'center'
    }
   },
   fcard2:{
    width: '100%', background: '#FDF1DE' ,
    "@media only screen and (max-device-width: 480px)": {
        
    }
   },
   fin_main:{
    width: '100%', display: 'flex', justifyContent: 'center', padding: '33px 33px 10px 33px',
     background: '#FDF1DE' ,
    "@media only screen and (max-device-width: 480px)": {
        padding: '16px', 
    }
   },
   fin_card:{
    width: '40%', background: '#0B1420 !important',
 borderRadius: '12px', display: 'flex', padding: '20px',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',
        
    }
   },
   fin_card1:{
   width: '100%', display: 'flex', justifyContent: 'space-evenly', paddingTop: '32px',
   "@media only screen and (max-device-width: 480px)": {
    width: '100%', display: 'block', justifyContent: 'center', padding: '16px',
    
}
   },
   fin_card2:{
    width: '33%', background: '#0B1420', borderRadius: '12px', padding: '23px 0px 0px 0px', display: 'flex',


    backgroundRepeat: "no-repeat",
    backgroundSize: '120px',
    backgroundPosition: "left center",
    backgroundImage: `url(${funders_bull})`,
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',
        backgroundImage: `none`,
        padding: '16px'
    }
   },
   fin_card3:{
    width: '33%', background: '#0B1420', borderRadius: '12px', padding: '23px 0px 0px 0px',
    "@media only screen and (max-device-width: 480px)": {
        marginTop:'16px',
        width: '100%',
    
        padding: '16px'
    }

   },
   fin_card4:{
   width: '33%', background: '#0B1420', borderRadius: '12px', padding: '23px', display: 'flex',  alignItems: 'center',
   backgroundRepeat: "no-repeat",
   backgroundSize: '120px',
   backgroundPosition: "right center",
   backgroundImage: `url(${funders_bull1})`,
   "@media only screen and (max-device-width: 480px)": {
 marginTop:'16px',
    width: '100%',
    backgroundImage: `none`,
    padding: '16px'
}
   },
   bank_main:{
    width: '100%', background: '#ffffff', padding: '108px 0px 108px 0px', borderTop: '1px solid #DFE0EB',
    "@media only screen and (max-device-width: 480px)": {
        padding: '0px'
    }

   },
   bank_img1:{
    // height: '50%', //dev-trupti on 30-12-2024
    height:'52px !important',
    width:'212px !important',
    "@media only screen and (max-device-width: 600px)": {
        width:'180px !important',
        height:'52px !important'
    },
    "@media only screen and (max-device-width: 480px)": {
        width:'110px !important',
        height:'28px !important'
    }
   },
   bank_img2:{
    height:'52px !important',
    width:'212px !important',
    "@media only screen and (max-device-width: 600px)": {
        width:'180px !important',
        height:'52px !important'
    },
    "@media only screen and (max-device-width: 480px)": {
        width:'110px !important',
        height:'28px !important'
    }
   },
   //add 01/03/2024
   fcard1_1:{
    width: '100%', padding: '50px', background: '#FDF1DE' ,
    "@media only screen and (max-device-width: 480px)": {
        padding: '25px',display:'flex',justifyContent:'center'
    }
   },
   adv_main_card:{
    width: '100%', display: 'flex', background: '#0B1420', 
    "@media only screen and (max-device-width: 480px)": {
      display:'block'
    }
   },
   adv_card:{
    width: '50%',display: 'flex', justifyContent:'center',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',display:'block'
    }
},
adv_img:{
    height:'167px',width:'167px',
    "@media only screen and (max-device-width: 480px)": {
        height:'140px',width:'140px',
    }

},
adv_img1:{
    paddingTop:'0px',
    "@media only screen and (max-device-width: 480px)": {
        paddingTop:'50px',
    }

},
   //end 01/03/2024
   
    adv_img_card:{
width:'25%',
"@media only screen and (max-device-width: 480px)": {
    width:'100%'
}
    },
    adv_img_card1:{
        display:'table-column',marginLeft:'45px',
        "@media only screen and (max-device-width: 480px)": {
           justifyContent:'center',display:'grid',marginLeft:'0px',
        } 
    },
    adv_img_card2:{
    width:'100%',display:'flex',justifyContent:'left',
    "@media only screen and (max-device-width: 480px)": {
        width:'100%',display:'flex', justifyContent:'center'
     } 
    },

    adv_sub_grid:{
        width:'380px !important',
        marginLeft:'30px !important',
        "@media only screen and (max-device-width: 1200px)": {
            marginLeft:'0px !important',
     } 
    },

    //Added new advisory section styles by dev-trupti on 25-10-2024 
    advisory_title:{
        color: "#FFFFFF !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "48px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingTop:'60px !important',
        paddingBottom:'20px !important',
        textAlign:'center !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: "24px !important",
            paddingTop:'30px !important ',
            paddingBottom:'10px !important',
        }
    },
    advisory_name:{
        color: "#F3B95B !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "20px !important",
        fontFamily: "cardiuma-Bold !important",
        paddingTop:'30px !important',  //dev-trupti on 27-10-2024
        "@media only screen and (max-device-width: 480px)": {
            paddingTop:'10px !important', 
        },
        "@media only screen and (max-device-width: 1200px)": {
            textAlign:'center !important'
        }
    },
    advisory_member:{
        color: "#BCD171 !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "16px !important",
        fontFamily: "cardiuma-SemiBold !important",
        paddingTop:'8px', 
        textWrap: 'nowrap !important',
        "@media only screen and (max-device-width: 1200px)": {
            textAlign:'center !important'
        }
    },
    advisory_about:{
        marginTop:'10px !important',
        color: "#FFFFFF !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        lineHeight:'normal !important',
        "@media only screen and (max-device-width: 1200px)": {
            textAlign:'center !important',
        } 
    },
    advisory_about_text :{
        fontFamily: "cardiuma-regular !important",
        fontSize: "14px !important",
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "12px !important",

        }
    },  //dev-trupti on 02-12-2024
    advisory_about_text_wrap :{
        fontFamily: "cardiuma-regular !important",
        fontSize: "14px !important",
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "12px !important",
            textWrap:'nowrap !important'
        }
    },
    main_grid:{
        paddingBottom:'50px !important',
        "@media only screen and (max-device-width: 480px)": {
            paddingBottom:'15px !important' //dev-trupti on 27-10-2024

        }
    },
    container_grid : {
        width: '100% !important', 
        background: '#0B1420 !important', 
        justifyContent:'center !important', 
        alignItems:'center !important'
    },
    sub_container_grid : {
    display:"flex !important", 
    justifyContent:'center !important'
    },
    sub_box : {
    // width:'350px !important', 
    display:'flex !important',
    flexDirection:'row !important',
    alignItems:'center !important',
    margin:'30px !important', //dev-trupti on 27-10-2024
    "@media only screen and (min-device-width: 520px) and (max-device-width: 899px)": {
        width: '500px !important'
    },
    "@media only screen and (max-device-width: 480px)": {
        margin:'35px 15px 15px 15px !important'
    },
    "@media only screen and (max-device-width: 1200px)": {
        flexDirection:'column !important',
    }
    },

    //Committee styles added by dev-trupti on 02-12-2024
    committeeGrid : {
        backgroundColor:'#F3D45E !important',
        
    },

    committe_img :{
        height:'211px',width:'211px',
        "@media only screen and (min-device-width: 480px) and (max-device-width:900px)": {
            height:'167px',width:'167px',
        },
        "@media only screen and (max-device-width: 480px)": {
            height:'150px',width:'150px',
        }
    },
    sub_committee_grid:{
        padding:'70px 20px 70px 20px !important',
        display:'flex !important',
        justifyContent:'center !important',
        "@media only screen and (max-device-width: 480px)": {
            padding:'35px 15px 35px 15px !important',
        } 
    },
    committee_name:{
        color: "#0B1420 !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        fontSize: "32px !important",
        fontFamily: "cardiuma-Bold !important",
        paddingTop:'8px !important',  
        textAlign:'center !important',
        "@media only screen and (max-device-width: 500px)": {
            paddingTop:'10px !important', 
            fontSize: "24px !important",
        }      
    },
    committee_member:{
        color: "#0B1420 !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "24px !important",
        fontFamily: "cardiuma-SemiBold !important",
        paddingTop:'2px', 
        textAlign:'center !important',
        textWrap: 'nowrap !important',
        "@media only screen and (max-device-width: 500px)": {
            fontSize: "16px !important",
        } 
    },
    committee_sub_box : {
        display:'flex !important',
        flexDirection:'column !important',
        alignItems:'center !important',
        textAlign:'center !important',
    },

    seperator:{
        fontWeight:'900 !important'
    },

    committee_about_deskstop :{
        marginTop: '7px !important',
        
        "@media only screen and (max-width: 1030px)" : {
            display: 'none !important'  
        },
    },
    
    committee_about_tab :{
        marginTop: '7px !important',
        '@media only screen and (max-width: 500px)': {
            display: 'none !important', 
        },
       '@media only screen and (min-width: 501px) and (max-width: 1031px)': {
            display: 'block !important' 
        },
        '@media only screen and (min-width: 1031px)': {
            display: 'none !important',
        },
    },
    
    committee_about_mobile: {
        marginTop: '7px !important',
        '@media only screen and (max-width: 500px)' :{
            display: 'block !important', 
        },
        '@media only screen and (min-width: 501px)' :{
            display: 'none !important', 
        },
    },
    committee_about_text :{
        fontFamily: "cardiuma-regular !important",
        fontSize: "16px !important",   
        '@media only screen and (max-width: 900px)' :{
            fontSize: "16px !important",   
        },
        '@media only screen and (max-width: 590px)' :{
            fontSize: "14px !important",   
        },
    },

    committee_about_nowrap:{
        fontFamily: "cardiuma-regular !important",
        fontSize: "14px !important",  
        textAlign:'center !important', 
        textWrap:'nowrap !important',
    },
    //executive leadership styles added by dev-trupti 

    executive_main_grid:{
        paddingBottom:'50px !important',
        "@media only screen and (max-device-width: 480px)": {
            paddingBottom:'0px !important' //dev-trupti on 27-10-2024
        }
    },

    executive_grid:{
        backgroundColor:'#f3d45e !important'
    },
    
    executive_title:{
        color: "#000000 !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "48px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingTop:'50px !important',
        paddingBottom:'20px !important',
        textAlign:'center !important',
        "@media only screen and (max-device-width: 560px)": {
            fontSize: "40px !important",
        },

        "@media only screen and (max-device-width: 480px)": {
            fontSize: "24px !important",
            paddingTop:'30px !important ',
            paddingBottom:'30px !important',
        }
    },

    executive_container_grid : {
        width: '100% !important', 
        background: '#f3d45e !important', 
        justifyContent:'center !important', 
        alignItems:'center !important'
    },

    executive_name:{
        color: "#0B1420 !important",
        textAlign:'center !important',
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "20px !important",
        fontFamily: "cardiuma-Bold !important",
        paddingTop:'30px !important',  //dev-trupti on 27-10-2024
        "@media only screen and (max-device-width: 480px)": {
            paddingTop:'10px !important', 
        }      
    },

    executive_member:{
        width: '235px !important',
        textAlign:'center !important',  //dev-trupti on 02-12-2024
        color: "#3E3E3E !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "16px !important",
        fontFamily: "cardiuma-SemiBold !important",
        paddingTop:'8px', 
        // textWrap: 'nowrap !important'
        "@media only screen and (max-device-width: 480px)": {
            fontSize:'14px !important', 
            paddingTop:'0px', 
        } 
    },
    
    executive_sub_box : {
        height:'285px !important',
        width:'337px !important', 
        display:'flex !important',
        flexDirection:'column !important',
        alignItems:'center !important',
        margin:'30px !important', //dev-trupti on 27-10-2024
        
        "@media only screen and (min-device-width: 500px) and (max-device-width: 899px)": {
            width: '500px !important',
            margin:'35px 35px 0px 35px !important'
        },
        "@media only screen and (max-device-width: 480px)": {
            margin:'0px 15px 30px 15px !important',
            height:'auto !important',

        }
        },

    border : {
        height: '139px', 
        marginTop: '35px !important',
        "@media only screen and (max-device-width: 480px)": {
            height:'72px !important',
            marginTop:'15px !important'
        }
    },
    bankGrid1:{
        padding: '42px', 
        width: '48%', 
        display: 'flex', 
        justifyContent: 'flex-end',
        "@media only screen and (max-device-width: 480px)": {
            padding:'20px 20px 32px 20px !important'
        }
    },
    bankGrid2:{
    padding: '42px', width: '48%',
    "@media only screen and (max-device-width: 480px)": {
            padding:'20px 20px 32px 20px !important'
        }
    }
})

class aboutus extends Component {
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        document.body.style.backgroundColor = "#0B1420"
    }
    
    render() {
        const { classes } = this.props;
        return (
            <div>
            <DocumentMeta {...meta} />
             <Grid className={classes.about_main} >
                <Grid style={{width:'50%'}}>
                    {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
               <h1  className={classes.about_txt}>About Us</h1>
               <Typography style={{ marginTop: '25px', color: '#ffffff', fontSize: '22px', fontFamily: 'cardiuma-regular' }}>“BULLFORCE” is a brand , exclusively representing Maliram Makharia Finstock Private Limited”.</Typography>

<Typography className={classes.about_deta} style={{ marginTop: '5px'}}>
    Maliram Makharia Finstock Private Limited (MMFPL) is one of the most experienced broking house with 25+ years of diversified experience in the Indian equity, derivative markets.
    MMFPL started in the year 1996 with the objective of assuring the best ever possible client services and world class investor experience..</Typography>
               </Grid>
               <Grid style={{width:'50%',display:'flex',justifyContent:'center'}}>
               <Grid  className={classes.about_card2}>
                <Grid style={{display:'block'}}>
                        <img src={fullbull} alt='bulllogo' width='330px' height='225px' ></img>

                        <img src={mlog} alt="mlogo" style={{marginTop:'10%'}}></img>
                        </Grid>
                    </Grid>
               </Grid>
               </Grid>
                <Grid className={classes.about_main1}>

                <h1  className={classes.about_txt}>About Us</h1> 
                <Grid  className={classes.about_card3}>
              
                <Grid style={{display:'block'}}>
                    <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <img src={fullbull} alt='bulllogo' width='200px' height='155px' ></img>
                        </Grid>
                        <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <img src={mlog} alt="mlogo"  style={{marginTop:'2%',width:'60%'}}></img>
                        </Grid>
                        </Grid>
                    </Grid>

                    <Typography style={{ marginTop: '25px', color: '#ffffff', fontSize: '22px', fontFamily: 'cardiuma-regular' }}>“BULLFORCE” is a brand , exclusively representing Maliram Makharia Finstock Private Limited”.</Typography>

<Typography className={classes.about_deta} style={{ marginTop: '5px'}}>
    Maliram Makharia Finstock Private Limited (MMFPL) is one of the most experienced broking house with 25+ years of diversified experience in the Indian equity, derivative markets.
    MMFPL started in the year 1996 with the objective of assuring the best ever possible client services and world class investor experience..</Typography>
               
                  

                </Grid>
                <Grid className={classes.but_card}>
                    <Button className={classes.Btn}
                    
                    onClick={() => this.props.history.push('/contactus', this.state)}

                    >Reach Out To Us

                   
                    </Button>
                </Grid>

                <Grid className={classes.miss_card}>

                    <h1 className={classes.founder_tit} >Our Mission</h1>
                </Grid>

                <Grid  className={classes.miss_card1}>
                    <Grid className={classes.miss_card2}>
                        <Grid className={classes.img_card11}>
                        <Grid className={classes.img_card1} >
                            <img src={aboutus1} alt='aboutus'></img>
                        </Grid>
                        </Grid>
                        <h3 className={classes.about_tit} style={{ marginTop: '3%' }}>Remove complexities and adopt simple processes.</h3>

                        <Typography className={classes.about_info} >The most advanced technology platform and highly simplified user interface is making every step of the investment process very simple right from the onboarding until settlement, without compromising the investor security and safely.</Typography>
                    </Grid>
                    <Grid className={classes.miss_card3}>
                    <Grid className={classes.img_card11}>
                        <Grid className={classes.img_card1} >
                            <img src={aboutus2} alt='aboutus2'></img>
                        </Grid>
                        </Grid>
                        <h3 className={classes.about_tit} style={{ marginTop: '3%' }}>Continuously Bring new investors into the market.</h3>
                        <Typography className={classes.about_info} >We would continuously strive to bring new investors from various economic backgrounds into the market and play a key role in building the retail investor base in the India market.</Typography>
                    </Grid>
                    <Grid className={classes.miss_card4}>
                    <Grid className={classes.img_card11}>
                        <Grid className={classes.img_card1} >
                    
                            <img src={aboutus3} alt='aboutus3'></img>
                        </Grid>
                        </Grid>
                        <h3 className={classes.about_tit} style={{ marginTop: '3%' }}>Building social investor networks.</h3>
                        <Typography className={classes.about_info} >In line with the present context of rapidly growing social networking within the Indian societies, we are building a number of well-designed socio-investor networks and bring the investor community very close to the Indian and Global financial market information and news.</Typography>
                    </Grid>
                    <Grid className={classes.miss_card5}>
                    <Grid className={classes.img_card11}>
                        <Grid className={classes.img_card1} >
                    
                            <img src={aboutus4} alt='aboutus4'></img>
                        </Grid>
                        </Grid>
                        <h3 className={classes.about_tit} style={{ marginTop: '3%' }}>Building an enlightened trading community</h3>
                        <Typography className={classes.about_info} >The education and awareness of are the key factors of successful investments. We are running “zero cost” educational sessions both online and offline to build a knowledgable and enlightened investor community.</Typography>
                    </Grid>
                </Grid>

                <Grid className={classes.fcard}>


                </Grid>
                <Grid className={classes.fcard1}>
                <h1 className={classes.founder_tit}>Founders</h1>
                </Grid>
                <Grid className={classes.fcard2}>


                   

                    <Grid className={classes.fin_main}>



                        <Grid className={classes.fin_card}>

                            <Grid style={{
                                width: '25%',

                                backgroundRepeat: "no-repeat",
                                backgroundSize: '100px',
                                backgroundPosition: "center center",
                                backgroundImage: `url(${funders_bull})`

                            }}></Grid>

                            <Grid style={{
                                width: '50%',



                            }}>
                                <h1 className={classes.founder_subtit}>Srinath U.K.</h1>

                                <h3 className={classes.founder_subtit1}>Founder - BULLFORCE.</h3>
                            </Grid>
                            <Grid style={{
                                width: '25%',

                                backgroundRepeat: "no-repeat",
                                backgroundSize: '100px',
                                backgroundPosition: "Right center",
                                backgroundImage: `url(${funders_bull1})`

                            }}></Grid>

                        </Grid>
                    </Grid>
                    <Grid >
                        <p className={classes.founder_disp} style={{ wordWrap: 'break-word' }}>
                            Srinath is the principal architect of BullForce. He is a passionate investment advisor with 25+ years of experience and
                            deepest understanding of trading and investment strategies. He has been mentoring many of the investors communities
                            within the close circle on equity investments in the global markets.
                        </p>
                    </Grid>
                    <Grid className={classes.fin_card1}>

                        <Grid className={classes.fin_card2}>

                            <Grid style={{ width: '100%' }}>
                                <h1 className={classes.founder_subtit2}>L. H. Pereira.</h1>
                                <h3 className={classes.founder_subtit1}>Co-Founder - BULLFORCE.</h3>
                            </Grid>
                        </Grid>
                        <Grid className={classes.fin_card3} >
                            <h1 className={classes.founder_subtit2}>Subrahmanya Basavanahalli</h1>
                            <h3 className={classes.founder_subtit1}>Co-Founder - BULLFORCE.</h3>
                        </Grid>
                        <Grid className={classes.fin_card4}
                        >
                            <Grid style={{
                                width: '100%',


                            }}>
                                <h1 className={classes.founder_subtit2}>Rajesh Sundrani</h1>
                                <h3 className={classes.founder_subtit1}>Co-Founder - BULLFORCE.</h3>

                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.fcard1_1}>
               
                </Grid> 
                {/*Commiittee section start by dev-trupti on 02-12-2024*/}
                <Grid className={classes.committeeGrid}>
                <Grid xl={12} lg={12} md={12} sm={12} xs={12} className={classes.sub_committee_grid}>
                            <Box className={classes.committee_sub_box}>
                                <img className={classes.committe_img} src={committeimg}  alt={noimg}></img>
                                <Typography className={classes.committee_name}>Rajesh Tiwari</Typography>
                                <Typography className={classes.committee_member}>Chairman – Reviewing Committee</Typography>
                                <Grid className={classes.committee_about_deskstop}>
                                    <Typography className={classes.committee_about_text}>Director General & CEO of Indian Centre for CSR (ICCSR) <span className={classes.seperator}>|</span> Co-Founder and JMD (Former) of Tikona Digital Networks Pvt. Ltd.,</Typography>
                                    <Typography className={classes.committee_about_text}>Group President (Former) of Reliance Industries Limited <span className={classes.seperator}>|</span>  Group Director (Former) of Reliance-ADAG,</Typography>
                                    <Typography className={classes.committee_about_text}>Executive Director (Former) of Bharat Aluminium Limited (BALCO) <span className={classes.seperator}>|</span> Founding team member – MTNL,</Typography>
                                    <Typography className={classes.committee_about_text}>Executive Vice President of Afro-Asian Satellite Communications</Typography>
                                </Grid>
                                <Grid className={classes.committee_about_tab}>
                                    <Typography className={classes.committee_about_text}>Director General & CEO of Indian Centre for CSR (ICCSR),</Typography>
                                    <Typography  className={classes.committee_about_text}>Co-Founder and JMD (Former) of Tikona Digital Networks Pvt. Ltd.,</Typography>
                                    <Typography className={classes.committee_about_text}>Group President (Former) of Reliance Industries Limited,</Typography>
                                    <Typography  className={classes.committee_about_text}>Group Director (Former) of Reliance-ADAG,</Typography>
                                    <Typography className={classes.committee_about_text}>Executive Director (Former) of Bharat Aluminium Limited (BALCO),</Typography>
                                    <Typography  className={classes.committee_about_text}>Founding team member – MTNL,</Typography>
                                    <Typography className={classes.committee_about_text}>Executive Vice President of Afro-Asian Satellite Communications</Typography>
                                </Grid>
                                <Grid className={classes.committee_about_mobile}>
                                <Typography className={classes.committee_about_nowrap}>Director General & CEO of Indian Centre for</Typography>
                                <Typography className={classes.committee_about_nowrap}> CSR (ICCSR),</Typography>
                                <Typography  className={classes.committee_about_nowrap}>Co-Founder and JMD (Former) of Tikona</Typography>
                                <Typography  className={classes.committee_about_nowrap}>Digital Networks Pvt. Ltd.,</Typography>
                                <Typography className={classes.committee_about_nowrap}>Group President (Former) of Reliance</Typography>
                                <Typography className={classes.committee_about_nowrap}>Industries Limited,</Typography>
                                <Typography  className={classes.committee_about_nowrap}>Group Director (Former) of Reliance-ADAG,</Typography>
                                <Typography className={classes.committee_about_nowrap}>Executive Director (Former) of Bharat</Typography>
                                <Typography className={classes.committee_about_nowrap}>Aluminium Limited (BALCO),</Typography>
                                <Typography  className={classes.committee_about_nowrap}>Founding team member – MTNL,</Typography>
                                <Typography className={classes.committee_about_nowrap}>Executive Vice President of Afro-Asian,</Typography>
                                <Typography className={classes.committee_about_nowrap}>Satellite Communications</Typography>
                                </Grid>
                            </Box>
                        </Grid>
                </Grid>
                {/*Commiittee section start by dev-trupti on 02-12-2024*/}
                {/* Updated Advisory code start by dev-trupti on 20-01-2025*/}
                <Grid >
                <Typography className={classes.advisory_title}>Advisory Board</Typography>
                <Box className={classes.main_grid} >
                    <Grid container className={classes.container_grid}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.sub_container_grid}>
                            <Box className={classes.sub_box}>
                                <img className={classes.adv_img} src={advimg1}  alt={noimg}></img>
                                <Grid className={window.innerWidth > 1119 ? classes.adv_sub_grid : null}>
                                    <Typography className={classes.advisory_name}>Narendra Mairpady</Typography>
                                    <Typography className={classes.advisory_member}>Member of Advisory Board</Typography>
                                    <Grid className={classes.advisory_about}>
                                        <Typography className={classes.advisory_about_text}>Former Chairman & Managing</Typography>
                                        <Typography className={classes.advisory_about_text}>Director - Indian Overseas Bank.</Typography>
                                        <Typography className={classes.advisory_about_text}>Former Executive Director - Bank of India</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.sub_container_grid}>
                            <Box className={classes.sub_box}>
                                <img className={classes.adv_img} src={advimg4}  alt={noimg}></img>
                                <Grid className={window.innerWidth > 1119 ? classes.adv_sub_grid : null}>
                                    <Typography className={classes.advisory_name}>Dr. Subramanya Kusnur</Typography>
                                    <Typography className={classes.advisory_member}>Member of Advisory Board</Typography>
                                    <Grid className={classes.advisory_about}>
                                        <Typography className={classes.advisory_about_text}>Founder Chairman & CEO - AquaKraft Group Ventures.</Typography>
                                        <Typography className={classes.advisory_about_text}>Member of Governing Council - UN Global Compact Network.</Typography>
                                        <Typography className={classes.advisory_about_text}>Co-Chairman, US-India SME Council.</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.sub_container_grid}>
                            <Box className={classes.sub_box}>
                                <img className={classes.adv_img} src={advimg2}  alt={noimg}></img>
                                <Grid className={window.innerWidth > 1119 ? classes.adv_sub_grid : null}>
                                <Typography className={classes.advisory_name}>Rakesh Bohra</Typography>
                                    <Typography className={classes.advisory_member}>Director on Board & Advisor</Typography>
                                    <Grid className={classes.advisory_about}>
                                        {/* Dev-trupti on 02-12-2024 */}
                                    <Typography  className={classes.advisory_about_text_wrap}>Chairperson - Trinity Group of companies, Dubai.</Typography>
                                        <Typography  className={classes.advisory_about_text_wrap}>Serial Entrepreneur & Startup Ecosystem Mentor.</Typography>
                                        <Typography className={classes.advisory_about_text}>Ex Wipro and Jumbo Electronics, Taiwan</Typography>
                                    </Grid>
                               </Grid>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.sub_container_grid}>
                            <Box className={classes.sub_box}>
                                <img className={classes.adv_img} src={advimg3}  alt={noimg}></img>
                                <Grid className={window.innerWidth > 1119 ? classes.adv_sub_grid : null}>
                                    <Typography className={classes.advisory_name}>H Prakash Nayak</Typography>
                                    <Typography className={classes.advisory_member}>Member of Advisory Board</Typography>
                                    <Grid className={classes.advisory_about}>
                                    <Typography className={classes.advisory_about_text}>Expert in Agency Management along with Wealth & Asset Creation</Typography>
                                        <Typography className={classes.advisory_about_text}>Former Senior Business Associate at LIC of India</Typography>
                                        <Typography className={classes.advisory_about_text}>Business Associate at TATA-AIA Life Insurance</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                </Grid>
                    {/* Updated Advisory code end by dev-trupti on 20-01-2025 */}
                    {/* Executive Leadership start by dev-trupti on 27-12-2024 */}
                <Grid className={classes.executive_grid}>
                <Typography className={classes.executive_title}>Executive Leadership</Typography>
                <Box className={classes.executive_main_grid} >
                    <Grid container className={classes.executive_container_grid}>
                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.sub_container_grid}>
                            <Box className={classes.executive_sub_box}>
                                <img className={classes.adv_img} src={executiveimg1}  alt={noimg}></img>
                                <Typography className={classes.executive_name}>Subrahmanya Basavanahalli</Typography>
                                <Typography className={classes.executive_member}>Founding Member & CTO</Typography>
                            </Box>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.sub_container_grid}>
                            <Box className={classes.executive_sub_box}>
                                <img className={classes.adv_img} src={executiveimg2}  alt={noimg}></img>
                                <Typography className={classes.executive_name}>Veenadhar Shetty</Typography>
                                <Typography className={classes.executive_member}>Associate Director & Head of Partnerships</Typography>
                            </Box>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.sub_container_grid}>
                            <Box className={classes.executive_sub_box}>
                                <img className={classes.adv_img} src={executiveimg3}  alt={noimg}></img>
                                <Typography className={classes.executive_name}>Vignesh Reddy</Typography>
                                <Typography className={classes.executive_member}>Global Head of Sales & ALGO Trading Program</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                </Grid>
                {/* Executive Leadership end by dev-trupti on 27-12-2024 */}
                <Grid style={{ width: '100%', background: '#ffffff', padding: '108px 0px 108px 0px', display: 'none' }}>
                    <Typography className={classes.founder_tit}>Executive Leadership</Typography>

                    <Grid style={{ width: '100%', background: '#ffffff', display: 'flex', justifyContent: 'center' }}>
                        <Grid style={{ width: '95%' }}>
                            <Grid style={{ padding: '50px 32px 108px 32px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '22%' }}>
                                    <img src={subbu} alt='subbu' style={{ width: '95%' }}></img>
                                    <Typography className={classes.ex_subtit}>Manoj Bajpayee</Typography>
                                    <Typography className={classes.ex_subtit1}>Bengaluru</Typography>
                                </Grid>
                                <Grid style={{ width: '22%' }}>
                                    <img src={subbu} alt='subbu' style={{ width: '95%' }}></img>
                                    <Typography className={classes.ex_subtit}>Manoj Bajpayee</Typography>
                                    <Typography className={classes.ex_subtit1}>Bengaluru</Typography>

                                </Grid>
                                <Grid style={{ width: '22%' }}>
                                    <img src={subbu} alt='subbu' style={{ width: '95%' }}></img>
                                    <Typography className={classes.ex_subtit}>Manoj Bajpayee</Typography>
                                    <Typography className={classes.ex_subtit1}>Bengaluru</Typography>

                                </Grid>
                                <Grid style={{ width: '22%' }}>

                                    <img src={subbu} alt='subbu' style={{ width: '95%' }}></img>

                                    <Typography className={classes.ex_subtit}>Manoj Bajpayee</Typography>
                                    <Typography className={classes.ex_subtit1}>Bengaluru</Typography>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ width: '100%', background: '#0B1420', padding: '108px 0px 108px 0px', display: 'none', justifyContent: 'center' }}>
                    <Grid style={{ width: '90%', display: 'flex', justifyContent: 'space-evenly' }}>
                        <Grid style={{ width: '30%' }}>,
                            <Typography className={classes.Auditors_tit}>Auditors</Typography>
                            <Typography className={classes.Auditors_subtit}>Jalan Sandeep & Co.</Typography>
                            <Typography className={classes.Auditors_subtit1} style={{ paddingTop: '8px' }}>Charted Accountants</Typography>
                            <Typography className={classes.Auditors_subtit1} >Bengaluru</Typography>

                        </Grid>
                        <Grid style={{ width: '30%' }}>,
                            <Typography className={classes.compsec_tit}>Company Secretaries</Typography>
                            <Typography className={classes.Auditors_subtit}>Jalan Sandeep & Co.</Typography>
                            <Typography className={classes.Auditors_subtit1} style={{ paddingTop: '8px' }}>Sr, Secretaries</Typography>
                            <Typography className={classes.Auditors_subtit1} >Bengaluru</Typography>

                        </Grid>

                        <Grid style={{ width: '30%' }}>,
                            <Typography className={classes.Legal_tit}>Legal Advisory</Typography>
                            <Typography className={classes.Auditors_subtit}>Jalan Sandeep & Co.</Typography>
                            <Typography className={classes.Auditors_subtit1} style={{ paddingTop: '8px' }}>Sr, Secretaries</Typography>
                            <Typography className={classes.Auditors_subtit1} >Bengaluru</Typography>


                        </Grid>

                    </Grid>
                </Grid>
                <Grid className={classes.bank_main}>
<div style={{display:'flex',justifyContent:'center'}}>
                    <h1 className={classes.founder_tit}>Banking Partners</h1>
                    </div>

                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Grid  className={classes.bankGrid1} >
                            <div>
                                <h3 className={classes.bankname}>Axis Bank</h3>
                                <img src={axisbank} className={classes.bank_img1} alt='bank'></img>
                            </div>
                        </Grid>
                        <Grid className={classes.border} style={{ width: '2%', borderRight: '1px solid #DFE0EB', display: 'flex', justifyContent: 'flex-end' }}>

                        </Grid>
                        <Grid className={classes.bankGrid2} >
                            <h3 className={classes.bankname}>HDFC Bank</h3>
                            {/* <div style={{ marginTop: '-5px', height: 'auto' }}> */}
                                <img src={hdfcbank} className={classes.bank_img2} alt='bank'></img>
                            {/* </div> */}
                        </Grid>

                    </Grid>
                </Grid>
                <Fotter />
            </div >
        )
    }
}
export default (withStyles(styles)((aboutus)));