
import React from 'react'
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material';


const BestOnlineTradingplatform = ({ blog }) => {
  return (
      <>
          {blog.content?.WhatMakesBullforcePerfectforBeginners && (
              <Grid item xs={12}>
                  <h1 className='wblgtit' mb='25px'>What Makes Bullforce Perfect for Beginners ? </h1>
                  <h2 className='wblgintr' mb='25px'>{blog.content.WhatMakesBullforcePerfectforBeginners}</h2>
              </Grid>
          )}

          {blog.content?.Theplatformofferssimplified && (
              <Grid item xs={12}>
                  <h2 className='wblgintr' mb='25px'>{blog.content.Theplatformofferssimplified}</h2>
              </Grid>
          )}

          {blog.content?.InitialPublicOfferings && (
              <Grid item xs={12}>
                  <h2 className='wblgintr' mb='25px'>{blog.content.InitialPublicOfferings}</h2>
              </Grid>
          )}

          {blog.content?.HowBullforceSIPCalculator && (
              <Grid item xs={12}>
                  <h1 className='wblgtit' mb='25px'>How Bullforce’s SIP Calculator Helps You Plan Smarter Investments </h1>
                  <h2 className='wblgintr' mb='25px'>{blog.content.HowBullforceSIPCalculator}</h2>
              </Grid>
          )}

          {blog.content?.HowBullforceSIPCalculator && (
              <Grid item xs={12}>
                  <h2 className='wblgintr' mb='10px'>With Bullforce's SIP calculator, you can:</h2>
              </Grid>
          )}

          {blog.content?.steps && blog.content.steps.length > 0 && (
              <Grid item xs={12} mb="15px">
                  <List>
                      {blog.content.steps.map((feature, index) => (
                          <ListItem key={index} sx={{ paddingLeft: 0, marginLeft: 0, display: 'flex', alignItems: 'flex-start' }}>
                              <ListItemIcon sx={{ minWidth: '20px', paddingLeft: 0, marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                                  <FiberManualRecord fontSize="inherit" style={{ color: '#000', fontSize: '10px' }} />
                              </ListItemIcon>
                              <ListItemText
                                  primary={feature}
                                  classes={{ primary: 'wblgintr' }}
                                  sx={{
                                      paddingLeft: 0,
                                      display: 'inline-block',
                                      whiteSpace: 'normal',
                                      marginTop: -1
                                  }}
                              />
                          </ListItem>
                      ))}
                  </List>
              </Grid>
          )}

          {blog.content?.calculators && (
              <Grid item xs={12}>
                  <h2
                      className="wblgintr"
                      mb="25px"
                      dangerouslySetInnerHTML={{
                          __html: blog.content.calculators
                              .replace("Lumpsum Calculator", `<a href="https://bullforce.co/lumpsum-calculator" target="_blank" rel="noopener noreferrer">Lumpsum Calculator</a>`)
                              .replace("Step up SIP Calculator", `<a href="https://bullforce.co/stepup-sip-calculator" target="_blank" rel="noopener noreferrer">Step up SIP Calculator</a>`)
                              .replace("Online Brokerage Calculator", `<a href="https://bullforce.co/brokerage" target="_blank" rel="noopener noreferrer">Online Brokerage Calculator</a>`),
                      }}
                  />
              </Grid>
          )}


          {blog.content?.demataccount && (
              <Grid item xs={12}>
                  <h1 className='wblgtit' mb='25px'>Open a Demat Account with Bullforce: Simple and Secure</h1>
                  <h2 className='wblgintr' mb='25px'>{blog.content.demataccount}</h2>
              </Grid>
          )}

          {blog.content?.HowBullforceSIPCalculator && (
              <Grid item xs={12}>
                  <h2 className='wblgintr' mb='25px'>To open your Bullforce trading account, all you need to do is:</h2>
              </Grid>
          )}

          {blog.content?.tradingSteps && blog.content.tradingSteps.length > 0 && (
              <Grid item xs={12} mb="25px" ml='25px' >
                  {blog.content.tradingSteps.map((step, index) => {
                      const formattedStep = step
                          .replace(
                              'Visit the',
                              '<strong>Visit the</strong>'
                          )
                          .replace(
                              'Bullforce',
                              '<a href="https://bullforce.co/" target="_blank" rel="noopener noreferrer">Bullforce</a>'
                          )
                          .replace(
                              'Website',
                              '<strong>Website</strong>'
                          )
                          .replace(
                              'Register',
                              '<a href="https://bullforce.co/signup" target="_blank" rel="noopener noreferrer">Register</a>'
                          )
                          .replace(
                              'Fill in your basic details,',
                              '<strong>Fill in your basic details,</strong>'
                          )
                          .replace(
                              'Complete the KYC verification,',
                              '<strong>Complete the KYC verification,</strong>'
                          )
                          .replace(
                              'Link your bank account',
                              '<strong>Link your bank account</strong>'
                          );

                      return (
                          <h2
                              key={index}
                              variant="body1"
                              className="wblgintr"
                              style={{ marginBottom: '15px' }}
                              dangerouslySetInnerHTML={{
                                  __html: `${index + 1}. ${formattedStep}`,
                              }}
                          />
                      );
                  })}
              </Grid>
          )}

          {blog.content?.BullforceOffers && (
              <Grid item xs={12}>
                  <h2
                      className="wblgintr"
                      mb="25px"
                      dangerouslySetInnerHTML={{
                          __html: blog.content.BullforceOffers
                              .replace(
                                  'multi-exchange trading',
                                  '<strong>multi-exchange trading</strong>'
                              )
                              .replace(
                                  'user-friendly interface',
                                  '<strong>user-friendly interface</strong>'
                              ),
                      }}
                  />
              </Grid>
          )}

          {blog.content?.bullforcesTrading && (
              <Grid item xs={12}>
                  <h1 className='wblgtit' mb='25px'>The Best Online Trading Platform in India</h1>
                  <h2 className='wblgintr' mb='25px'>{blog.content.bullforcesTrading}</h2>
              </Grid>
          )}

          {blog.content?.bullforcesTrading && (
              <Grid item xs={12}>
                  <h2 className='wblgintr' mb='10px'>Some of the standout features include:</h2>
              </Grid>
          )}

          {blog.content?.standoutSteps && blog.content.standoutSteps.length > 0 && (
              <Grid item xs={12} mb='15px'>
                  <List>
                      {blog.content.standoutSteps.map((feature, index) => (
                          <ListItem key={index} sx={{ paddingLeft: 0, marginLeft: 0, display: 'flex', alignItems: 'flex-start' }}>
                              <ListItemIcon sx={{ minWidth: '20px', paddingLeft: 0, marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                                  <FiberManualRecord fontSize="inherit" style={{ color: '#000', fontSize: '10px' }} />
                              </ListItemIcon>
                              <ListItemText
                                  primary={
                                      index === 0 ? (
                                          <span><b>Multi-exchange trading</b>, allowing you to trade across various exchanges effortlessly and diversify your portfolio.</span>
                                      ) : index === 1 ? (
                                          <span><b>Real-time insights</b> with live stock market online charts and performance updates to help you stay informed and make better decisions.</span>
                                      ) : index === 2 ? (
                                          <span><b>Educational resources</b>, including free training materials from the Bullforce Academy, helping you become a more knowledgeable trader.</span>
                                      ) : index === 3 ? (
                                          <span><a href="https://www.bullforce.com/advice" target="_blank" rel="noopener noreferrer">Expert advice</a>, Bullforce offers personalized investment guidance from experienced professionals, helping beginners make informed decisions and trade with confidence.</span>
                                      ) : (
                                          feature
                                      )
                                  }
                                  classes={{ primary: 'wblgintr' }}
                                  sx={{
                                    paddingLeft: 0,
                                    display: 'inline-block',
                                    whiteSpace: 'normal',
                                    marginTop: -1
                                }}
                              />
                          </ListItem>
                      ))}
                  </List>
              </Grid>
          )}

          {blog.content?.bullforcesTrading && (
              <Grid item xs={12}>
                  <h2 className='wblgintr' mb='25px'>With these tools at your fingertips, you'll be empowered to take control of your financial future and invest confidently.</h2>
              </Grid>
          )}

          {blog.content?.mutualFunds && (
              <Grid item xs={12}>
                  <h1 className='wblgtit' mb='25px'>Invest in Mutual Funds with Bullforce: A Smarter Way to Grow Wealth</h1>
                  <h2 className='wblgintr' mb='25px'>{blog.content.mutualFunds}</h2>
              </Grid>
          )}

          {blog.content?.mutualFundsSip && (
              <Grid item xs={12}>
                  <h2 className='wblgintr' mb='25px'>
                      {blog.content.mutualFundsSip.split("SIP calculator").map((part, index) => (
                          <>
                              {part}
                              {index < blog.content.mutualFundsSip.split("SIP calculator").length - 1 && (
                                  <a
                                      href="https://bullforce.co/" target="_blank" rel="noopener noreferrer"
                                  >
                                      SIP calculator
                                  </a>
                              )}
                          </>
                      ))}
                  </h2>
              </Grid>
          )}

          {blog.content?.BullforcesEducation && (
              <Grid item xs={12}>
                  <h1 className="wblgtit" mb="25px">
                      Bullforce’s Commitment to Education and Community Building
                  </h1>
                  <h2 className="wblgintr" mb="25px">
                      {blog.content.BullforcesEducation.split(/(Bullforce Academy|Learning Centre)/).map((part, index) =>
                          part === "Bullforce Academy" || part === "Learning Centre" ? (
                              <b key={index}>{part}</b>
                          ) : (
                              part
                          )
                      )}
                  </h2>
              </Grid>
          )}

          {blog.content?.beginnersSteps && blog.content.beginnersSteps.length > 0 && (
              <Grid item xs={12} mb='15px'>
                  <List>
                      {blog.content.beginnersSteps.map((feature, index) => {
                          let boldText = '';
                          if (index === 0) {
                              boldText = 'Beginner’s Program';
                          } else if (index === 1) {
                              boldText = 'Advanced Program';
                          } else if (index === 2) {
                              boldText = 'Mentorship Program';
                          }

                          return (
                              <ListItem key={index} sx={{ paddingLeft: 0, marginLeft: 0, display: 'flex', alignItems: 'flex-start' }}>
                                  <ListItemIcon sx={{ minWidth: '20px', paddingLeft: 0, marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                                      <FiberManualRecord fontSize="inherit" style={{ color: '#000', fontSize: '10px' }} />
                                  </ListItemIcon>
                                  <ListItemText
                                      primary={
                                          feature.split(boldText).map((part, i) => (
                                              <span key={i}>
                                                  {i > 0 && <strong>{boldText}</strong>}
                                                  {part}
                                              </span>
                                          ))
                                      }
                                      classes={{ primary: 'wblgintr' }}
                                      sx={{
                                        paddingLeft: 0,
                                        display: 'inline-block',
                                        whiteSpace: 'normal',
                                        marginTop: -1
                                    }}
                                  />
                              </ListItem>
                          );
                      })}
                  </List>
              </Grid>
          )}

            {blog.content?.bullforcesTrading && (
                <Grid item xs={12}>
                    <h2 className='wblgintr' mb='25px'>In addition, Bullforce fosters a <strong>social investor network</strong>, where you can connect with other traders and learn from their experiences.</h2>
                </Grid>
            )}

          {blog.content?.bullforceDiplomats && (
              <Grid item xs={12}>
                  <h2 className='wblgintr' mb='25px'>
                      {blog.content.bullforceDiplomats.split("Bullforce Diplomats").map((part, index) => (
                          <>
                              {part}
                              {index < blog.content.bullforceDiplomats.split("Bullforce Diplomats").length - 1 && (
                                  <a
                                      href="https://bullforce.co/diplomats" target="_blank" rel="noopener noreferrer"
                                  >
                                      Bullforce Diplomats
                                  </a>
                              )}
                          </>
                      ))}
                  </h2>
              </Grid>
          )}


          {blog.content?.bullforceDiplomats && (
              <Grid item xs={12}>
                  <h1 className='wblgtit' mb='25px'>Why Bullforce is the Best Choice for Beginners</h1>
              </Grid>
          )}

            {blog.content?.bullforceDiplomats && (
                <Grid item xs={12}>
                    <h2 className='wblgintr' mb='10px'>Bullforce stands out for several reasons, especially for beginners: </h2>
                </Grid>
            )}

          {blog.content?.comprehensiveSteps && blog.content.comprehensiveSteps.length > 0 && (
              <Grid item xs={12} mb='15px'>
                  <List>
                      {blog.content.comprehensiveSteps.map((feature, index) => {
                          let boldText = '';
                          if (index === 0) {
                              boldText = 'Comprehensive tools:';
                          } else if (index === 1) {
                              boldText = 'Educational support:';
                          } else if (index === 2) {
                              boldText = 'Trusted brand:';
                          }

                          return (
                              <ListItem key={index} sx={{ paddingLeft: 0, marginLeft: 0, display: 'flex', alignItems: 'flex-start' }}>
                                  <ListItemIcon sx={{ minWidth: '20px', paddingLeft: 0, marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                                      <FiberManualRecord fontSize="inherit" style={{ color: '#000', fontSize: '10px' }} />
                                  </ListItemIcon>
                                  <ListItemText
                                      primary={
                                          feature.split(boldText).map((part, i) => (
                                              <span key={i}>
                                                  {i > 0 && <strong>{boldText}</strong>}
                                                  {part}
                                              </span>
                                          ))
                                      }
                                      classes={{ primary: 'wblgintr' }}
                                      sx={{
                                          paddingLeft: 0,
                                          display: 'inline-block',
                                          whiteSpace: 'normal',
                                          marginTop: -1
                                      }}
                                  />
                              </ListItem>
                          );
                      })}
                  </List>
              </Grid>
          )}

          {blog.content?.readyToStart && (
              <Grid item xs={12}>
                  <h1 className='wblgtit' mb='25px'>Ready to Start Your Trading Journey with Bullforce?</h1>
              </Grid>
          )}

          {blog.content?.readyToStart && (
              <Grid item xs={12}>
                  <h2 className='wblgintr' mb='25px'>
                      {blog.content.readyToStart.split("Register Now").map((part, index) => (
                          <>
                              {part}
                              {index < blog.content.readyToStart.split("Register Now").length - 1 && (
                                  <a
                                      href="https://bullforce.co/signup" target="_blank" rel="noopener noreferrer"
                                  >
                                      Register Now
                                  </a>
                              )}
                          </>
                      ))}
                  </h2>
              </Grid>
          )}

        </>
    )
}

export default BestOnlineTradingplatform