
import { useState, useEffect } from "react";
import { TextField, IconButton, Popover, Button, Modal, InputLabel, ListItemText, Select, Box, Typography, MenuItem, Checkbox, Grid, FormControl, InputAdornment, FormHelperText, } from "@mui/material";
import frame from '../../../ui-assets/Frame.png'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from "dayjs";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "./css/MobileModView.css"
import { httpRequest } from '../../../ui-utils/api'

export default function MobileModal({ setShowModal, setShower, setMySnack, snackeror, setError, defaultBasicCheckBox, defaultCompleteCheckBox, defaultProfessionalCheckBox, setDefaultBasicCheckBox, setDefaultProfessionalCheckBox, setDefaultCompleteCheckBox }) {



  const options = ["Basic", "Professional", "Complete"]
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [language, setLanguage] = useState("");
  const [slot, setSlot] = useState("");
  const [date, setDate] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [errorMsg, setErrorMsg] = useState('');
  const [errType, setErrType] = useState(0)
  const [option, setOption] = useState(options)
  const [program, setProgram] = useState([])
  const [isHoveredHindi, setIsHoveredHindi] = useState(false)
  const [isHoveredEnglish, setIsHoveredEnglish] = useState(false)
  const [isHoveredslot1, setIsHoveredslot1] = useState(false)
  const [isHoveredslot2, setIsHoveredslot2] = useState(false)
  const [datemodel, setDatemodel] = useState(false)
  const [dialogInfo, setDialogInfo] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);



  useEffect(() => {

    let updateOptions = []

    if (defaultBasicCheckBox) {
      updateOptions.push("Basic")
    }
    if (defaultProfessionalCheckBox) {
      updateOptions.push("Professional")
    }
    if (defaultCompleteCheckBox) {
      updateOptions.push("Complete")
    }

    setProgram(updateOptions)



  }, [defaultBasicCheckBox, defaultProfessionalCheckBox, defaultCompleteCheckBox])

  const basics = [
    'Introduction',
    'Swing Trading v/s Day Trading',
    'How part-time trading works',
    'Finding the best stock to Swing Trade',
    'Stock screening criteria',
    'Analyzing your stocks',
    'Stock analysis websites',
    'When to enter your position',
    'When to exit your position',
    'Trading platform overview and features'
  ]
  const professional = [
    'Trading strategies based on technical indicators',
    'Trading strategies based on patterns',
    'Trading with special patterns',
    'Trading strategies based on Elliot Wave',
    'Fibonacci trend line trading strategy',
    'Trading strategies based on Options strategies',
    'Trading strategy based on | Support resistance | Harmonic patterns | Gan fan concepts |  Candlestick pattern',
    'Trading strategy based on commodity',
    'Pivot points, Fibonacci, Trend line and time frame used by trader',
    'Trading strategies based on Forex',
    'Stock selection and entry exit criteria for intraday trading',
    'Trading phycology and qualities of good trader',
    'Stock selection and entry exit criteria for intraday trading',
    'Trading phycology and qualities of good trader'
  ]
  const complete = [
    'Course Introduction',
    'Options and the Stock Volatility, the VIX and more',
    'The basics of how Options work (Tickers, Call, Put and more)',
    'Pricing a Long or Short Call or Put',
    'Valuation and Analysis of Options',
    'Statistics and Greek formulas to analyze options',
    'How to value options using Binomial pricing',
    'How to value Options using Black Scholes',
    'How to value Options using Monte Carlo simulation',
    'Option Strategies',
    'Creating single option strategies',
    'Creating 1 Stock PLUS Single option strategies',
    'Creating single option strategies',
    'Creating 1 Stock PLUS Single option strategies'
  ]





  const cancelReset = () => {
    setName('')
    setEmail('')
    setPhone('')
    setDate(null)
    setInputValue("")
    setLanguage('')
    setSlot('')
    setDefaultBasicCheckBox(true)
    setDefaultProfessionalCheckBox(true)
    setDefaultCompleteCheckBox(true)
    setShowModal(false)


  }




  const openDateModal = async () => {
    setDatemodel(true)

  }





  const handleBasicPlan = (state) => {
    if (state === "Basic") {
      setDefaultBasicCheckBox(!defaultBasicCheckBox)
      console.log(defaultBasicCheckBox)
    }

  }

  const handleProfessionalPlan = (state) => {
    if (state === "Professional") {
      setDefaultProfessionalCheckBox(!defaultProfessionalCheckBox)
    }
  }

  const handleCompletePlan = (state) => {
    if (state === 'Complete') {
      setDefaultCompleteCheckBox(!defaultCompleteCheckBox)
    }
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value === "") {

      setDate(null);
      setErrType(null);
      setErrorMsg("");
    } else {

      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(value)) {
        setErrType(5);
        setErrorMsg("Invalid date format. Please use YYYY-MM-DD.");
        return;
      }
      const parsedDate = dayjs(value, "YYYY-MM-DD", true);
      if (parsedDate.isValid()) {
        if (parsedDate.isBefore(dayjs().startOf("day"))) {
          setErrType(5);
          setErrorMsg("You cannot select a date before today.");
        } else {
          setDate(parsedDate);
          setErrType(null);
          setErrorMsg("");
        }
      } else {
        setErrType(5);
        setErrorMsg("Invalid date format. Please use YYYY-MM-DD.");
      }
    }
  };

  const handleDateChange = (newDate) => {
    const today = dayjs().startOf("day");
    if (newDate.isBefore(today)) {
      setErrType(5);
      setErrorMsg("You cannot select a date before today.");
    } else {
      setDate(newDate);
      setInputValue(newDate.format("YYYY-MM-DD"));
      setErrType(null);

      setErrorMsg("");
      setDatemodel(false)
    }
  };



  const handlePopoverOpen = (event, selectedOption) => {
    console.log('open')
    setAnchorEl(event.currentTarget)


    if (selectedOption === "Basic") {
      setDialogInfo(basics)
    } else if (selectedOption === "Professional") {
      setDialogInfo(professional)
    } else if (selectedOption === "Complete") {
      setDialogInfo(complete)
    }
  }

  const handleChange = (opt) => {
    if (opt === "Basic") {
      handleBasicPlan(opt)
    } else if (opt === "Professional") {
      handleProfessionalPlan(opt);
    } else if (opt === "Complete") {
      handleCompletePlan(opt);
    }
  };

  const getCheckedState = (opt) => {
    if (opt === "Basic") return defaultBasicCheckBox;
    if (opt === "Professional") return defaultProfessionalCheckBox;
    if (opt === "Complete") return defaultCompleteCheckBox;
    return false;
  };


  const handlePopoverClose = () => {
    setAnchorEl(null)
    setDialogInfo([])
  };

  const open = Boolean(anchorEl)


  const toggleLanguage = (lang) => {
    setLanguage((prev) => (prev === lang ? null : lang))
    setErrType(0)
  };


  const toggleSlot = (selectedSlot) => {
    setSlot((prev) => (prev === selectedSlot ? null : selectedSlot))
    setErrType(0)
  };





  const validateName = (value) => {
    if (value.trim() === "") {
      setErrType(1);
      setErrorMsg("Name is required.");
      return false;
    }
    else if (!/^[a-zA-Z\s]+$/.test(value)) {
      setErrType(1);
      setErrorMsg("Name must contain only letters and spaces.");
      return false;
    }
    else {

      setErrType(0);
      setErrorMsg("");
      return true;
    }
  };

  const validateEmail = (value) => {
    if (value.trim() === "") {
      setErrType(2);
      setErrorMsg("Email is required.");
      return false;
    }
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setErrType(2);
      setErrorMsg("Please enter a valid email address.");
      return false;
    } else if (!/[a-zA-Z]/.test(value.split('@')[0])) {
      setErrType(2);
      setErrorMsg("Email must contain  alphabetic character.");
      return false;
    }
    else {
      setErrType(0);
      setErrorMsg("");
      return true;
    }
  };

  const validatePhone = (value) => {
    if (value.trim() === "") {
      setErrType(3);
      setErrorMsg("Phone number is required.");
      return false;
    }
    else if (!/^\d{10}$/.test(value)) {
      setErrType(3);
      setErrorMsg("Phone number must be exactly 10 digits.");
      return false;
    }
    else if (/^(\d)\1{9}$/.test(value)) {
      setErrType(3);
      setErrorMsg("Phone number cannot consist of the same digit repeated.");
      return false;
    }
    else {

      setErrType(0);
      setErrorMsg("");
      return true;
    }

  };

  const validateDate = (date) => {
    if (!date) {
      setErrType(5);
      setErrorMsg("Date is required.");
      return false;
    } else {

      setErrType(0);
      setErrorMsg("");
      return true;
    }
  };

  const validateOptions = (options) => {
    if (options.length === 0) {
      setErrType(4);
      setErrorMsg("Please select at least one program.");
      return false;
    } else {
      setErrType(0);
      setErrorMsg("");
      return true;
    }
  };




  const validateLanguage = () => {
    if (!language) {
      setErrType(6);
      setErrorMsg("Please select a language.");
      return false;
    } else {
      setErrType(0);
      setErrorMsg("");
      return true;
    }
  };

  const validateSlot = () => {
    if (!slot) {
      setErrType(7);
      setErrorMsg("Please select a slot.");
      return false;
    } else {
      setErrType(0);
      setErrorMsg("");
      return true;
    }
  };






  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!validateName(name)) return;
    if (!validateEmail(email)) return;
    if (!validatePhone(phone)) return;
    if (!validateDate(date)) return;
    if (!validateLanguage(language)) return;
    if (!validateSlot(slot)) return;



    let payload = {
      name: name,
      email: email,
      phone: phone,
      tdate: inputValue,
      lang: language,
      slots: slot,
      coursename: program
    }

    console.log(payload)

    try {
      console.log(payload)

      const response = await httpRequest({
        endPoint: '/api/training/submit',
        method: 'post',
        instance: "instanceOne",
        requestBody: payload
      })
      console.log(response)
      if (response.status === 200) {
        setShower(true)
        setError('Your request has been submitted for review.')
        setMySnack(snackeror.mysucc)

        console.log("success")

        setDefaultBasicCheckBox(true)
        setDefaultProfessionalCheckBox(true)
        setDefaultCompleteCheckBox(true)
        setName("");
        setEmail("");
        setPhone("");
        setInputValue("");
        setSlot("");
        setLanguage("");
        setDate(null);
        setShowModal(false)
      } else {

        setShower(true)
        console.log(response)
        setError(response.data.message)
        console.log(response)
        setMySnack(snackeror.myerror)

      }

    } catch (error) {
      setErrType(0)
      console.log(error)
      setError("Oops, something went wrong")
      setMySnack(snackeror.myerror)
    }


  };

  return (
    <>

      <Modal open="true">

        <Box
          className="mobmodalboxmodal"
        >

          <Grid className="mobmodalconnect_sub_card1">

            <Typography className="mobmodalselecttitle" >
              Bullforce Academy & Learning Centre
            </Typography>



            <FormControl style={{ width: "100%", marginTop: "5px" }} variant="standard">
              <TextField
                size="small"
                variant="standard"
                className="autofill_fix"
                label="Name"
                fullWidth
                value={name}
                sx={{
                  "& .MuiInput-underline:before": { borderBottomColor: "#92A4C1" },
                  "& .MuiInput-underline:hover:before": { borderBottomColor: "#BCD171" },
                  "& .MuiInput-underline:after": { borderBottomColor: "#BCD171" },
                }}
                InputLabelProps={{
                  style: { color: errType === 1 ? "#000" : "#333", fontSize: "12px", fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important' },
                }}
                InputProps={{
                  style: { color: "#0B1420", fontSize: "18px", height: "30px", fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important' },
                }}
                onChange={(e) => {
                  setName(e.target.value)
                  setErrType(0)
                }}
                onBlur={() => validateName(name)}

              />
              {errType === 1 && <FormHelperText className="mobmodalerrtxt">{errorMsg}</FormHelperText>}
            </FormControl>

            <FormControl style={{ width: "100%", marginTop: "15px" }} variant="standard">
              <TextField
                size="small"
                variant="standard"
                className="autofill_fix"
                label="Email"
                fullWidth
                value={email}
                sx={{
                  "& .MuiInput-underline:before": { borderBottomColor: "#92A4C1" },
                  "& .MuiInput-underline:hover:before": { borderBottomColor: "#BCD171" },
                  "& .MuiInput-underline:after": { borderBottomColor: "#BCD171" },
                }}
                InputLabelProps={{
                  style: { color: errType === 2 ? "#000" : "#333", fontSize: "12px", fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important' },
                }}
                InputProps={{
                  style: { color: "#0B1420", fontSize: "18px", height: "30px", fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important' },
                }}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setErrType(0)
                }}
                onBlur={() => validateEmail(email)}
              />
              {errType === 2 && <FormHelperText className="mobmodalerrtxt">{errorMsg}</FormHelperText>}
            </FormControl>




            <FormControl style={{ width: "100%", marginTop: "15px" }} variant="standard">
              <TextField
                size="small"
                variant="standard"
                className="autofill_fix"
                label="Phone"
                fullWidth
                value={phone}
                sx={{
                  "& .MuiInput-underline:before": { borderBottomColor: "#92A4C1" },
                  "& .MuiInput-underline:hover:before": { borderBottomColor: "#BCD171" },
                  "& .MuiInput-underline:after": { borderBottomColor: "#BCD171" },
                }}
                InputLabelProps={{
                  style: { color: errType === 3 ? "#000" : "#333", fontSize: "16px", fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important' }
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <Typography style={{ color: "black", fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important', fontSize: '16px' }}>
                      +91
                    </Typography>
                  </InputAdornment>,

                  style: { color: "#0B1420", fontSize: "18px", height: "30px" },
                }}
                onChange={(e) => {
                  setPhone(e.target.value)
                  setErrType(0)
                }}
                onBlur={() => validatePhone(phone)}
              />
              {errType === 3 && <FormHelperText className="mobmodalerrtxt">{errorMsg}</FormHelperText>}
            </FormControl>



            <FormControl variant="standard" fullWidth sx={{ marginTop: "10px" }}>
              <InputLabel id="multi-select-label" style={{ color: "#000", fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important' }}>
                Selected Programs for you
              </InputLabel>
              <Select
                labelId="multi-select-label"
                id="multi-select"
                multiple
                value={option}
                onChange={(e) => setOption(e.target.value)}
                onBlur={() => validateOptions(option)}
                renderValue={(selected) => selected.join(", ")}
                sx={{
                  "& .MuiInput-underline:before": { borderBottomColor: "#92A4C1" },
                  "& .MuiInput-underline:hover:before": { borderBottomColor: "#BCD171" },
                  "& .MuiInput-underline:after": { borderBottomColor: "#BCD171" },
                }}
              >
                {options.map((opt) => (
                  <MenuItem
                    key={opt}
                    value={opt}
                    sx={{
                      fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important',
                      fontSize: "14px",
                      padding: "5px 10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChange(opt)
                    }}
                  >

                    <Checkbox
                      checked={getCheckedState(opt)}
                      onChange={(e) => e.stopPropagation()}
                      sx={{
                        padding: "0 8px",
                        transform: "scale(0.8)",
                        color: "#0B1420",
                        "&.Mui-checked": {
                          color: "#0B1420",
                        },
                      }}
                    />
                    <ListItemText
                      primary={opt}
                      primaryTypographyProps={{
                        fontFamily: "Cardiuma-Regular, Arial, sans-serif !important",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                      sx={{
                        marginLeft: "8px",
                      }}
                    />
                    <IconButton

                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"

                      onMouseEnter={(e) => handlePopoverOpen(e, opt)}
                      onMouseLeave={handlePopoverClose}
                      sx={{
                        fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important',
                        marginLeft: "10px",
                        color: "#6C757D",
                        fontSize: "18px",
                      }}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </MenuItem>
                ))}
              </Select>
              {errType === 4 && <FormHelperText className="mobmodalerrtxt">{errorMsg}
              </FormHelperText>}



              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                  '& .MuiPaper-root': {
                    backgroundColor: '#f9f9f9',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    padding: '10px',
                  },
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography sx={{
                  fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important',
                  fontSize: '16px',
                  color: '#333',
                  padding: '10px',
                  lineHeight: '1.5',
                }}>

                  {dialogInfo.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        fontFamily: 'Cardiuma-Regular, Arial, sans-serif !important',
                        marginBottom: '5px',
                        position: 'relative',
                        paddingLeft: '15px',
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '0',
                        }}
                      >
                        ‣
                      </span>
                      {item}
                    </div>
                  ))}
                </Typography>
              </Popover>

            </FormControl>


            <FormControl style={{ width: "100%", marginTop: "15px" }} variant="standard">
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  label=" Select your training date"
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={() => { validateDate(date) }}

                  sx={{
                    "& .MuiInput-underline:before": { borderBottomColor: "#92A4C1" },
                    "& .MuiInput-underline:hover:before": { borderBottomColor: "#BCD171" },
                    "& .MuiInput-underline:after": { borderBottomColor: "#BCD171" },
                  }}

                  InputLabelProps={{
                    shrink: true,
                    style: {

                      color: errType === 5 ? "#000" : "#333",
                      marginLeft: '6px',
                      fontFamily: 'cardiuma-M !important',
                      fontSize: '17px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',

                    }

                  }}

                  InputProps={{


                    style: {

                      flexShrink: 0,
                      color: '#0B1420 ! important',
                      fontFamily: 'cardiuma-regular !important',
                      fontSize: '16px',
                      marginLeft: '5px',
                      lineHeight: 'normal ',
                      fontStyle: 'normal',
                      fontWeight: '400',
                    },

                  }}

                ></TextField>

                <img
                  src={frame}
                  alt="Calendar Icon"

                  style={{ width: '24px', height: '24px', cursor: 'pointer', fill: '#F74542', flexShrink: '0' }}
                  onClick={openDateModal}
                />

              </div>

              {errType === 5 && (
                <FormHelperText className="mobmodalerrtxt" id="component-error-text">
                  {errorMsg}
                </FormHelperText>
              )}
            </FormControl>


            <Modal
              open={datemodel}
              onClose={() => setDatemodel(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '80px',
                width: '100%', 
                height: '100%', 
              }}
            >
              <div
                style={{
                  background: '#ffffff',
                  borderRadius: '8px',
                  padding: '16px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem style={{ background: '#ffffff' }}>
                    <DateCalendar
                      value={date}
                      onChange={handleDateChange}
                      minDate={dayjs()}
                      sx={{
                        "& .MuiPickersDay-root": {
                          "&.Mui-selected": {
                            backgroundColor: '#F74542 !important',
                          },
                        },
                        ".MuiPickersCalendarHeader-root": {
                          fontSize: '1rem',
                        },
                        ".MuiPickersDay-root": {
                          fontSize: '1rem',
                        },
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </div>
            </Modal>






            <FormControl component="fieldset">
              <Typography
                className="mobmodalbuttonheading"
                style={{
                  color: errType === 6 ? "#000" : "#333",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                Language Selection
              </Typography>

              <div style={{ display: "flex", gap: "5px", marginTop: "5px" }}

              >

                <Button
                  value="hindi"
                  onClick={() => {
                    toggleLanguage("Hindi");

                  }}
                  className="mobmodalbuttoncard"
                  style={{
                    background: (language === "Hindi" || isHoveredHindi) && "#F3D45E",
                  }}
                  onMouseEnter={() => setIsHoveredHindi(true)}
                  onMouseLeave={() => setIsHoveredHindi(false)}
                  onBlur={validateLanguage}
                >
                  <span className="mobmodalbuttontext">Hindi</span>
                </Button>

                <Button
                  value="english"
                  onClick={() => {
                    toggleLanguage("English");

                  }}
                  className="mobmodalbuttoncard"
                  style={{
                    background: (language === "English" || isHoveredEnglish) && "#F3D45E",
                  }}
                  onMouseEnter={() => setIsHoveredEnglish(true)}
                  onMouseLeave={() => setIsHoveredEnglish(false)}
                  onBlur={validateLanguage}
                >
                  <span className="mobmodalbuttontext">English</span>
                </Button>
              </div>
              {errType === 6 && (
                <FormHelperText className="mobmodalerrtxt" id="component-error-text">
                  {errorMsg}
                </FormHelperText>
              )}
            </FormControl>



            <Grid container style={{ marginTop: "15px", marginLeft: "5px" }}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography
                    className="mobmodalbuttonheading"
                    style={{ color: errType === 7 ? "#F74542" : "#333" }}
                  >
                    Select Your Slots
                  </Typography>
                  <div style={{ display: "flex", gap: "5px", marginTop: "5px" }}>
                    <Button
                      value="10AM-12PM"
                      onClick={() => {
                        toggleSlot("10AM-12PM");

                      }}
                      className="mobmodalbuttoncard"
                      style={{
                        background: (slot === "10AM-12PM" || isHoveredslot1) && "#F3D45E",
                      }}
                      onMouseEnter={() => setIsHoveredslot1(true)}
                      onMouseLeave={() => setIsHoveredslot1(false)}
                      onBlur={validateSlot}
                    >
                      <span className="mobmodalbuttontext">10AM-12PM</span>
                    </Button>

                    <Button
                      value="2PM-3PM"
                      onClick={() => {
                        toggleSlot("2PM-3PM");

                      }}
                      className="mobmodalbuttoncard"
                      style={{
                        background: (slot === "2PM-3PM" || isHoveredslot2) && "#F3D45E",
                      }}
                      onMouseEnter={() => setIsHoveredslot2(true)}
                      onMouseLeave={() => setIsHoveredslot2(false)}
                      onBlur={validateSlot}
                    >
                      <span className="mobmodalbuttontext">2PM-3PM</span>
                    </Button>
                  </div>

                  {errType === 7 && (
                    <FormHelperText className="mobmodalerrtxt" id="component-error-text">
                      {errorMsg}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>


            <Grid container style={{ marginTop: '25px', }}>
              <Grid item style={{ display: 'flex' }}>
                <Button onClick={() => {
                  cancelReset()
                  setErrType(0)
                  setErrorMsg('')

                }}
                  className="mobmodalcancelbutton">Back </Button>
                <Button className="mobmodalsubmitbutton"
                  onClick={handleSubmit}
                >Submit</Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )

}