import React, { Component } from 'react'
import { withStyles, } from '@mui/styles'
import { Typography, Grid, Tab, Tabs, styled, Slider, OutlinedInput, InputAdornment, Box, Button } from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import SquareIcon from '@mui/icons-material/Square';
import styles1 from "../calculators/calculators.module.css"
import Footer from '../landingpage/fotter'

//full file added by dev-Ayush 17-10-2024
const CustomSlider = styled(Slider)(({ theme }) => ({
    color: "#7DAB1C",
    height: "2px",
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 16,
        width: 16,
        border: "1px solid #7DAB1C",
        backgroundColor: '#fff',
        boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
        },
        '&:before': {
            boxShadow:
                '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
        },
    },
    '& .MuiSlider-valueLabel': {
        display: "none"
    },
    '& .MuiSlider-track': {
        border: 'none',
        height: "2px",
    },
    '& .MuiSlider-rail': {
        backgroundColor: '#f1f1f1',
    }
}));

const styles = theme => ({
    buttonlabel: {
        width: "300px !important",
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '15px !important',
        "&:hover": {

            cursor: 'pointer'

        },
        "@media only screen and (max-device-width: 1500px)": {
            width: "230px !important",
        },
        "@media only screen and (max-device-width: 1200px)": {
            width: "160px !important",
        },
    },

    mainlayout: {
        padding: '20px 42px 15px 42px',
        display: 'flex',
        justifyContent: 'center',
        "@media screen and (max-width:960px)": {
            width: '100%', padding: '42px 16px 15px 16px',


        }
    },

    heading: {
        color: '#3E3E3E !important',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '32px !important',
        fontStyle: 'normal !important',
        fontWeight: '600 !important',
        lineHeight: 'normal !important',
        // h1 tag margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',

        }
    },

    main1: {
        marginTop: '90px',
        "@media screen and (max-width:960px)": {
            marginTop: '40px',

        }
    },

})

class calculator extends Component {
    constructor(props) {
        super(props)


        this.state = {
            tabvalue: 0,
            monthlyInvestment: 500,
            timePeriod: 10,
            expectedReturnRate: 12,
            estimatedReturns: 0,
            investedAmount: 0,
            maturityAmount: 0,
            stepUpPercentage: 10,
            calculatorName: ""
        }
    }

    calculateSIP = () => {

        let p = 0
        let r = 0
        let n = 0

        if (window.location.pathname === "/sip-calculator") {

            p = Number(this.state.monthlyInvestment);
            r = Number(this.state.expectedReturnRate / 100 / 12);
            n = Number(this.state.timePeriod * 12);

            const yearlyReturns = [];
            let maturityValue = 0;
            let totalInvestedAmount = 0;

            for (let year = 1; year <= this.state.timePeriod; year++) {
                const months = year * 12;

                const currentMaturityValue = p * ((Math.pow(1 + r, months) - 1) / r) * (1 + r);
                yearlyReturns.push({
                    year: year,
                    maturityValue: Math.round(currentMaturityValue),
                    investedAmount: Math.round(p * months)
                });

                totalInvestedAmount = p * months;
            }

            const finalMaturityValue = yearlyReturns[yearlyReturns.length - 1]?.maturityValue;
            const returns = finalMaturityValue - totalInvestedAmount;

            return {
                maturityValue: Math.round(finalMaturityValue),
                investedAmount: Math.round(totalInvestedAmount),
                estimatedReturns: Math.round(returns),
                yearlyReturns: yearlyReturns
            };
        }

        if (window.location.pathname === "/lumpsum-calculator") {
            const p = Number(this.state.monthlyInvestment);
            const r = Number(this.state.expectedReturnRate) / 100;
            const n = Number(this.state.timePeriod);

            let cumulativeValue = p;
            let totalInvestedAmount = p;
            let totalReturns = 0;
            let yearlyReturns = [];

            for (let year = 1; year <= n; year++) {
                const yearlyReturn = cumulativeValue * r;
                cumulativeValue += yearlyReturn;
                totalReturns = cumulativeValue - totalInvestedAmount;

                yearlyReturns.push({
                    year: year,
                    investedAmount: Math.round(totalInvestedAmount),
                    maturityValue: Math.round(cumulativeValue),
                    yearlyReturn: Math.round(yearlyReturn),
                });
            }

            return {
                maturityValue: Math.round(cumulativeValue),
                investedAmount: Math.round(totalInvestedAmount),
                estimatedReturns: Math.round(totalReturns),
                yearlyReturns: yearlyReturns,
            };

        }

        if (window.location.pathname === "/stepup-sip-calculator") {
            const p = this.state.monthlyInvestment
            const r = this.state.expectedReturnRate / 100 / 12
            const n = this.state.timePeriod * 12
            let totalValue = 0
            let totalInvestedAmount = 0

            let array = []

            for (let year = 0; year < this.state.timePeriod; year++) {
                const monthlyInvestmentForYear = p * Math.pow(1 + this.state.stepUpPercentage / 100, year)
                totalInvestedAmount += monthlyInvestmentForYear * 12

                for (let month = 0; month < 12; month++) {
                    const monthsRemaining = n - (year * 12 + month)
                    const futureValue = monthlyInvestmentForYear * Math.pow(1 + r, monthsRemaining)
                    totalValue += futureValue

                    console.log(monthsRemaining, "months remaining")
                }

                array.push({
                    maturityValue: Math.round(totalValue),
                    investedAmount: Math.round(totalInvestedAmount),
                    estimatedReturns: Math.round(totalValue - totalInvestedAmount)
                })
            }

            console.log(array, "array")
            return {
                maturityValue: Math.round(totalValue),
                investedAmount: Math.round(totalInvestedAmount),
                estimatedReturns: Math.round(totalValue - totalInvestedAmount)
            }
        }


    }


    componentDidMount = async () => {
        document.body.style.backgroundColor = "#f7f9fa"

        if (window.location.pathname === "/sip-calculator") {
            this.setState({ calculatorName: "SIP Calculator" })

        }
        if (window.location.pathname === "/stepup-sip-calculator") {
            this.setState({ calculatorName: "Step Up SIP Calculator" })
        }
        if (window.location.pathname === "/lumpsum-calculator") {
            this.setState({ calculatorName: "Lumpsum Calculator" })
        }
        this.calculateSIP()
        this.setState({ monthlyInvestment: 25000 })

    }

    componentDidUpdate(prevProps) {

        if (this.state.monthlyInvestment !== prevProps.monthlyInvestment) {

            if (window.location.pathname == "/lumpsum-calculator") {
                if (this.state.monthlyInvestment > 10000000) {
                    this.setState({ monthlyInvestment: 10000000 })
                }
            } else {
                if (this.state.monthlyInvestment > 1000000) {
                    this.setState({ monthlyInvestment: 1000000 })
                }
            }
        }

        if (this.state.stepUpPercentage !== prevProps.stepUpPercentage) {
            if (this.state.stepUpPercentage > 50) {
                this.setState({ stepUpPercentage: 50 })
            }
        }

        if (this.state.expectedReturnRate !== prevProps.expectedReturnRate) {
            if (this.state.expectedReturnRate > 30) {
                this.setState({ expectedReturnRate: 30 })
            }
            if (this.state.expectedReturnRate == 0) {
                this.setState({ expectedReturnRate: 12 })
            }
        }

        if (this.state.timePeriod !== prevProps.timePeriod) {
            if (this.state.timePeriod > 40) {
                this.setState({ timePeriod: 40 })
            }
            if (this.state.timePeriod == 0) {
                this.setState({ timePeriod: 12 })
            }
        }

    }



    handleTabChange = (event, value) => {
        this.setState({ tabvalue: value });
    };

    handleInvestmentChange = (e, value) => {
        this.setState({ monthlyInvestment: parseInt(e.target.value) })
    }

    handleStepUpPercentageChange = (e, value) => {
        this.setState({ stepUpPercentage: parseInt(e.target.value) })
    }

    handleTimePeriodChange = (e, value) => {
        this.setState({ timePeriod: parseInt(e.target.value) })
    }

    handleReturnChange = (e, value) => {
        this.setState({ expectedReturnRate: parseFloat(e.target.value) })
    }



    render() {
        const { classes } = this.props;

        return (
            <div style={{ flexDirection: "column", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} className={classes.main1}>
                <Grid className={classes.mainlayout} >
                    <h1 className={classes.heading}>
                        {this.state.calculatorName}
                    </h1>
                </Grid>

                <Grid className={styles1.calculatorMainGrid}>
                    <Grid className={styles1.calculatorSubGrid1}>
                        <Grid>
                            <Grid display="flex" justifyContent="space-between">
                                <Typography className={styles1.calculatorTxt}>{window.location.pathname === "/lumpsum-calculator" ? "Total Investment" : "Monthly Investment"}</Typography>
                                <OutlinedInput
                                    type='number'

                                    value={this.state.monthlyInvestment}
                                    onChange={this.handleInvestmentChange}
                                    size='small'
                                    id="outlined-adornment-weight"
                                    startAdornment={<InputAdornment sx={{ m: "2px 2px 0px 0px" }}>₹</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                        style: { textAlign: 'left', marginRight: "5px" }
                                    }}
                                    className={styles1.calculatorAmount}
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        },
                                    }}
                                />
                            </Grid>
                            <CustomSlider size="small"
                                onChange={this.handleInvestmentChange}
                                step={500}
                                max={window.location.pathname == "/lumpsum-calculator" ? 10000000 : 1000000}
                                value={this.state.monthlyInvestment}
                                aria-label="Small"
                                valueLabelDisplay="auto" />
                        </Grid>
                        {window.location.pathname === "/stepup-sip-calculator" &&
                            <Grid>
                                <Grid display="flex" justifyContent="space-between">
                                    <Typography className={styles1.calculatorTxt}>Annual step up</Typography>
                                    <OutlinedInput
                                        type='number'

                                        value={this.state.stepUpPercentage}
                                        onChange={this.handleStepUpPercentageChange}
                                        size='small'
                                        id="outlined-adornment-weight"
                                        endAdornment={<InputAdornment sx={{ m: "2px 2px 0px 0px" }}>%</InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                            style: { textAlign: 'right', marginRight: "5px", color: "#3E3E3E !important" }
                                        }}
                                        className={styles1.calculatorAmount}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                        }}
                                    />
                                </Grid>
                                <CustomSlider size="small"
                                    onChange={this.handleStepUpPercentageChange}
                                    step={0.1}
                                    max={50}
                                    value={this.state.stepUpPercentage}
                                    aria-label="Small"
                                    valueLabelDisplay="auto" />
                            </Grid>
                        }
                        <Grid>
                            <Grid display="flex" justifyContent="space-between">
                                <Typography className={styles1.calculatorTxt} >Expected return rate (p.a)</Typography>
                                <OutlinedInput
                                    type='number'
                                    value={this.state.expectedReturnRate}
                                    onChange={this.handleReturnChange}
                                    size='small'
                                    id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment sx={{ m: "2px 2px 0px 0px" }}>%</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                        style: { textAlign: 'right', marginRight: "5px" }
                                    }}
                                    className={styles1.calculatorAmount}
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        },
                                    }}
                                />
                            </Grid>
                            <CustomSlider size="small"
                                onChange={this.handleReturnChange}
                                max={30}
                                step={0.1}
                                value={this.state.expectedReturnRate}
                                aria-label="Small"
                                valueLabelDisplay="auto" />
                        </Grid>
                        <Grid>
                            <Grid display="flex" justifyContent="space-between">
                                <Typography className={styles1.calculatorTxt} >Time period</Typography>
                                <OutlinedInput
                                    type='number'
                                    value={this.state.timePeriod}
                                    onChange={this.handleTimePeriodChange}
                                    size='small'
                                    id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment sx={{ m: "2px 2px 0px 0px" }} >Yr</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                        style: { textAlign: 'right', marginRight: "5px" }
                                    }}
                                    className={styles1.calculatorAmount}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        },
                                    }}
                                />
                            </Grid>
                            <CustomSlider size="small"
                                onChange={this.handleTimePeriodChange}
                                max={40}
                                value={this.state.timePeriod}
                                aria-label="Small"
                                valueLabelDisplay="auto" />
                        </Grid>

                        <Grid className={styles1.calculationResult} rowGap={3}>
                            <Grid display="flex" justifyContent="space-between">
                                <Typography className={styles1.investTxt} mr={1} >Invested amount</Typography>
                                <Typography className={styles1.investTxt} textAlign="start" fontFamily="cardiuma-SemiBold" >₹ {new Intl.NumberFormat('en-IN').format(this.calculateSIP().investedAmount) != "NaN" ? new Intl.NumberFormat('en-IN').format(this.calculateSIP()?.investedAmount) : 0}</Typography>
                            </Grid>
                            <Grid display="flex" justifyContent="space-between">
                                <Typography className={styles1.investTxt}>Estimated returns</Typography>
                                <Typography className={styles1.investTxt} textAlign="start" fontFamily="cardiuma-SemiBold">₹ {new Intl.NumberFormat('en-IN').format(this.calculateSIP().estimatedReturns) != "NaN" ? new Intl.NumberFormat('en-IN').format(this.calculateSIP()?.estimatedReturns) : 0}</Typography>
                            </Grid>
                            <Grid display="flex" justifyContent="space-between">
                                <Typography className={styles1.investTxt}>Total value</Typography>
                                <Typography className={styles1.investTxt} textAlign="start" fontFamily="cardiuma-SemiBold">₹ {new Intl.NumberFormat('en-IN').format(this.calculateSIP().maturityValue) != "NaN" ? new Intl.NumberFormat('en-IN').format(this.calculateSIP()?.maturityValue) : 0}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={styles1.calculatorSubGrid2}>
                        <Box sx={{
                            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: window.location.pathname == "/stepup-sip-calculator" ? "80px" : "30px", padding: "0px 20px",
                            "@media only screen and (max-width: 1000px)": {
                                marginTop: window.location.pathname == "/stepup-sip-calculator" ? "0px" : "30px"
                            },
                            "@media only screen and (max-width: 480px)": {
                                padding: "0px 0px",
                            },
                        
                        }} >
                            {window.location.pathname !== "/stepup-sip-calculator" ? <PieChart
                                series={[
                                    {
                                        data: [
                                            { label: 'Invested Amount', value: this.calculateSIP().investedAmount, color: "#206CFF40" },
                                            { label: 'Estimated Return', value: this.calculateSIP().estimatedReturns, color: "#0AA9F4" },
                                        ],
                                        innerRadius: 50,
                                        outerRadius: 80,
                                        cx: (window.innerWidth < 420 && 150) || (window.innerWidth < 480 && 190) || (window.innerWidth < 599 && 75) || 100
                                    },

                                ]}
                                slotProps={{
                                    legend: {
                                        direction: "column",
                                        hidden: window.innerWidth < 480 ? true : false
                                    }
                                }}
                                height={180}
                                width={(window.innerWidth < 420) && 300 || (window.innerWidth < 480) && 400 || (window.innerWidth < 599) && 375 || (window.innerWidth < 599) && 400 || (window.innerWidth > 600 && window.innerWidth < 1100) && 400 || 450}
                            /> 
                            :
                            <PieChart
                                series={[
                                    {
                                        data: [
                                            { label: 'Invested Amount', value: this.calculateSIP().investedAmount, color: "#206CFF40" },
                                            { label: 'Estimated Return', value: this.calculateSIP().estimatedReturns, color: "#0AA9F4" },
                                        ],
                                        cy: (window.innerWidth < 500 && 120) || (window.innerWidth < 1000 && 175) || 150,
                                        cx: (window.innerWidth < 500 && 145) || (window.innerWidth < 1000 && 230) || (window.innerWidth < 1100 && 220) || (window.innerWidth < 1300 && 240) || 230,
                                        innerRadius: window.innerWidth < 500 ? 60 : 100,
                                        outerRadius: window.innerWidth < 500 ? 110 : 150,
                                    },
                                ]}
                                height={(window.innerWidth < 500 && 250) || (window.innerWidth < 1000 && 350) || 320}
                                width={(window.innerWidth < 500 && 300) || 470}
                                slotProps={{
                                    legend: {
                                        hidden: true
                                    },
                                    
                                }}
                            />}


                            <Grid className={window.location.pathname == "/stepup-sip-calculator" ? styles1.legend1 : styles1.legend} display="flex" justifyContent="center">
                                <Grid display="flex" justifyContent="center" alignItems="center">
                                    <SquareIcon sx={{ fontSize: "18px", color: "#206CFF40" }} />
                                    <Typography>Invested amount</Typography>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" sx={{ ml: "20px" }}>
                                    <SquareIcon sx={{ fontSize: "18px", color: "#00A2EF" }} />
                                    <Typography>Estimated returns</Typography>
                                </Grid>
                            </Grid>

                            {window.location.pathname !== "/stepup-sip-calculator" && <BarChart
                                xAxis={[{scaleType: 'band', data: this.calculateSIP().yearlyReturns.map((ele, i) => `${i + 1}Y`), disableTicks: true }]}
                                yAxis={[{
                                    disableTicks: true, disableLine: true, valueFormatter: (num) => {
                                        if (num >= 1e7) {
                                            return (num / 1e7).toFixed(0) + ' Cr';
                                        } else if (num >= 1e5) {
                                            return (num / 1e5).toFixed(0) + ' L';
                                        } else if (num >= 1e3) {
                                            return (num / 1e3).toFixed(0) + ' K';
                                        } else {
                                            return num.toString();
                                        }
                                    }
                                }]}

                                grid={{ horizontal: true }}
                                slotProps={{
                                    legend : {
                                        hidden : true
                                    }
                                }}
                                series={[
                                    {label : "Invested amount",
                                        data: this.calculateSIP().yearlyReturns.map((ele, i) => {
                                            if (window.location.pathname === "/lumpsum-calculator") {
                                                if (i == 0) {
                                                    return ele?.investedAmount
                                                } else {
                                                    return 0
                                                }
                                            }
                                            return ele?.investedAmount


                                        }), stack: 'A', color: "#C7DAFF"
                                    },
                                    {   label : "Estimated returns",
                                        data: this.calculateSIP().yearlyReturns.map((ele, i) => {
                                            if (window.location.pathname === "/lumpsum-calculator") {
                                                if (i == 0) {
                                                    return Math.abs(ele?.investedAmount - ele?.maturityValue)
                                                } else {
                                                    return Math.abs(ele?.maturityValue)
                                                }
                                            }
                                            return Math.abs(ele?.investedAmount - ele?.maturityValue)
                                        }), stack: 'A', color: "#0AA9F4"
                                    },
                                ]}
                                // width={ (window.innerWidth < 480 ) && 400 || (window.innerWidth < 599 ) && 400 || (window.innerWidth > 600 && window.innerWidth < 1100 ) && 450 || 500}
                                height={(window.innerWidth <= 600) && 300 || (window.innerWidth <= 1000) && 400 || 250}
                            />}
                        </Box>


                        <Grid display="flex" justifyContent="center">
                            <Button onClick={() => window.location.assign("/signup")} className={styles1.investButton}>
                                Invest Now
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* added by dev-Ayush 16-12-2024 */}
                <Grid style={{ width: '100%',background:'#0B1420' }}>
                    <Footer/>
                </Grid>
            </div >
        )
    }
}
export default (withStyles(styles)((calculator)));
