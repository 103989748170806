// ALL THE CHANGES ARE IN THIS FILE MADE BY DEV SRINI 0N 16 AUG 24

import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import {
    Typography, Grid, Button, useMediaQuery,
    RadioGroup, FormControlLabel, Radio //added on 13/08/24

} from '@mui/material'


import bull from '../../ui-assets/bull.svg'
import bullicon from '../../ui-assets/icon.png'
//added on 13/08/24 New login screen images. 
import backimg from '../../ui-assets/login_background.svg'
import tp_icon from '../../ui-assets/tp_icon.svg'
import dip_icon from '../../ui-assets/dip_icon.svg'
import exp_icon from '../../ui-assets/exp_icon.svg'
import wealth_vault_icon from "../../ui-assets/wealthVaultIcon.svg"
import backendHosts from '../../ui-utils/apiConfig';
import { useTheme } from '@mui/material';
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Login | BullForce',
    description: 'Login to Bullforce: Secure access to your trading account. Trade stocks, bonds, and more on NSE, BSE. Fast, easy login to your demat account. Start trading today.',
    canonical: 'https://bullforce.co/login',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, login, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}


const styles = theme => ({


    login_but: {
        cursor: 'pointer', marginTop: '10px', background: '#FFFFFF', border: '1px solid #DFE0EB !important', borderRadius: '8px', padding: '12px', width: '100%', display: 'flex', alignItems: 'center',

        "&:hover": {
            background: "#F3D45E !important",
            color: '#000000'


        },

    },

    login_but_sell: {
        cursor: 'pointer', marginTop: '10px', background: '#F3D45E', border: '1px solid #DFE0EB !important', borderRadius: '8px', padding: '12px', width: '100%', display: 'flex', alignItems: 'center',
        color: '#000000',


    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "48px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M  !important"
    },
    l1: {
        width: '25%',
        "@media screen and (max-width:960px)": {

            display: 'none'

        }
    },
    l2: {
        width: '50%', display: 'flex', alignItems: 'center', background: '#1B2636', justifyContent: 'center',
        "@media screen and (max-width:960px)": {
            width: '100%',
        }

    },
    l4: {
        width: '60%',
        "@media screen and (max-width:960px)": {
            width: '90%',
        }

    },

    d1: {



        fontStyle: "normal !important",
        fontWeight: "700 !important", //update on 13/08/24 Font weight change. 
        fontSize: "18px !important",

        color: "#FFFFFF !important",

        fontFamily: "cardiuma-regular !important",
        "@media screen and (max-width:960px)": {
            fontSize: "14px !important",
        }
    },


    d2: {

        fontStyle: "normal !important",

        fontSize: "18px !important",
        fontWeight: "400 !important", //update on 13/08/24 Font weight change. 


        color: "#FFFFFF !important",

        fontFamily: "cardiuma-regular !important",
        "@media screen and (max-width:960px)": {
            fontSize: "14px !important",
        }
    },

    d3: {
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "18px !important",

        color: "#FFFFFF !important",

        fontFamily: "cardiuma-regular !important",
        "@media screen and (max-width:960px)": {
            fontSize: "14px !important",
        }
    },

    d4: {

        fontStyle: "normal !important",

        fontSize: "18px !important",
        fontWeight: "400 !important", //update on 13/08/24 Font weight change. 


        color: "#F3D45E !important",

        fontFamily: "cardiuma-regular !important",
        "@media screen and (max-width:960px)": {
            fontSize: "14px !important",
        }
    },



    //added on 13/08/24 New logins. Css update 
    sel_button: {
        borderRadius: '8px',
        display: 'flex',
        border: '1px solid #F3D45E',
        padding: '10px 15px 5px 10px',
        cursor: 'pointer',
        width: '70%',
        background: '#182D49'
        //#182D49
    },

    sel_button_dis: {
        borderRadius: '8px',
        border: '1px solid #345580',


        display: 'flex',

        padding: '10px 15px 5px 10px',
        cursor: 'pointer',
        width: '70%',
        background: '#182D49'
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important',
        fontSize: '24px !important', fontWeight: "500 !important", width: '100%', textAlign: 'center',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },

})

// Functional component to use hooks
const withThemeAndMediaQuery = (WrappedComponent) => {
    return (props) => {
        const theme = useTheme()
        const range = useMediaQuery(theme.breakpoints.between(601, 849))
        console.log(range, "rangerange")
        return <WrappedComponent {...props} theme={theme} range={range} />
    }
}

class login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            t1: 1,
        }

    }
    componentDidMount = async () => {

        window.Changemytab(5)

    }

    Login() {

        const { t1, t2 } = this.state

        if (t1 === 1) {
            window.open(backendHosts.TRURL, "_blank") //updaed on 13/08/24 Redirect url. 
        }
        //updaed on 13/08/24 Redirect url. 
        if (t1 === 2) {
            window.open(backendHosts.DIPURL, "_blank")
        }
        if (t1 === 3) {
            window.open(backendHosts.EXPURL, "_blank")  //updaed on 13/08/24 Redirect url. 
        }
        if( t1 === 4){
            window.open(backendHosts.WEALTH_VAULT_URL , "_blank")
        }

    }
    render() {
        const { classes, range } = this.props;
        const { t1, t2, } = this.state;

        return (
        <>
            <DocumentMeta {...meta} />
            <Grid sx={{
                background: '#1B2636D9', backgroundImage: window.innerWidth < 849 ? 'none' : `url(${backimg})`,
                backgroundRepeat: 'no-repeat', backgroundSize: 'cover, contain', backgroundPosition: 'center', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'
            }}>

                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {/* Height adjust - 08/01/25 - devsrini */}
                    <Grid container style={{ width: window.innerWidth < 849 ? '100%' : window.innerWidth < 800 ? "50%" : window.innerWidth < 1100 ? "50%" : '40%', height: window.innerHeight > 1070 ? '685px' : window.innerHeight > 900 ? '685px' : window.innerHeight > 585 ? '585px' : '450px', background: '#1B2636D9', borderRadius: window.innerWidth < 849 ? '0px' : '31px', border: window.innerWidth < 849 ? 'none' : 'none ', padding: window.innerWidth < 849 ? '0px' : '25px 25px 25px' }}>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                            <img src={bull} alt="bulllogo" width='19%'></img>

                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bullicon} alt="bulllogo" width="32%" height='80%'></img>
                        </Grid>

                        <Grid style={{ width: '100%', marginTop: '3%', display: 'flex', justifyContent: 'center', }}>
                            <Typography className={classes.card_tit}>Login now</Typography>
                        </Grid>

                        {/* 1st button */}
                        <Grid style={{ width: range ? '68%' : '100%', marginTop: '3%', marginLeft: range && '6%', display: 'flex', justifyContent: range ? 'end' : 'center', alignItems: 'center', }}>
                            <Grid className={this.state.t1 === 1 ? classes.sel_button : classes.sel_button_dis}
                                onClick={() => this.setState({ t1: 1 })}

                            >
                                <Grid container style={{ width: '15%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>

                                    <div style={{ background: '#192433', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={tp_icon} alt="bulllogo" height='70%'></img>
                                    </div>

                                </Grid>
                                <Grid container style={{ width: '75%', marginLeft: '3%', justifyContent: "start", alignContent: "center" }}><Typography className={this.state.t1 === 1 ? classes.d1 : classes.d2}>Trading Platform</Typography></Grid>
                                {this.state.t1 === 1 &&
                                    <Grid style={{ width: '10%' }}>
                                        <RadioGroup
                                            row
                                            defaultValue="upi"
                                            value="true"



                                        >
                                            <FormControlLabel value="indian" control={<Radio
                                                disableripple
                                                size='small'
                                                checked='true'
                                                sx={{
                                                    color: "#999999",
                                                    "&.Mui-checked": {
                                                        position: "relative",
                                                        "&::before": {
                                                            content: '""',
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            color: "yellow"
                                                        },
                                                        "&::after": {
                                                            content: '""',
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#F3D45E",

                                                            borderColor: "#F3D45E",
                                                            color: "yellow"
                                                        },

                                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                                        {
                                                            stroke: '#F3D45E',
                                                            strokeWidth: 0.5,
                                                        },
                                                    }

                                                }}
                                            />} />







                                        </RadioGroup>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        {/* 2nd button */}
                        <Grid style={{ width: range ? '68%' : '100%', marginTop: '3%', marginLeft: range && '6%', display: 'flex', justifyContent: range ? 'end' : 'center', alignItems: 'center' }}>
                            <Grid className={this.state.t1 === 3 ? classes.sel_button : classes.sel_button_dis}

                                onClick={() => this.setState({ t1: 3 })}
                            >
                                <Grid container style={{ width: '15%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>

                                    <div style={{ background: '#192433', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={exp_icon} alt="bulllogo" height='70%'></img>
                                    </div>

                                </Grid>
                                <Grid container style={{ width: '75%', marginLeft: '3%', justifyContent: "start", alignContent: "center" }}><Typography className={this.state.t1 === 3 ? classes.d1 : classes.d2}>Expert Recommendation</Typography></Grid>
                                {this.state.t1 === 3 &&
                                    <Grid style={{ width: '10%' }}>
                                        <RadioGroup
                                            row
                                            defaultValue="upi"
                                            value="true"



                                        >
                                            <FormControlLabel value="indian" control={<Radio
                                                disableripple
                                                size='small'
                                                checked='true'
                                                sx={{
                                                    color: "#999999",
                                                    "&.Mui-checked": {
                                                        position: "relative",
                                                        "&::before": {
                                                            content: '""',
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            color: "yellow"
                                                        },
                                                        "&::after": {
                                                            content: '""',
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#F3D45E",

                                                            borderColor: "#F3D45E",
                                                            color: "yellow"
                                                        },

                                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                                        {
                                                            stroke: '#F3D45E',
                                                            strokeWidth: 0.5,
                                                        },
                                                    }

                                                }}
                                            />} />







                                        </RadioGroup>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        {/* 3nd button */}
                        {/*dev-Ayush 22/09/2024 */}
                        <Grid style={{ width: range ? '68%' : '100%', marginTop: '3%', marginLeft: range && '6%', display: 'flex', justifyContent: range ? 'end' : 'center', alignItems: 'center' }}>
                            <Grid className={this.state.t1 === 4 ? classes.sel_button : classes.sel_button_dis}

                                onClick={() => this.setState({ t1: 4 })}
                            >
                                <Grid container style={{ width: '15%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>

                                    <div style={{ background: '#192433', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={wealth_vault_icon} alt="bulllogo" height='70%'></img>
                                    </div>

                                </Grid>
                                <Grid container style={{ width: '75%', marginLeft: '3%', justifyContent: "start", alignContent: "center" }}><Typography className={this.state.t1 === 4 ? classes.d1 : classes.d2}>Wealth Vaults</Typography></Grid>
                                {this.state.t1 === 4 &&
                                    <Grid style={{ width: '10%' }}>
                                        <RadioGroup
                                            row
                                            defaultValue="upi"
                                            value="true"



                                        >
                                            <FormControlLabel value="indian" control={<Radio
                                                disableripple
                                                size='small'
                                                checked='true'
                                                sx={{
                                                    color: "#999999",
                                                    "&.Mui-checked": {
                                                        position: "relative",
                                                        "&::before": {
                                                            content: '""',
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            color: "yellow"
                                                        },
                                                        "&::after": {
                                                            content: '""',
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#F3D45E",

                                                            borderColor: "#F3D45E",
                                                            color: "yellow"
                                                        },

                                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                                        {
                                                            stroke: '#F3D45E',
                                                            strokeWidth: 0.5,
                                                        },
                                                    }

                                                }}
                                            />} />







                                        </RadioGroup>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {/*dev-Ayush 22/09/2024 */}

                        {/* 4th button */}
                        <Grid style={{ width: range ? '68%' : '100%', marginTop: '3%', marginLeft: range && '6%', display: 'flex', justifyContent: range ? 'end' : 'center', alignItems: 'center' }}>
                            <Grid className={this.state.t1 === 2 ? classes.sel_button : classes.sel_button_dis}
                                onClick={() => this.setState({ t1: 2 })}
                            >
                                <Grid container style={{ width: '15%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>

                                    <div style={{ background: '#192433', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={dip_icon} alt="bulllogo" height='70%'></img>
                                    </div>

                                </Grid>
                                <Grid container style={{ width: '75%', marginLeft: '3%', justifyContent: "start", alignContent: "center" }}><Typography className={this.state.t1 === 2 ? classes.d1 : classes.d2}>Diplomats</Typography></Grid>
                                {this.state.t1 === 2 &&
                                    <Grid style={{ width: '10%' }}>
                                        <RadioGroup
                                            row
                                            defaultValue="upi"
                                            value="true"



                                        >
                                            <FormControlLabel value="indian" control={<Radio
                                                disableripple
                                                size='small'
                                                checked='true'
                                                sx={{
                                                    color: "#999999",
                                                    "&.Mui-checked": {
                                                        position: "relative",
                                                        "&::before": {
                                                            content: '""',
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            color: "yellow"
                                                        },
                                                        "&::after": {
                                                            content: '""',
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#F3D45E",

                                                            borderColor: "#F3D45E",
                                                            color: "yellow"
                                                        },

                                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                                        {
                                                            stroke: '#F3D45E',
                                                            strokeWidth: 0.5,
                                                        },
                                                    }

                                                }}
                                            />} />







                                        </RadioGroup>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <Grid style={{ width: range ? '48%' : '70%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                <Button fullWidth className={classes.joinDiplomtsBtn}
                                    style={{ marginTop: '20px', }}

                                    onClick={() => this.Login()}

                                >Login

                                </Button>
                            </Grid>
                        </Grid>

                        <Grid style={{ marginTop: '3%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography className={classes.d3}>Don’t have an account ? </Typography>
                            <Typography className={classes.d4} sx={{ marginLeft: "1%", cursor:'pointer !important' }}
                                onClick={() => this.props.history.push('/signup', this.state)}

                            > Register </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
        )
    }
}
export default withThemeAndMediaQuery((withStyles(styles)((login))));