import { Grid, Typography } from '@mui/material'

import './blog.css'
import BlogListing from './BlogListing'
import Footer from '../landingpage/fotter'

const Blog = () => {

    return (
        <Grid container bgcolor='#fff'>
            {/* mt -Srini - 23-01-25 */}
            <Grid item className='wblimg' mt='30px'>
                <Typography className='woblg'>
                    Our Blogs
                </Typography>
                <Typography className='wbpetxt'>
                    The Bullforce platform emphasizes transparency with a no-dealing-desk (NDD) model and provides resources for real-time market insights.
                </Typography>
            </Grid>

            <BlogListing />

            <Grid style={{ width: '100%', background: '#0B1420', marginTop: '50px' }}>
                <Footer />
            </Grid>
        </Grid>
    )

}

export default Blog