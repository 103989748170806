import React from 'react'
import { Typography, Grid, } from '@mui/material'

const ExcellenceBlog = ({ blog }) => {
    return (
        <>
            {/* Legacy of Trust */}
            {blog.content?.aLegacyOfTrust && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>A Legacy of Trust</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.aLegacyOfTrust}</h2>
                </Grid>
            )}

            {blog.content?.understandingClientNeeds && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>Understanding Client Needs</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.understandingClientNeeds}</h2>
                </Grid>
            )}

            {blog.content?.personalizedInvestmentSolutions && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>Personalized Investment Solutions</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.personalizedInvestmentSolutions}</h2>
                </Grid>
            )}

            {blog.content?.leveragingTechnology && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>Leveraging Technology for Enhanced Experience</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.leveragingTechnology}</h2>
                </Grid>
            )}

            {blog.content?.comprehensiveResearch && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>Comprehensive Research and Insights</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.comprehensiveResearch}</h2>
                </Grid>
            )}

            {blog.content?.proactiveCustomerSupport && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>Proactive Customer Support</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.proactiveCustomerSupport}</h2>
                </Grid>
            )}

            {blog.content?.educationalInitiatives && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>Educational Initiatives</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.educationalInitiatives}</h2>
                </Grid>
            )}

            {blog.content?.buildingACommunity && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>Building a Community</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.buildingACommunity}</h2>
                </Grid>
            )}

            {blog.content?.conclusion && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>
                        Conclusion
                    </h1>
                    <h2 className='wblgintr' paragraph>
                        {blog.content.conclusion.split("BullForce").map((part, index) => (
                            <>
                                {part}
                                {index < blog.content.conclusion.split("BullForce").length - 1 && (
                                    <a
                                        href="https://bullforce.co/lumpsum-calculator" target="_blank" rel="noopener noreferrer"
                                    >
                                        BullForce
                                    </a>
                                )}
                            </>
                        ))}

                    </h2>
                </Grid>
            )}

        </>
    )
}

export default ExcellenceBlog