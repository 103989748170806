import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";

import * as CryptoJS from 'crypto-js';
import {

    Typography, Grid,
    Snackbar, SnackbarContent, IconButton, Checkbox,Hidden
} from '@mui/material'

import verfiy from '../../ui-assets/verfiy.svg'

import signupbull from '../../ui-assets/reg_steps.svg' //dev-trupti on 05-11-2024
import signupbull1 from '../../ui-assets/reg_info.svg'

import nominee from '../../ui-assets/nomnib.svg'

import { Close } from '@mui/icons-material';

import logo from '../../ui-assets/icon.png'

import noimage from '../../ui-assets/noimage.svg'




import bull from '../../ui-assets/bull.svg'


import './signup.css'
import setp_1 from '../../ui-assets/step_1.png'

import backendHosts from '../../ui-utils/apiConfig.js'





import Page0 from './pages/main.js'
import Page1 from './pages/aadharno.js'
import Page2 from './pages/otp.js'
import Page3 from './pages/pan.js'
import Page4 from './pages/occupation.js'
import Page5 from './pages/annual_Income.js'
import Page6 from './pages/preferences.js'
import Page6_1 from './pages/marital_status.js'
import Page6_2 from './pages/qualification.js'
import Page7 from './pages/bank.js'
import Page7_1 from './pages/psi.js'
import Page8 from './pages/liveness.js'
import Page9 from './pages/e_sign.js'
import Page10 from './pages/e_sign_otp.js'
import Page11 from './pages/payment.js'
import Page11_1 from './pages/exchanges.js'// added on 25/07/24 New exchange selection page 
import Page12 from './pages/nominee.js'
import Page13 from './pages/documentupload.js'
import Page13_1 from './pages/documentuploadboth.js'
import Page14 from './pages/signature.js'
import Page17_2 from './pages/nri_upload.js' //update to put nri upload to lager screen
import Page16 from './pages/congratulations.js'
import Page18 from './pages/campaign_congratulations.js' //added by dev-trupti on 12-11-2024
import Page100 from './pages/mobile_step.js'
import Page14_1 from './pages/s_otp.js'
import Page17 from './pages/digilocker.js'
import Page16_1 from './pages/depository.js'
import Page17_1 from './pages/account_aggregator.js'
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#F74542",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#F74542'
        }
    },
    checked: { color: "#F74542", }
}))(Checkbox);



const styles = theme => ({
    maincard: {
        width:'48%',height:'100vh',marginTop:'2%',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none', padding: '0%',
        },

    },
    maincard2: {
        width:'48%',marginTop:'2%',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none', padding: '0%',
        },

    },
    maincard1: {
        width:'45%',background:'#1B2636',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', padding: '0%',
        },

    },

})
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },


};





class newsignup extends Component {
    //  ws = new WebSocket(backendHosts.WSURL);
    constructor(props) {
        super(props)

        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
            pageno:0,
            aadharno: '',
            fullname: '',
            gender: '',
            dob: '',
            emailid: '',
            profile_img: '',
            panno: '',
            Occupation: '-',
            Annual_Income: '-',
            tinvestment: '-',
            backimg_style: 1,
            bankaccno: '',
            upi_id: '',
            ifsc: '',
            esign: true,
            imagepage: 0,
            aadharverfy: 0,
            panverfy: 0,
            qrpage: 0,
            citizen: 0, 
            mobile_verify:"0",//added on 14/03/24
            email_verify:"0"  ,//added on 14/03/24
         
        }



    }


    closeContactModal() {
        this.setState({ showerr: false })
    }



    componentDidMount = async () => {

        window.Changemytab(5)

        document.body.style.backgroundColor = "#0B1420"

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        console.log(decodeURIComponent(urlParams.get('data'), "base64"))
        if (window.innerWidth < 960) {
         //   this.setState({ pageno: 100 })
            window.Changemytab(6)


        }
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        if (rd2.stage === 4) {
          
            this.setState({ pageno: 16.1 })
       }
        if (rd2.stage === 6) {
          
                this.setState({
                    emailid: rd2.emailid,
                    mobileno: rd2.mobileno,
                    stage: 0,
                    citizen: rd2.citizen,
                    aadharno: rd2.aadharno,
                    panverfy: rd2.panverfy,
                    aadharverfy: rd2.aadharverfy,
                    upi_id: rd2.upi_id,
                    bankaccno: rd2.bankaccno,
                    ifsc: rd2.ifsc,
                    tinvestment: rd2.tinvestment,
                    Annual_Income: rd2.Annual_Income,
                    Occupation: rd2.Occupation,
                    panno: rd2.panno,
    
                    fullname: rd2.fullname,
                    dob: rd2.dob,
                    gender: rd2.gender,
                    profile_img: rd2.profile_img,
                    father_name: rd2.father_name, 
                    mobile_verify:rd2.mobile_verify,//addon 14/03/24
                    email_verify:rd2.email_verify//addon 14/03/24
          
                  
                })
            this.setState({ pageno: 11 })
        }
        if (rd2.stage === 3) {
         
                this.setState({
                    emailid: rd2.emailid,
                    mobileno: rd2.mobileno,
                    stage: 0,
                    citizen: rd2.citizen,
                    aadharno: rd2.aadharno,
                    panverfy: rd2.panverfy,
                    aadharverfy: rd2.aadharverfy,
                    upi_id: rd2.upi_id,
                    bankaccno: rd2.bankaccno,
                    ifsc: rd2.ifsc,
                    tinvestment: rd2.tinvestment,
                    Annual_Income: rd2.Annual_Income,
                    Occupation: rd2.Occupation,
                    panno: rd2.panno,
    
                    fullname: rd2.fullname,
                    dob: rd2.dob,
                    gender: rd2.gender,
                    profile_img: rd2.profile_img,
                    father_name: rd2.father_name,
                    mobile_verify:rd2.mobile_verify,//addon 14/03/24
                    email_verify:rd2.email_verify//addon 14/03/24
          
                    
                })
            this.setState({ pageno: 17 })
        }
        window.ChangePageNo = (pageno) => {
          
            let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)

            this.setState({
                emailid: rd2.emailid,
                mobileno: rd2.mobileno,
                stage: 0,
                citizen: rd2.citizen,
                aadharno: rd2.aadharno,
                panverfy: rd2.panverfy,
                aadharverfy: rd2.aadharverfy,
                upi_id: rd2.upi_id,
                bankaccno: rd2.bankaccno,
                ifsc: rd2.ifsc,
                tinvestment: rd2.tinvestment,
                Annual_Income: rd2.Annual_Income,
                Occupation: rd2.Occupation,
                panno: rd2.panno,

                fullname: rd2.fullname,
                dob: rd2.dob,
                gender: rd2.gender,
                profile_img: rd2.profile_img,
                father_name: rd2.father_name,
                mobile_verify:rd2.mobile_verify,//addon 14/03/24
                email_verify:rd2.email_verify//addon 14/03/24
      

            })
console.log('Full Name=',rd2.fullname)
            this.setState({ pageno: pageno });

        }


        let a = localStorage.getItem('signup') || '{}'
        let b = JSON.parse(a)


        //  this.setState({ emailid: b.email, mobileno: b.mobileno })
        localStorage.removeItem('signup')
        let intid = setInterval(() => {




            this.setState({ imagepage: this.state.imagepage + 1 })

            if (this.state.imagepage > 1) {
                this.setState({ imagepage: 0 })
            }


        }, 3000);
       

    }

    onSetpEnd(e) {


        if (e === 2) {
            window.Changemytab(6)
            this.setState({ pageno: 0 })
        }

    }
    ChangeStep() {
        window.Changemytab(5)
        this.setState({ pageno: 100 })
    }

    
        render() {
            const { classes } = this.props;
            const { pageno, backimg_style } = this.state
            return (
                <div>
                  <Hidden only={["xs", "sm"]}>
                 {pageno === 16.1 &&
                          <div style={{display : "flex" ,  justifyContent : "center"}}>
                              <div style={{backgroundColor : "#1B2636" , marginLeft : "200px" , marginRight : "200px"}} >
                                  <Page16_1/>
                              </div>
                          </div>        
                          } 

{pageno === 17.2 &&
                          <div>
                              
                                  <Page17_2/>
                              </div>
                          
                          }      
                <Grid container style={{width:'100%',height:pageno===16.1 ? '0vh':'100vh',position:'relative'}}>
                
              
    
                    
                         {pageno === 16 &&
                            <div>
                                <Page16/>
                            </div>
                         }
                   
                   {/* added by dev-trupti on 12-11-2024 */}
                   {pageno === 18 &&
                            <div>
                                <Page18/>
                            </div>
                         }
                  
                    
                 
                    <Grid style={{width:pageno>=16 ?'28%':'45%',height:pageno===16.1?'0vh':'100vh',background:pageno>=16 ? '#1B2636':'',marginLeft:pageno>=16 ?'20%':'0%'}}></Grid>
                   
       
     
                    <Grid className={classes.maincard1}
                    
                    style={{width:pageno>=16 ?'35%':'45%'}}
                    >
    
                    </Grid>
                    <Grid container style={{width:'100%',height:pageno===16.1?'0vh':'100vh',position:'absolute',top:'0',left:'0'}}>
    
                    <Grid className={pageno===16.1? classes.maincard2:classes.maincard}>
                    {pageno === 17 &&
                            <div>
                                <Page17/>
                            </div>
                         }
                    {pageno === 0 &&
    
    <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
        
            <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                <a href='/'>
                    <img src={bull} alt='step1' style={{ height: '75px' }}></img>
                </a>
            </Grid>
            <Grid style={{ marginLeft: '5px', width: '40%', display: 'flex', alignItems: 'flex-end' }}>
                <a href='/'>
                    <img src={logo} alt='step2' style={{ height: '22px', alignItems: 'flex-end' }}></img>
                </a>
            
        </Grid>
       
    
    </Grid>
    }
    {pageno === 16 &&
    <div>
    <Grid style={{ width: '45%', display: 'flex',justifyContent:'flex-end'  }}>
    <a href='/'>
                    <img src={logo} alt='step2' style={{ height: '22px', alignItems: 'flex-end' }}></img>
                </a>
            
        </Grid>
    </div>
        }
      
    {pageno > 0 && pageno < 16 &&
    
    <Grid style={{ width: '100%',display:'flex',marginTop:'2%' }}>
        
    <Grid style={{  width: '18%', display: 'flex', alignItems: 'flex-end' }}>
              
        </Grid>
       
            <Grid style={{ width: '40%', display: 'flex',  }}>
                <a href='/'>
                    <img src={logo} alt='step2' style={{ height: '22px', alignItems: 'flex-end' }}></img>
                </a>
            
        </Grid>
       
    
    </Grid>
    }
    
    <Grid style={{width:'100%',display:pageno<16?'flex':'none'}}>
    
    <Grid style={{width:'15%'}}>
    
    </Grid>
    <Grid style={{width:'85%',marginTop:'2%', background:pageno===0 ?'transparent': pageno===12 ?'#0B1420' :'#1B2636',border:pageno===0 ? 'none': pageno===12 ?'4px solid #1B2636': '4px solid #0B1420',borderRadius:pageno===0 ?'0px':'24px',padding:pageno===0 ?'0%' :'2% 2%'}}>
    {pageno===0 &&
    <div>
    <Grid style={{ width: '100%' }}>
                                       
                                        <Grid style={{  width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', zIndex: '1' }}>
                                                <Grid container style={{
    
    
                                                     width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2px 0px'
                                                }}>
                                                    {this.state.imagepage === 0 &&
                                                        <img src={signupbull1} alt="step" style={{ width: '98%' }} className={classes.signup_carousel_img} />
                                                    }
    
                                                    {this.state.imagepage === 1 &&
                                                        <img src={signupbull} alt="step" style={{ width: '98%' }} className={classes.signup_carousel_img} />
                                                    }
                                                    {this.state.imagepage === 2 &&
                                                        <img src={signupbull1} alt="step" style={{ width: '98%' }} className={classes.signup_carousel_img} />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
    
                                    </Grid>
       </div> }
    
    {pageno>0 &&
    
    <div>
    <div  style={{display:pageno===12 ?'block':'none'}}>
    <Grid style={{ width: '100%', padding: '10%', alignItems: 'center', justifyContent: 'center', background: '#0B1420', borderRadius: '8px' }}>
        <Typography style={{color: '#FBB72E', fontFamily: 'cardiuma-regular', fontSize: '18px' }}>
        This helps to avoid any delay in the transfer of securities, which could cause financial hardship for the family.
        </Typography>
        <Typography style={{color: '#FBB72E', fontFamily: 'cardiuma-regular', fontSize: '18px',paddingTop:'5%' }}>
        Additionally, appointing a nominee for a Demat account is essential to estate planning, which can help secure the family's future.
        </Typography>
    </Grid>
    <Grid style={{marginTop:'-30%',marginLeft:'-25%'}}>
        <img src={nominee} style={{width:'50%'}}></img>
    </Grid>
        </div>
     
    <div  style={{display:pageno===12 ?'none':''}}>
    
    <Grid style={{ width: '100%', display: 'flex', padding: '1%', alignItems: 'center', justifyContent: 'center', background: '#0B1420', borderRadius: '8px' }}>
    
    <Typography style={{ color: '#F3D45E', fontFamily: 'cardiuma-M', fontSize: '24px' }}>Account opening form</Typography>
    </Grid>
    <Grid style={{ width: '100%', display: 'flex' }}>
        {/* page error handled - 06/09/24 - Dev Srini */}
                                                    <Grid style={{ marginTop: '24px', width: '130px', display: 'flex', justifyContent: 'center' }}>
                                                        <img src={pageno <= 2 ? noimage : this.state.profile_img?.length<=10 ? noimage :this.state.profile_img} onerror={setp_1} height='125px' width='125px' alt='profile'></img>
                                                    </Grid>
                                                    <Grid style={{ width: '70%', marginLeft: '3%', marginTop: '24px', }}>
                                                        <Grid style={{ width: '100%' }}>
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'cardiuma-regular' ,fontWeight:'100'}}>Name :{' '}{this.state.fullname}</Typography>
                                                        </Grid>
                                                        <Grid style={{ width: '100%', marginTop: '10px' }}>
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'cardiuma-regular',fontWeight:'100' }}>{this.state.father_name}</Typography>
                                                        </Grid>
    
                                                        <Grid style={{ width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'cardiuma-regular',fontWeight:'100' }}>Email ID :{' '}{this.state.emailid}</Typography>
                                                            {this.state.email_verify === "1" &&
                                                                <div>
    
                                                                    <img src={verfiy} style={{ marginLeft: '10px' }} alt='verfiy'></img>
                                                                </div>
    
                                                            }
                                                        </Grid>
                                                        <Grid style={{ width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
    
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'cardiuma-regular',fontWeight:'100' }}>Phone number :{' '}{this.state.mobileno}</Typography>
                                                            {this.state.mobile_verify === "1" &&
                                                                <div>
    
                                                                    <img src={verfiy} style={{ marginLeft: '10px' }} alt='verfiy'></img>
                                                                </div>
    
                                                            }
                                                        </Grid>
                                                        <Grid style={{ width: '100%', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
    
                                                            {this.state.aadharverfy !== 1 &&
                                                                <Typography style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'cardiuma-regular',fontWeight:'100' }}>Aadhar number :</Typography>
                                                            }
                                                            {this.state.aadharverfy === 1 &&
                                                                <div style={{ display: 'flex' }}>
                                                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'cardiuma-regular',fontWeight:'100' }}>Aadhar number :{' '}{this.state.aadharno}</Typography>
                                                                    <img src={verfiy} style={{ marginLeft: '5px' }} alt='verfiy'></img>
                                                                </div>
                                                            }
                                                        </Grid>
    
                                                        <Grid style={{ width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            {this.state.panverfy !== 1 &&
                                                                <Typography style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'cardiuma-regular',fontWeight:'100' }}>PAN number :</Typography>
                                                            }
    
                                                            {this.state.panverfy === 1 &&
                                                                <div style={{ display: 'flex' }}>
                                                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'cardiuma-regular',fontWeight:'100' }}>PAN number :{' '}{this.state.panno}</Typography>
                                                                    <img src={this.state.panverfy === 1 ? verfiy : ''} style={{ marginLeft: '5px' }} alt='verfiy'></img>
                                                                </div>
                                                            }
                                                        </Grid>
    
                                                    </Grid>
                                                </Grid>
                                                <Grid style={{ padding: '1%', marginTop: '5%', width: '100%', display: 'flex', justifyContent: 'space-evenly', background: '#0B1420', borderRadius: '8px' }}>
                                                    <Grid style={{ width: '33%' }}>
                                                        <Typography style={{ textAlign: 'center', color: '#ffffff', fontFamily: 'cardiuma-M', fontSize: '14px' }}>{this.state.Occupation}</Typography>
                                                        <Typography style={{ marginTop: '5px', textAlign: 'center', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>Occupation</Typography>
    
                                                    </Grid>
                                                    <Grid style={{ width: '33%' }}>
                                                        <Typography style={{ textAlign: 'center', color: '#ffffff', fontFamily: 'cardiuma-M', fontSize: '14px' }}>{this.state.Annual_Income}</Typography>
                                                        <Typography style={{ marginTop: '5px', textAlign: 'center', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>Annual Income</Typography>
    
                                                    </Grid>
                                                    <Grid style={{ width: '33%' }}>
                                                        <Typography style={{ textAlign: 'center', color: '#ffffff', fontFamily: 'cardiuma-M', fontSize: '14px' }}>{this.state.tinvestment}</Typography>
                                                        <Typography style={{ marginTop: '5px', textAlign: 'center', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>Trading & Investment</Typography>
    
                                                    </Grid>
                                                </Grid>
                                                <Grid style={{ marginTop: '5%', width: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <Grid style={{ width: '26%' }}>
                                                        <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Bank account :</Typography>
                                                    </Grid>
                                                   
                                                    <Grid style={{ width: '30%', borderRight: '1px solid #999999', paddingLeft: '3px' }}>
                                                        <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>{this.state.bankaccno}</Typography>
                                                    </Grid>
                                                    <Grid style={{ width: '45%', paddingLeft: '3px' }}>
                                                        <Typography style={{ textAlign:'center', color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>{this.state.ifsc}</Typography>
                                                    </Grid>
    
                                                </Grid>
                                                <Grid style={{ marginTop: '2%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <WhiteBackgroundCheckbox
                                                        checked={this.state.esign}
                                                        onChange={() => this.setState({ esign: !this.state.esign })}
                                                    ></WhiteBackgroundCheckbox>
                                                    <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '16px' }}>I agree with </Typography>
    
                                                    
                                                    <Typography style={{marginLeft:'5px',  cursor: 'pointer', color: '#0083CA', fontFamily: 'cardiuma-regular', fontSize: '16px' }}
    
                                                        onClick={() => window.open('/pricing')} //updated 01/03/24
                                                    > Pricing &</Typography> 
                                                    <Typography style={{ cursor: 'pointer', color: '#0083CA', fontFamily: 'cardiuma-regular', fontSize: '16px' }}
    
                                                        onClick={() => window.open('/Privacy_Policy')} //updated 01/03/24
                                                    >Terms and Conditions</Typography> 
                                                </Grid>
    </div>
    </div>}
    </Grid>
       
    </Grid>
    
                    </Grid>
                    <Grid container style={{ width:'40%',display:'flex',justifyContent:'center',alignItems:'center',padding:'0% 5% 0% 3%'}}>
                    {pageno === 0 &&
                                    <div>
                                        <Page0 />
                                    </div>
    
                                }
                                {pageno === 100 &&
    
                                    <div>
                                        <Page100 />
                                    </div>
    
                                }
                                {pageno === 1 &&
    
                                    <div>
                                        <Page1 />
                                    </div>
    
                                }
                                {pageno === 2 &&
    
    
                                    <div>
                                        <Page2 />
                                    </div>
    
    
                                }
                                {pageno === 3 &&
    
                                    <div>
                                        <Page3 />
                                    </div>
    
                                }
                                {pageno === 4 &&
    
    
                                    <div>
                                        <Page4 />
                                    </div>
    
                                }
                                {pageno === 5 &&
    
                                    <div>
                                        <Page5 />
                                    </div>
    
                                }
                                {pageno === 6 &&
    
    
                                    <div>
                                        <Page6 />
                                    </div>
    
                                }
                                 {pageno === 6.1 &&
    
    
    <div>
        <Page6_1 />
    </div>
    
    }
    {pageno === 6.2 &&
    
    
    <div>
        <Page6_2 />
    </div>
    
    }
                                {pageno === 7 &&
    
    
                                    <div>
                                        <Page7 />
                                    </div>
    
                                }
                                  {pageno === 7.1 &&
    
    
    <div>
        <Page7_1 />
    </div>

}
                                {pageno === 8 &&
    
    
                                    <div>
                                        <Page8 />
                                    </div>
    
                                }
    
                                {pageno === 9 &&
    
    
                                    <div>
                                        <Page9 />
                                    </div>
    
    
                                }
                                {pageno === 10 &&
                                    <div>
                                        <Page10 />
                                    </div>
                                }
                                {pageno === 13 &&
                                    <div>
                                        <Page13 />
                                    </div>
                                }
                                 {pageno === 13.1 &&
                                    <div>
                                        <Page13_1 />
                                    </div>
                                }
    
    
                                {pageno === 14 &&
                                    <div>
    
                                        <Page14 />
    
                                    </div>
    
                                }
                                {pageno === 14.1 &&
                                    <div>
    
                                        <Page14_1 />
    
                                    </div>
                                }
                                {pageno === 17 &&
                                    <div>
    
                                        <Page17 />
    
                                    </div>
    
    
                                }
     
     {pageno === 17.1 &&
                                    <div>
    
                                        <Page17_1 />
    
                                    </div>
    
    
                                }
    
                                {pageno === 15 &&
                                    <div>
                                        
                                    </div>
    
                                }
    
    {/* added on 25/07/24 New exchange selection page for desktop.  */}
                                {pageno === 11.1 &&
    
                                    <div>
                                        <Page11_1 />
                                    </div>
    
    
                                }
                                 {pageno === 11 &&
    
    <div style={{display:'grid',justifyContent:'center',width:'100%'}}>
        <Page11 />
    </div>
    
    
    }
                                {pageno === 12 &&
    
    
                                    <div>
                                        <Page12 />
                                    </div>
    
    
    
                                }
                    </Grid>
                        
                
    
                    </Grid>
                </Grid>
                </Hidden>
                <Hidden only={["lg", "md", "xl"]}>
                <Grid style={{height:pageno === 16.1 ? "0vh" :'100vh',background:'#1B2636',marginTop:pageno === 16.1 ? "0%" : "3%",padding:'0px 16px'}}>
                    {pageno === 0 &&
                                    <div>
                                        <Page0 />
                                    </div>
    
                                }
                                {pageno === 100 &&
    
                                    <div>
                                        <Page100 />
                                    </div>
    
                                }
                                {pageno === 1 &&
    
                                    <div>
                                        <Page1 />
                                    </div>
    
                                }
                                {pageno === 2 &&
    
    
                                    <div>
                                        <Page2 />
                                    </div>
    
    
                                }
                                {pageno === 3 &&
    
                                    <div>
                                        <Page3 />
                                    </div>
    
                                }
                                {pageno === 4 &&
    
    
                                    <div>
                                        <Page4 />
                                    </div>
    
                                }
                                {pageno === 5 &&
    
                                    <div>
                                        <Page5 />
                                    </div>
    
                                }
                                {pageno === 6 &&
    
    
                                    <div>
                                        <Page6 />
                                    </div>
    
                                }
                                     {pageno === 6.1 &&
    
    
    <div>
        <Page6_1 />
    </div>
    
    }
    
    {pageno === 6.2 &&
    
    
    <div>
        <Page6_2 />
    </div>
    
    }
                                {pageno === 7 &&
    
    
                                    <div>
                                        <Page7 />
                                    </div>
    
                                }
                                   {pageno === 7.1 &&
    
    
    <div>
        <Page7_1 />
    </div>

}
                                {pageno === 8 &&
    
    
                                    <div>
                                        <Page8 />
                                    </div>
    
                                }
    
                                {pageno === 9 &&
    
    
                                    <div>
                                        <Page9 />
                                    </div>
    
    
                                }
                                {pageno === 10 &&
                                    <div>
                                        <Page10 />
                                    </div>
                                }
                                  {pageno === 11 &&
    
    <div>
        <Page11 />
    </div>
    
    
    }
    {/* added on 25/07/24 New exchange selection page for Mobile. */}
                                    {pageno === 11.1 &&
    
                                    <div>
                                    <Page11_1 />
                                    </div>
    
    
    }
    {pageno === 12 &&
    
    
    <div>
        <Page12 />
    </div>
    
    
    
    }
                                {pageno === 13 &&
                                    <div>
                                        <Page13 />
                                    </div>
                                }
                                {/* Added mobile screen start 22-10-2024 by dev-trupti  */}
                                {pageno === 13.1 &&
                                    <div>
                                        <Page13_1 />
                                    </div>
                                }
                                {/* Added mobile screen end 22-10-2024 by dev-trupti  */}
                                {pageno === 14 &&
                                    <div>
    
                                        <Page14 />
    
                                    </div>
    
                                }
                                {pageno === 14.1 &&
                                    <div>
    
                                        <Page14_1 />
    
                                    </div>
                                }
                                {pageno === 17 &&
                                    <div>
    
                                        <Page17 />
    
                                    </div>
    
    
                                }
                                 {pageno === 17.1 &&
                                    <div>
    
                                        <Page17_1 />
    
                                    </div>
    
    
                                }
                                 
                                {pageno === 15 &&
                                    <div>
                                       
                                    </div>
    
                                }
    
    
    {pageno === 16 &&
                                    <div>
                                        <Page16 />
                                    </div>
    
                                }
                        
                        {/* added by dev-trupti on 12-11-2024 */}
                        {pageno === 18 &&
                            <div>
                                <Page18/>
                            </div>
                         }

    {pageno === 16.1 &&
                                    <div style={{display : "flex" ,  justifyContent : "center"}}>
                                    <div style={{backgroundColor : "#1B2636"}} >
                                        <Page16_1/>
                                    </div>
                                </div> 
    
                                }
    {pageno === 17 &&
                            <div>
                                <Page17/>
                            </div>
                         }
                         {/* Added Mobile screen for docupload for nri dev-trupti on 10-01-2025 */}
                         {pageno === 17.2 &&
                          <div>     
                                  <Page17_2/> 
                              </div>
                          }  
                    </Grid>
                    </Hidden>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        open={this.state.showerr}
                        autoHideDuration={3000}
                        onClose={() => this.closeContactModal()}
    
                    >
    
                        <SnackbarContent
                            style={this.state.mysnack}
                            message={
    
                                <span style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#333333",
                                    fontSize: "12px",
                                    fontFamily: "cardiuma-M !important"
                                }}>
    
                                    {this.state.errorMsg}
    
                                </span>
                            }
                            action={
                                <React.Fragment>
    
                                    <IconButton
                                        size="small"
                                        aria-label="close"
                                        color="inherit"
                                        onClick={() => { this.setState({ showerr: false }) }}
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            }
                        >
    
                        </SnackbarContent>
    
                    </Snackbar>
    
    
                
                </div >
    
            )
        }
    }
    export default (withStyles(styles)((newsignup)));
    