import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import OtpInput from "react-otp-input";
import { httpRequest } from '../../../ui-utils/api'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'

import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {
    Typography, Grid, Button,Modal,Box
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
  

    page1card: {
       width: '100%',
       paddingLeft: '20%',
       background: '#1B2636',
       "@media only screen and (max-device-width: 480px)": {
           width: '100%',
           padding: '90px 16px',

       }
   },
   card_tit: {
       color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

       "@media only screen and (max-device-width: 480px)": {
           fontSize: '24px !important',
       },

   },
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },


   step_tit:{
    paddingTop:'3.5vh',  textAlign: 'center',  color: '#FBB72E !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important', //dev-trupti on 14-01-2025
 },
 step_tit1:{
     paddingTop:'1.5vh',  textAlign: 'center',  color: '#FBB72E !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important',
     "@media only screen and (max-device-width: 370px)": {
           fontSize: '14px !important'
     }
  },
  step_tit2_subheading:{
     paddingTop:'1.5vh',  textAlign: 'center',  color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important',
     "@media only screen and (max-device-width: 370px)": {
           fontSize: '14px !important'
     }
  },
  step_tit2:{
    paddingTop:'1.5vh',  color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important',
    "@media only screen and (max-device-width: 370px)": {
        fontSize: '14px !important'
  }
 },  
  step_tit3:{
     paddingTop:'1.5vh',   color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important',
     "@media only screen and (max-device-width: 370px)": {
        fontSize: '14px !important'
  }
  },
  gridPadding:{
    padding:'10px 32px',
    "@media only screen and (max-device-width: 370px)": {
        padding:'7px 32px',
    }
  },
  step_card:{
  background:'#2E3B4D',borderRadius:'6px',height:'120px',display:'flex',justifyContent:'center',alignItems:'center'
  },
   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },

})

class mobile_step extends Component {

    ChangePage(){

        window.Changemytab(6)
        window.ChangePageNo (0)
    }
  render() {
    const { classes } = this.props;
    return (
      <div><Grid  style={{width:'100%',background:'#0B1420',height:'100vh'}} >
      <Grid  style={{width:'100%',background:'#1B2636',height:'100vh',borderTopRightRadius:'43px',borderTopLeftRadius:'43px'}} >
      
      
      
                              <div>
                              <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
         <Grid style={{width:'10%',borderBottom:'5px solid #EBC026',borderRadius:'2px',paddingTop:'5%    '}}>
      
      </Grid>
         </Grid>
      
         <Typography className={classes.step_tit}>Required Documents Information</Typography>
         <Typography className={classes.step_tit2_subheading}>Please keep the following information handy and enjoy fastest registration</Typography>
         
         <Grid className={classes.gridPadding}>
          <div style={{display:'flex'}}>
         <Typography className={classes.step_tit1}>1 {" "}</Typography>
         <Typography className={classes.step_tit2} style={{marginLeft:'10px'}}>Aadhar number</Typography>
         </div>
         </Grid>
      
         <Grid className={classes.gridPadding}>
          <div style={{display:'flex'}}>
         <Typography className={classes.step_tit1}>2 {" "}</Typography>
         <Typography className={classes.step_tit2} style={{marginLeft:'10px'}}>PAN number</Typography>
         </div>
         </Grid>
      
         <Grid className={classes.gridPadding}>
          <div style={{display:'flex'}}>
         <Typography className={classes.step_tit1}>3 {" "}</Typography>
         <Typography className={classes.step_tit2} style={{marginLeft:'10px'}}>Bank account number</Typography>
         </div>
         </Grid>
         <Grid className={classes.gridPadding}>
          <div style={{display:'flex'}}>
         <Typography className={classes.step_tit1}>4 {" "}</Typography>
         <Typography className={classes.step_tit2} style={{marginLeft:'10px'}}>Nominee aadhaar number</Typography>
         </div>
         </Grid>
         <Grid className={classes.gridPadding}>
         <div style={{border:'1px solid #9A9A9A'}}></div> 
         <div style={{display:'flex',justifyContent:'center'}}>
         <Typography style={{color:'#9A9A9A', fontSize:'14px', fontFamily:'cardiuma-M',marginTop:'-10px',backgroundColor:'#1B2636',whiteSpace:'nowrap',position:'absolute',textAlign:'center',padding:'0px 10px' }}>Additional Documents for NRI Investors</Typography>
         </div>
         </Grid>
         <Grid className={classes.gridPadding}>
          <div style={{display:'flex'}}>
         <Typography className={classes.step_tit1}>5 {" "}</Typography>
         <Typography className={classes.step_tit2} style={{marginLeft:'10px'}}>Copy of valid passport with visa</Typography>
         </div>
         </Grid>
         <Grid className={classes.gridPadding}>
          <div style={{display:'flex'}}>
         <Typography className={classes.step_tit1}>6 {" "}</Typography>
         <Typography className={classes.step_tit2} style={{marginLeft:'10px'}}>Proof of foreign address</Typography>
         </div>
         </Grid>
         <Grid className={classes.gridPadding}>
          <div style={{display:'flex'}}>
         <Typography className={classes.step_tit1}>7 {" "}</Typography>
         <Typography className={classes.step_tit3} style={{marginLeft:'10px'}}>Copy of RBI permission / approval  </Typography>
         
         </div>
         <div style={{display:'flex'}}>
         <Typography className={classes.step_tit1}> {"  "}</Typography>
         <Typography className={classes.step_tit3} style={{marginLeft:'12px',marginTop:'-15px'}}>( portfolio investment scheme)</Typography>
         
         </div>
         </Grid>
         
         <Grid style={{position:'absolute',bottom:'0'}}>
      <Grid style={{width:'50%',borderBottom:'5px solid #EBC026',borderRadius:'2px',paddingTop:'5%    '}}>
      
      </Grid>
      </Grid>
      <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
      <Grid style={{width:'90%',display:'flex',justifyContent:'center'}}>
      <Button
                                             
                                              fullWidth className={classes.joinDiplomtsBtn}
                                              style={{ marginTop: '40px', }}
                                              onClick={() => this.ChangePage()}
                                          >Continue
      
                                          </Button>
                                          </Grid>          
                                          </Grid>          
                              </div>
                         
                              
         </Grid>
         {/* <Grid style={{width:'50%',borderBottom:'5px solid #EBC026',borderRadius:'2px',paddingTop:'5%',bottom:0}}>
      
      
      </Grid>  */}
      </Grid></div>
    )
  }
}
export default (withStyles(styles)((mobile_step)));