import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import {signup_funcation} from './signup_funcation'
import { httpRequest } from '../../../ui-utils/api'
import {
    Typography, Grid, Button, TextField, FormControl, FormHelperText, InputAdornment, Popper, Box  , Fade  
} from '@mui/material'
import { inputLabelClasses } from "@mui/material/InputLabel"; //dev-trupti on 13-12-2024
import InfoIcon from '@mui/icons-material/Info';

const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
    page2card: {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            // padding: '52px 16px 0px 16px',
            margin:'52px 16px 0px 16px', //dev-trupti on 13-12-2024
            marginLeft: '0%',

        }
    },
    occ_main_card: {
        width: '100%', padding: '1%', display: 'flex', justifyContent: 'space-evenly', marginTop: '10px',
        "@media only screen and (max-device-width: 480px)": {
            display: 'inline', padding: '0%',
        },
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    occ_card: {
        width: '45%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', marginTop: '15px',
        },

    },
    occ_card1: {
        width: '80%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', marginTop: '15px',
        },

    },
    occ_but: {

        background: "#1B2636 !important",
        padding: "15px 5px !important",
        borderRadius: "8px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        border: '1px solid #DFE0EB !important',
        textTransform: "initial !important",
        fontFamily: "cardiuma-regular !important",

        "&:hover": {
            background: "#F3D45E !important",
            padding: "15px 5px !important",
            borderRadius: "8px !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "14px !important",
            lineHeight: "18px !important",
            color: "#0B1420 !important",
            border: '1px solid #DFE0EB !important',
            textTransform: "initial !important",
            fontFamily: "cardiuma-regular !important",

        },

    },
//dev-trupti on 13-12-2024
    selected:{
        background: "#F3D45E !important",
        padding: "15px 5px !important",
        borderRadius: "8px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#0B1420 !important",
        border: '1px solid #DFE0EB !important',
        textTransform: "initial !important",
        fontFamily: "cardiuma-regular !important",
    }

})

 class annual_Income extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
          
        
            emailid:'',
        
            citizen:0,//I need to get NRI. 
        nri:0,//added on 27/08/24 To cheque NRE and NRO  //dev-trupti on 10-01-2025
            Annual_Income: "",//dev-trupti on 13-12-2024
            net_worth : "",
            errtype:0,
            errorMsg:"",
            anchorEl: null, 
        }



    }
//Added to get dinner right from local hosts, from Local storage. 
  //dev-trupti on 13-12-2024    
  handleMouseEnter = (event) => {
    if (!this.state.anchorEl) {
      this.setState({
        anchorEl: event.currentTarget,
      });
    }
  };

  handleMouseLeave = () => {
    this.setState({
      anchorEl: null,
    });
  };;

  handleClick = (event) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
    });
  };


componentDidMount = async () => {
    let rd1 =  JSON.parse(localStorage.getItem('reg_data') || '{}')
    //added on 27/08/24 to show Annual income in dollar 
    if(rd1.nre===true){
        this.state.citizen=1
        this.setState({  nri:1})
        }
    if(rd1.nro===true){
            this.setState({  nri:1})
        }
        console.log(rd1,"rd1")
    if(rd1.citizen == '1'){ //Added another logic to cheque if the user is a NRI by dev-trupti on 10-01-2025
        this.setState({ nri:1})
    }
    }
            
        

    Annual_Income = async (income) => {

        this.setState({ Annual_Income: income })

//dev-trupti on 13-12-2024
        // let rd1 = localStorage.getItem('reg_data') || '{}'
        // let rd2 = JSON.parse(rd1)
        // let emailid=rd2.emailid
        // let mobileno=rd2.mobileno //added on 30/07/24 Added mobile number. Annual_Income endpoint
    
        // let payload = {
        //     email: emailid,
        //     option: income,
        //     optiontype: 2,
        //     mobileno:mobileno, //added on 30/07/24 Added mobile number. Annual_Income endpoint 
        // }
// signup_funcation(rd1,'Annual_Income',income)
//         try {
//             const stagevalue = await httpRequest({
//                 endPoint: `/api/registration/otheroption`,
//                 method: "post",
//                 instance: "instanceTwo",
//                 requestBody: payload,
//             });
//             if (stagevalue.status === 200) {
//                 window.ChangePageNo (6)
//             }
//         } catch (error) {

//         }
    }

//dev-trupti on 13-12-2024
    send = async () => {
        const {Annual_Income, net_worth} = this.state
        if(Annual_Income === ""){
            this.setState({errtype:1,errorMsg:"Please select Annual Income"})
        }
        else if(net_worth === ""){
            this.setState({errtype:2,errorMsg:"Please enter your Net Worth"})
        }else if(parseInt(net_worth) <= 0 || net_worth === '0' || /^0+/.test(net_worth)){
            this.setState({errtype:2,errorMsg:"Please enter valid net worth"})
        }else
        {
            this.setState({errtype:0,errorMsg:""})
            let netWorth = net_worth.replace(/,/g, '');
            
            //Making the API call

            let rd1 = localStorage.getItem('reg_data') || '{}'
            let rd2 = JSON.parse(rd1)
            let emailid=rd2.emailid
            let mobileno=rd2.mobileno //added on 30/07/24 Added mobile number. Annual_Income endpoint
        
            let payload = {
                email: emailid,
                option: Annual_Income,
                optiontype: 2,
                mobileno:mobileno, //added on 30/07/24 Added mobile number. Annual_Income endpoint 
                net_worth:netWorth
            }
            signup_funcation(rd1,'Annual_Income',Annual_Income)
        try {
            const stagevalue = await httpRequest({
                endPoint: `/api/registration/otheroption`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            if (stagevalue.status === 200) {
                window.ChangePageNo (6)
            }
        } catch (error) {
            console.log(error)
        }
        }
    }


  render() {
    const { classes } = this.props;
    const { Annual_Income, net_worth, anchorEl  } = this.state //dev-trupti on 13-12-2024
    const open = Boolean(anchorEl); 
    const id = open ? 'simple-popper' : undefined;
    return (
      <div>
      <Grid className={classes.page2card} >
          <Typography style={{ color: '#BCD171', fontFamily: 'cardiuma-M', fontSize: '24px',marginLeft:window.innerWidth<600 ? '5px':'20px' }}>Annual Income</Typography>
          <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px', marginTop: '1%',marginLeft:window.innerWidth<600 ? '5px':'20px' }}>Select below option</Typography>
          {this.state.nri===0 && 
          <div>
          <Grid className={classes.occ_main_card} >
              <Grid className={classes.occ_card}>
                {/* dev-trupti on 13-12-2024 */}
                  <Button fullWidth className={Annual_Income === 'Upto 1 Lakh'? classes.selected : classes.occ_but}
                      onClick={() => this.Annual_Income('Upto 1 Lakh')}
                  >
                      Upto 1 Lakh</Button>

              </Grid>
              <Grid className={classes.occ_card}>
                 {/* dev-trupti on 13-12-2024 */}
                  <Button fullWidth className={Annual_Income === '1 Lakh - 5 Lakh'? classes.selected : classes.occ_but}
                      onClick={() => this.Annual_Income('1 Lakh - 5 Lakh')}
                  >
                      1 Lakh - 5 Lakh
                  </Button>

              </Grid>
          </Grid>

          <Grid className={classes.occ_main_card}  >
              <Grid className={classes.occ_card}>
                 {/* dev-trupti on 13-12-2024 */}
                  <Button fullWidth className={Annual_Income === '5 Lakh - 10 Lakh'? classes.selected : classes.occ_but}
                      onClick={() => this.Annual_Income('5 Lakh - 10 Lakh')}
                  >
                      5 Lakh - 10 Lakh
                  </Button>
              </Grid>
              <Grid className={classes.occ_card}>
                 {/* dev-trupti on 13-12-2024 */}
                  <Button fullWidth className={Annual_Income === '10 Lakh - 25 Lakh'? classes.selected : classes.occ_but}
                      onClick={() => this.Annual_Income('10 Lakh - 25 Lakh')}
                  >
                      10 Lakh - 25 Lakh
                  </Button>
              </Grid>
          </Grid>
          <Grid className={classes.occ_main_card}  >
              <Grid className={classes.occ_card}>
                 {/* dev-trupti on 13-12-2024 */}
                  <Button fullWidth className={Annual_Income === '25 Lakh - 50 Lakh'? classes.selected : classes.occ_but}
                      onClick={() => this.Annual_Income('25 Lakh - 50 Lakh')}
                  >
                      25 Lakh - 50 Lakh
                  </Button>
              </Grid>
              <Grid className={classes.occ_card}>
                 {/* dev-trupti on 13-12-2024 */}
                  <Button fullWidth className={Annual_Income === '50 Lakh - 1 Crore'? classes.selected : classes.occ_but}
                      onClick={() => this.Annual_Income('50 Lakh - 1 Crore')}
                  >
                      50 Lakh - 1 Crore
                  </Button>
              </Grid>
          </Grid>
          <Grid className={classes.occ_main_card}  >
              <Grid className={classes.occ_card}>
                 {/* dev-trupti on 13-12-2024 */}
                  <Button fullWidth className={Annual_Income === '1 Crore - 5 Crores'? classes.selected : classes.occ_but}
                      onClick={() => this.Annual_Income('1 Crore - 5 Crores')}
                  >
                      1 Crore - 5 Crores
                  </Button>
              </Grid>
              <Grid className={classes.occ_card}>
                 {/* dev-trupti on 13-12-2024 */}
                  <Button fullWidth className={Annual_Income === 'More than 5 Crores'? classes.selected : classes.occ_but}
                      onClick={() => this.Annual_Income('More than 5 Crores')}
                  >
                      More than 5 Crores
                  </Button>
              </Grid>

          </Grid>
           {/* dev-trupti on 13-12-2024 */}
          {this.state.errtype === 1 &&
                      <FormHelperText className={classes.errtxt} sx={{marginLeft:window.innerWidth<600 ? '5px':'20px'}} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
          </div>
  }

{this.state.nri===1 && 
          <div>
          <Grid className={classes.occ_main_card} >
              <Grid className={classes.occ_card1}>
                  <Button fullWidth className={Annual_Income === '0 - $ 70k'? classes.selected : classes.occ_but} 
                      onClick={() => this.Annual_Income('0 - $ 70k')}>
                        0 - $ 70k</Button>
              </Grid>
              </Grid>
              <Grid className={classes.occ_main_card} >
              <Grid className={classes.occ_card1}>
                  <Button fullWidth className={Annual_Income === '$ 70k - $ 150k'? classes.selected : classes.occ_but} 
                      onClick={() => this.Annual_Income('$ 70k - $ 150k')}
                  >
                     $ 70k - $ 150k 
                  </Button>

              </Grid>
          </Grid>

          <Grid className={classes.occ_main_card}  >
              <Grid className={classes.occ_card1}>
                  <Button fullWidth className={Annual_Income === '$ 150k and above'? classes.selected : classes.occ_but}
                      onClick={() => this.Annual_Income('$ 150k and above')}
                  >
                   $ 150k and above
                  </Button>
              </Grid>
             
          </Grid>
          {this.state.errtype === 1 &&
                      <FormHelperText className={classes.errtxt}  sx={{marginLeft:window.innerWidth<600 ? '5px':'20px'}} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
          </div>
  }
   {/* dev-trupti on 13-12-2024 */}

<Grid 
// sx={{width:window.innerWidth<480 ? '100%': '96%'}}
sx={{marginLeft:window.innerWidth<600 ? '5px':'20px'}}
>
{/* <Typography style={{ color: '#BCD171', fontFamily: 'cardiuma-M', fontSize: window.innerWidth<600 ? '16px':'18px',marginTop:'15px'}}>Please enter your Net Worth</Typography> */}

<Grid style={{ width: '95%', marginLeft: '5px', marginTop: '20px' }}>
        <FormControl style={{ width: '100%' }} variant="standard">
          <TextField
            size="small"
            variant="standard"
            type="tel"
            fullWidth
            sx={{
              '& .MuiInput-input': {
                '-moz-appearance': 'textfield',
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                },
              },
              '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
              '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
              '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
              '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' },
            }}
            InputLabelProps={{
              sx: {
                color: "#FFFFFF",
                fontFamily: 'cardiuma-regular',
                fontSize: '14px',
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#FFFFFF",
                },
              },
            }}
            onInput={(e) => {
              let value = e.target.value.replace(/[^0-9]/g, '');
              e.target.value = value;
            }}
            label="Enter your Net Worth in Number"
            value={net_worth}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  
                >
                  <InfoIcon
                  onClick={this.handleClick} 
                  onMouseEnter={this.handleMouseEnter} 
                  onMouseLeave={this.handleMouseLeave} 
                  sx={{ color: '#BCD171', cursor: 'pointer' }} />
                </InputAdornment>
              ),
              style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' },
            }}
            onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]/g, '');
                value = new Intl.NumberFormat('en-IN').format(value);
                this.setState({ net_worth: value }); 
              }}
          />
          {/* Error message handling */}
          {this.state.errtype === 2 && (
            <FormHelperText className={classes.errtxt} sx={{ marginLeft: window.innerWidth < 600 ? '5px' : '20px' }} id="component-error-text">
              {this.state.errorMsg}
            </FormHelperText>
          )}
        </FormControl>

        {/* Dialog Popup */}
        <Popper id={id} open={open} anchorEl={anchorEl} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={500}>
          <Box
            sx={{
              maxWidth:'310px !important',
              m:2,
              p: 2,
              borderRadius: '8px',
              bgcolor: 'background.paper',
            }}
          >
            <Typography sx={{fontSize:'14px',fontFamily: "cardiuma-regular !important"}}>Net worth is the value of a person or company and can be computed by deducting the total liabilities from the total assets that are owned by the individual/company. If an individual or company owns assets that are greater than liabilities, it is said to show a positive net worth.</Typography>
          </Box>
        </Fade>
      )}
    </Popper>
      </Grid>
                    <Button
                        sx={{
                            marginTop:'25px !important',
                             width:'97%'}}
                        disabled={this.state.bankverifybuttonst}
                        fullWidth className={this.state.bankverifybuttonst ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}

                        onClick={() => this.send()}
                        >Continue
                    </Button>
          </Grid>
          
      </Grid>
    
      <Typography style={{  textAlign: 'center', color: '#1B2636', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with aadhar</Typography>
      </div>
    )
  }
}
export default (withStyles(styles)((annual_Income)));