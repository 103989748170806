
import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import { httpRequest } from '../../../ui-utils/api'
import LoadingButton from '@mui/lab/LoadingButton';
import * as CryptoJS from 'crypto-js';
import { Close } from '@mui/icons-material';
import step_icon from '../../../ui-assets/step_icon.svg'
import validator from 'validator';

import { inputLabelClasses } from "@mui/material/InputLabel";

import step1mp3 from '../../../ui-assets/step_1.mp3'
import verfiy from '../../../ui-assets/verfiy.svg'//added on 14/03/24
import unverfiy from '../../../ui-assets/unverfiy.svg'//added on 14/03/23
import {
    Typography, Grid, InputAdornment, FormControl,
    TextField, Radio, RadioGroup, FormControlLabel, FormHelperText,Checkbox,Snackbar,SnackbarContent,IconButton
} from '@mui/material'
//added on 13/03/24
import OtpInput from "react-otp-input";
//added on 22/03/24
import email_verfication from '../../../ui-assets/otp_verfication.mp3'//added on 22/03/24
import after_verfication from '../../../ui-assets/after_verfication.mp3' //added on 23/03/24
// added on 01/09/24  Dev Srini by ravii
import DocumentMeta from 'react-document-meta'

// function imported - 05/09/24 - Dev Srini
import { publicIpv4} from 'public-ip';

const meta = {
    title: 'Signup | BullForce',
    description: 'Sign up with Bullforce: Create your demat account in minutes. Start trading stocks, bonds, and IPOs on a secure, easy-to-use platform. Join today and invest smartly.',
    canonical: 'https://bullforce.co/signup',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, signup, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}
//end Dev Srini by ravi
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
    formcontrol_width:
    {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%', marginTop: '13%',
        },
    },
    webcamdesk: {
        display: 'flex',
        justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'

        }
    },
    webcammob: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex',
            justifyContent: 'center'

        }
    },
    sigCanvas: {
        background: '#ffffff'
    },
    shutter: {
        marginTop: '50px',
        width: '100%',
        height: '100vh',
        background: 'red',
        borderTopLeftRadius: '8px',
        borderTopRightRaduis: '8px',
    },



};

const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#F74542",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#F74542'
        }
    },
    checked: { color: "#F74542", }
}))(Checkbox);

const styles = theme => ({
    page1card_1: {
        width: '102%',

       //dev-trupti on 05-11-2024
        "@media only screen and (max-device-width: 899px)": {
            width: '100%',
            padding: '80px 0px 0px 0px',

        }
    },
    mobilebar: {
        //dev-trupti on 05-11-2024
        "@media only screen and (max-device-width: 899px)": {
            display: 'flex', width: '100%', padding: '14px', background: '#F3D45E !important', justifyContent: 'center', alignItems: 'center'
        },

    },
    page1card: {
        width: '100%',
        paddingLeft: '20%',
        background: '#1B2636',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '90px 16px',

        }
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',textAlign:'center',
     
        marginBlockStart: 0, marginBlockEnd: 0, fontWeight: 'normal',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',marginTop:'5%'
        },

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    page0titcard: {
        width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px',
        "@media only screen and (max-device-width: 480px)": {
            marginTop: '10px',
            display: 'flex', justifyContent: 'flex-start'
        }
    },
    page0tit: {
        marginTop: '15px', color: '#999999', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
        
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex', justifyContent: 'flex-start', textAlign: 'left',
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },

    //add css for email and sms otp 13/03/24
    email_sub_tit:{
        marginTop: '15px', color: '#DFE0EB', fontFamily: 'cardiuma-regular !important', fontSize: '16px',textAlign:'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex', textAlign: 'left',
        }
    },
    email_edit:{
        color: '#0083CA', fontFamily: 'cardiuma-regular !important', fontSize: '14px',paddingLeft:'10px',textAlign: 'center',   cursor: 'pointer',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex', textAlign: 'left',
        } 
    },
    email_edit1:{
        color: '#0083CA', fontFamily: 'cardiuma-Bold !important', fontSize: '14px',paddingLeft:'10px',textAlign: 'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex', textAlign: 'left',
        } 
    },
    verfy_but: {
        background: "#1B2636 !important",
        padding: "5px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
       marginLeft:'10px',
        color: "#0083CA !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-regular !important"
    },

    verfy_but_disb: {
        background: "#999999 !important",
        padding: "12px 5px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
       
        color: "#0083CA !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-regular !important"
    },
    email_card:{
        width:'45vw',display:'grid',justifyContent:'center',
        "@media only screen and (max-device-width: 480px)": {
            width:'100%',alignItems:'center',paddingTop:'30%'
        } 
    }, //Added styles by dev-trupti on 22-10-2024
    nriMsg:{
         width:'357.03px !important', 
         height:'268px !important', 
         textAlign:'center !important',
         display:'flex !important',
         alignItems:'center !important',
         "@media only screen and (max-device-width: 900px)": {
            width:'100% !important',
        },
        //Added by dev-trupti on 22-10-2024
        "@media only screen and (max-device-width: 340px)": {
            width:'100% !important',
            marginTop:'15px  !important',
        } 
    },
    msgText:{
        color: '#FFFFFF', 
        fontFamily: 'cardiuma-regular !important', //dev-trupti on 22-10-2024
        fontSize: '16px', 
        // marginTop:'8.5% !important', 
        padding:'0px' ,
        "@media only screen and (max-device-width: 900px)": {
            // marginTop:'5% !important', 
            } 
    },
})
let citizen=0
class main extends Component {
    constructor(props) {
        super(props)


        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
            buttonlodder: false,
            emailid: '',
            aadharno: '',
           
            citizenvalue: 'indian',
            refcode: '',
            audio: new Audio(step1mp3),
            pageno:0,//added on 13/03/24
            edit:0,//added on 13/03/24
            emailotp:"",//added on 13/03/24
            mobileotp:"",//added on 13/03/24
            edit1:0,//added on 13/03/24
            v1:false,//added on 13/03/24
            v2:false,//added on 13/03/24
            v3:false,//added on 13/03/24
            v4:false,//added on 13/03/24
            ev:0,//added on 13/03/24
            ev1:0,//added on 13/03/24
            tandcond:true,//added on 21/03/24
            condbut:false,//added on 21/03/24
            otpaudio: new Audio(email_verfication),
            afterverf:new Audio(after_verfication),//added on 23/03/24
            otpautof:false,//added on 11/07/24
            nro:false,
            showNriMsg:0,

           
        }



    }
    ChangeStep() {
        window.Changemytab(5)
        window.ChangePageNo(100)
    }
    async handlePaymentMode1(e) {

        let citizenvalue = e.target.value;

        this.setState({ citizenvalue: e.target.value })

        if (e.target.value === "nri") {
            citizen= 1
            this.setState({showNriMsg:1, errtype:0, errorMsg:""}) // dev-trupti on 22-10-2024
        } else {
             citizen= 0 
             this.setState({showNriMsg:0, errtype:0, errorMsg:""}) // dev-trupti on 22-10-2024
        }

    };
//added in 19/08/24 for nre or nro selecation
    async handleNRI(e) {
        if (e.target.value === "nre") {
            this.setState({ nre: true ,nro:false})
        } else {
            this.setState({ nro: true ,nre:false})
        }

    };//end

    GetMobileNoNumber(e) {


        if (e.target.value.length <= 10)

            this.setState({ mobileno: e.target.value, errtype: 0, errorMsg: "" });

    }

    closeContactModal() {
        this.setState({ showerr: false })
    }
   
    componentDidMount = () => {

        if (this.state.pageno === 0) {
            setTimeout(
                function () {
                    this.state.audio.play()
                }
                    .bind(this),
                1000
            );
        }


        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        this.setState({ refcode: urlParams.get('referral') })

        // post api integrated for user count - 05/09/24 - Dev Srini
        const postVisitorsData = async () => {
            try {
                let ipAddress = await publicIpv4()

                const result = await httpRequest({
                    endPoint: `api/users/count`,
                    method: "post",
                    instance: 'instanceOne',
                    requestBody: {
                        ipAddress,
                        pageName: 'signup',
                    },
                });

            } catch (err) {
                console.log('Error in visitors post API: ', err)
            }
        }

        if (window.location.pathname === '/signup') {
            postVisitorsData()
        }

    }
    
    Continue1 = async () => {
        // added nri msg logic dev-trupti on 22-10-2024
        if(this.state.showNriMsg === 1){
            this.setState({pageno:0, showNriMsg:0, errtype:0, errorMsg:""})
        }else{
        const { mobileno, emailid } = this.state
        // console.log("After", intid)
        //  clearInterval(intid)
        let isok = 0
        let aadharno = ""
        let panno = ""
        let profile_image = ""
        let dob = ""
        let gender = ""
        let father_name = ""
        let aadhar_verify = 0
        localStorage.removeItem('reg_data') //added on 22/07/24 To clear local storage. On every visit 

        if (!emailid.length && isok === 0) {
            console.log("1");
            isok = 1
            this.setState({ errtype: 1, errorMsg: "Enter the Email ID" })


        }

        if (validator.isEmail(emailid) === false && isok === 0) {
            console.log("4");
            isok = 1

            this.setState({ errtype: 1, errorMsg: "Enter valid Email" })

        }
        if (!mobileno.length && isok === 0) {
            console.log("2");
            isok = 1
            this.setState({ errtype: 2, errorMsg: "Enter the Mobile Number" })


        }




        if (mobileno.length !== 10 && isok === 0) {

            isok = 1
            this.setState({ errtype: 2, errorMsg: "Enter valid Mobile Number" })


        }




        if (isok === 0) {
            let adata1 = ''
            if(citizen===0)
            {
                this.setState({ nre: false ,nro:false})
            }
            const { emailid, mobileno, refcode ,nre,nro} = this.state


            let payload =""

            if(citizen===0)
                {
                     payload = {
                        emailid: emailid,
                        mobileno: mobileno,
                        refcode: refcode,
                        citizen:citizen,
                        nre:false,
                        nro:false
                    }
                }else{
                     payload = {
                        emailid: emailid,
                        mobileno: mobileno,
                        refcode: refcode,
                        citizen:citizen,
                        nre:nre,
                        nro:nro
                    }
                }

console.log('payload',payload)

            this.setState({ buttonlodder: true })
            try {
                const stagevalue = await httpRequest({
                    endPoint: `/api/registration/stage`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
                this.setState({ buttonlodder: false })
                if (stagevalue === "API_ERROR") {
                    this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                }
                console.log('stagevalue==', stagevalue)
                if (stagevalue.status === 201) {
                    this.setState({ errtype: 5, errorMsg: stagevalue.message })
                }
                if (stagevalue.status === 202) {
                    this.setState({ errtype: 2, errorMsg: stagevalue.message })
                }
                if (stagevalue.status === 206) {
                    this.setState({ errtype: 1, errorMsg: stagevalue.message })
                }
                if (stagevalue.status === 200) {
                    if (stagevalue.count === 0) {
                     
                        const reg_data = {
                            id:stagevalue.id,
                            emailid: emailid,
                            mobileno: mobileno,
                            stage: 0,
                            citizen: citizen,
                            client_id: '',
                            panverfy: '0',
                            aadharverfy: 0,
                            aadharno: "",
                            upi_id: '',
                            bankaccno: '',
                            ifsc: null,
                            tinvestment: '',
                            Annual_Income: '',
                            Occupation: '',
                            panno: '',
                            fullname: '',
                            dob: '',
                            gender: '',
                            profile_img: '',
                            father_name: '',
                            mobile_verify:'',//addon 14/03/24
                            email_verify:'',//addon 14/03/24
                            skipaa:false,//addon 16/07/24 to skip This variable is added to skip Account aggregator On Chuck upload. 
                            fando:false,//addon 18/07/24 This variable is Added To change. F and o Option in payment Page. 
                            //update on 25/07/2024 fando to false
                            subscription:stagevalue.fee , // updated by dev-trupti on 03-01-2025
                            commodity:false,// added on 29/07/24 To store Exchange details. 
                            currency:false,// added on 29/07/24 To store Exchange details. 
                            equity:false,// added on 29/07/24 To store Exchange details. 
                            nre:false,// added on 09/08/24 To store nri or nro. 
                            nro:false,// added on 09/08/24 To store nri or nro. 
                            aadharupload:0//added on 19/09/24 to upload aadhara in diglocker 
                        }


                        localStorage.removeItem('reg_data')
                        localStorage.setItem('reg_data', JSON.stringify(reg_data))

                       // window.ChangePageNo(1)
                       this.setState({pageno:1})

this.emailverify()
this.mobileverify()


setTimeout(
    function () {
        
        this.state.otpaudio.play()
    }
        .bind(this),
    1000
);
   


                    }

                    if (stagevalue.count !== 0) {

                        let adata = ""

                        let data = JSON.parse(CryptoJS.AES.decrypt(stagevalue.data, "96358214256984120").toString(CryptoJS.enc.Utf8))
                        console.log("data", data)

                        if(data[0].nre===true ||data[0].nro===true)
                        {
                        citizen=1
                        }
                        if(data[0].citizenship =="1"){ //Added by dev-trupti so that user dosnt have to click NRI radio button every time he register (fetching form the db if he is nri) on 10-01-2025
                            citizen=1
                        }

                        let payload = {
                            emailid: emailid,
                            mobileno: mobileno,

                        }

                        console.log('data[0].aadhar_verify', data[0].aadhar_verify)
                        if (data[0].aadhar_verify != null) {
                            aadhar_verify = 1
                            adata = JSON.parse(CryptoJS.AES.decrypt(stagevalue.adata, "96358214256984120").toString(CryptoJS.enc.Utf8))
                            aadharno = CryptoJS.AES.decrypt(data[0].aadharno, "96358214256984120").toString(CryptoJS.enc.Utf8)
                            console.log('aadharno****',adata[0])
                           
                            
                          

                        try {
                            adata1 = JSON.parse(adata[0].data)
                           
                           
                            profile_image = 'data:image/jpeg;base64,' + adata1.data.profile_image
                            
                            dob = adata1.data.dob
                            gender = adata1.data.gender

                            father_name = adata1.data.care_of 
                        } catch (error) {
                            //Added for NRI with no aadhaar. 
                            profile_image=""
                            dob = "NA"
                            gender = "NA"

                            father_name = "NA"
                        }
                        }
                        let panverfy = 0

                        if (data[0].pan_verify === null) {
                            panverfy = 0
                        } else {
                            panverfy = 1

                            panno = CryptoJS.AES.decrypt(data[0].panno, "96358214256984120").toString(CryptoJS.enc.Utf8)
                        }




                        const reg_data = {
                            id:data[0].id,
                            emailid: emailid,
                            mobileno: mobileno,
                            stage: 0,
                            citizen: citizen,
                            client_id: '',
                            panverfy: panverfy,
                            aadharverfy: aadhar_verify,
                            upi_id: data[0].upi_id,
                            bankaccno: data[0].bankaccount,
                            ifsc: data[0].ifsc,
                            tinvestment: data[0].tinvestment,
                            Annual_Income: data[0].annual_income,
                            Occupation: data[0].occupation,
                            aadharno: aadharno,
                            panno: panno,
                            fullname: data[0].full_name,
                            dob: dob,
                            gender: gender,
                            profile_img: profile_image,
                            father_name: father_name,
                            mobile_verify:data[0].mobile_verify,//addon 14/03/24
                            email_verify:data[0].email_verify,//addon 14/03/24
                            skipaa:data[0].skipaa,//addon 16/07/24 To store Account aggregator status from DB. 
                            fando:data[0].fando,//addon 18/07/24 To store FNO status from DB 
                            subscription:stagevalue.fee,  // updated by dev-trupti on 03-01-2025
                            commodity:data[0].commodity,// added on 29/07/24 To store Exchange details. 
                            currency:data[0].currency,// added on 29/07/24 To store Exchange details. 
                            equity:data[0].equity,//
                            nre:data[0].nre,// added on 09/08/24 To store nri or nro. 
                            nro:data[0].nro,
                            aadharupload:0//added on 19/09/24 to upload aadhara in diglocker 
                        }
console.log(reg_data)
                        localStorage.removeItem('reg_data')
                        localStorage.setItem('reg_data', JSON.stringify(reg_data))


                        if (data[0].stage === "completed" || data[0].stage === "completed_bvpending" ) {

                            window.ChangePageNo(16)
                        }
                        if (data[0].stage === "nri") { 
                            window.ChangePageNo(12)    // Divided the stage names for nri dev-trupti on 10-01-2025
                        }

                        if (data[0].stage === "pis_yes"){
                            window.ChangePageNo(7)
                        }

                        if (data[0].stage === "pis_no"){
                            window.ChangePageNo(8)
                        }

                        if (data[0].stage === "docupload") {
                            if (citizen === 0) {
                                //Redirection correction 22-10-2024 by dev-trupti
                                window.ChangePageNo(11.1)
                            } else { window.ChangePageNo(15) }
                        }

                        if (data[0].stage === "nominee") {

                            window.ChangePageNo(14)

                        }
                        if (data[0].stage === "DIS") { //Added Condition for redirection if NRI user breaks the flow
                            if(citizen == "1") {
                                window.ChangePageNo(17.2)
                            }else{
                                window.ChangePageNo(12)
                            }

                        }
                        if (data[0].stage === "signature")
                        {
                         
                            window.ChangePageNo(11)
                        }
                        if (data[0].stage === 'payment'){
                            window.ChangePageNo(11) //Redirection for the new stage added by dev-trupti on 22-01-2025
                        }
                        if (data[0].stage === "exchange") {
                           if (data[0].fando===true && data[0].skipaa===false)
                            {
                                window.ChangePageNo(17.1)
                            }else{
                                window.ChangePageNo(16.1)
                            }
                        }
                        if (data[0].stage === "AA") {
                           
                                window.ChangePageNo(16.1)
                                                    }
                                                
                        

                        if (data[0].stage === "esign OTP") {
                            window.ChangePageNo(11)
                        }
                        if (data[0].stage === "esign-otp") {

                            window.ChangePageNo(11)
                        }

                        if (data[0].stage === "digilocker") {

                            window.ChangePageNo(11.1)
                        }

                       
                        if (data[0].stage === "digilocker_adhar") {

                            window.ChangePageNo(13)
                        }
                        if (data[0].stage === "digilocker_all") {

                            window.ChangePageNo(13.1)
                        }
                      
                        if (data[0].stage === "liveness" || data[0].stage === "esign") {
//liveness D reader for NRI 
                            if(citizen===1)
                            {
                                //If the flow breaks for NRIs redirect to segments - dev-trupti on 26-09-2024
                                window.ChangePageNo(11.1)    
                            }else{
                            window.ChangePageNo(17)}
                        }

                        if (data[0].stage === "Bank_verification" || data[0].stage === "Image_Failed") {
                            window.ChangePageNo(8)
                        }
                        if (data[0].stage === "qualification") {
                            
//Bank redirect for NRI. 
                            if(citizen===1)
                                {
                                    window.ChangePageNo(7.1)    
                                }else{
                                window.ChangePageNo(7)}
                            }

                        
                        if (data[0].stage === "marital_status") {
                            window.ChangePageNo(6.2)
                        }

                        if (data[0].stage === "preferences") {
                            window.ChangePageNo(6.1)
                        }

                        if (data[0].stage === "Annual_Income") {
                            window.ChangePageNo(6)
                        }

                        if (data[0].stage === "Occupation") {
                            window.ChangePageNo(5)
                        }

                        if (data[0].stage === "PAN verified") {
                            window.ChangePageNo(4)
                        }

                        if (data[0].stage === "aadhaar verified") {

                            window.ChangePageNo(3)
                        }
//chk if email and mobile verfication done

                        if (data[0].stage === "OTP_SENT" || data[0].stage === "REG_START") 
                        {

                            if(data[0].mobile_verify==="1" &&  data[0].email_verify==="1")
                            {

                            window.ChangePageNo(1)
                            }
                        else{
                            this.emailverify()
                            this.mobileverify()
                            this.setState({pageno:1})
                            setTimeout(
                                function () {
                                    
                                    this.state.otpaudio.play()
                                }
                                    .bind(this),
                                1000
                            );
                        }
    
                        }
                    

                    }
                }




            } catch (error) {

            }



        }          
    }
    }
//added otp handle funcation
    handleOTPChange = async (e) => {
        const {emailid,mobileno,emailotp,ev,ev1}=this.state
        this.setState({ emailotp:e })
        console.log(e.length)
        if(e.length===4)
        {

            let payload={
                emailid:emailid,
                mobileno:mobileno,
                otp:e
            }
this.setState({v2:true})
            try {
                const emailotp_data = await httpRequest({
                    endPoint: `/api/email/otpvalidate`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
                this.setState({v2:false})
                if(emailotp_data.status===201)
                {
                  

                    this.setState({ ev:3})
                  
                }
                if(emailotp_data.status===200)
                {
                   this.setState({edit:0,ev:1,otpautof:true})
                   let obj={}
                   let rd1 = localStorage.getItem('reg_data') || '{}'
                   let maindata=JSON.parse( rd1)
                   for (var key in maindata) {
               
               let f=0
               if(key==="mobile_verify")
               {
                   obj[key]="1"
                   f=1
               }
               if(key==="email_verify")
               {
                   obj[key]="1"
                   f=1
               }
             
               
               if(f===0)
                {
                       obj[key]=maindata[key]
               }
               
                   }
               
                   localStorage.removeItem('reg_data')
                   localStorage.setItem('reg_data', JSON.stringify(obj))
   
               
                   if( ev1===1)
                   {
                   /* setTimeout(
                        function () {
                            
                            this.state.afterverf.play()
                        }
                            .bind(this),
                        1000
                    );*/
                    setTimeout(function() { //Start the timer
                        window.ChangePageNo(1)
                        this.AccountActive()//added on 21/11/2024 to Activate account after verification. 
                    }.bind(this), 2000)
                   
                   }
                }
                
            } catch (error) {
                
            }
        }
    }

    handleOTPChange1 = async (e) => {
        const {emailid,mobileno,mobileotp,ev,ev1}=this.state
        this.setState({ mobileotp:e })
        console.log(e.length)
        if(e.length===4)
        {

            let payload={
                emailid:emailid,
                mobileno:mobileno,
                otp:e
            }
this.setState({v4:true})
            try {
                const emailotp_data = await httpRequest({
                    endPoint: `/api/mobile/otpvalidate`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
                this.setState({v4:false})
                if(emailotp_data.status===201)
                {
                    

                    if(window.innerWidth<600)
                    {
                        this.setState({ ev1:3})
                    }else{
                        this.setState({ ev1:3})
                        //this.setState({ ev1:3,showerr: true, errorMsg:"Invalid otp", mysnack: snackeror.myerror })     
                    }
                }
                if(emailotp_data.status===200)
                {
                   this.setState({edit1:0,ev1:1})
                   if(ev===1 )
                   {
                    let obj={}
                    let rd1 = localStorage.getItem('reg_data') || '{}'
              
                    let maindata=JSON.parse( rd1)
                    for (var key in maindata) {
                
                let f=0
                if(key==="mobile_verify")
                {
                    obj[key]="1"
                    f=1
                }
                if(key==="email_verify")
                {
                    obj[key]="1"
                    f=1
                }
              
                
                if(f===0)
                 {
                        obj[key]=maindata[key]
                }
                
                    }
                
                    localStorage.removeItem('reg_data')
                    localStorage.setItem('reg_data', JSON.stringify(obj))
                   /* setTimeout(
                        function () {
                            
                            this.state.afterverf.play()
                        }
                            .bind(this),
                        500
                    );*/
                    setTimeout(function() { //Start the timer
                        window.ChangePageNo(1)
                        this.AccountActive()//added on 21/11/2024 to Activate account after verification. 
                    }.bind(this), 4000)
                   
                   }
                }
                
            } catch (error) {
                
            }
        }
    }

    emailverify = async () => {
       
        const {emailid,mobileno}=this.state
        let isok=0
        if (!emailid.length && isok === 0) 
        {
             isok = 1
             this.setState({ errtype: 1, errorMsg: "Enter the E-mail " })
        }
        if (validator.isEmail(emailid) === false && isok === 0) {
            isok = 1
            this.setState({ errtype: 1, errorMsg: "Enter valid Email" })
        }

        if(isok===0)
        {

            let rd1 = localStorage.getItem('reg_data') || '{}'
            let rd2 = JSON.parse(rd1)
let id=rd2.id    

            let payload={
                emailid:emailid.toString().toLowerCase(),//updated on 17/10/2024 Convert. Email to proper case on email edit 
                mobileno:mobileno,
                id:id
            }
            this.setState({v1:true})
        try {
             const emailverify_data = await httpRequest({
                    endPoint: `/api/email/getOTP`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
                this.setState({v1:false})
                if(emailverify_data.status===200)
                {
                    this.setState({edit:0,ev:0})
                   
                }
        } catch (error) {
            
        }
    }
    }
    mobileverify = async () => {
       
        const {emailid,mobileno}=this.state
        let isok=0
        if (!mobileno.length && isok === 0) 
        {
             isok = 1
             this.setState({ errtype: 1, errorMsg: "Enter the Mobile Number" })
        }
        if (mobileno.length!==10 && isok === 0) {
            isok = 1
            this.setState({ errtype: 1, errorMsg: "Enter the Mobile Number" })
        }

        if(isok===0)
        {

            let rd1 = localStorage.getItem('reg_data') || '{}'
            let rd2 = JSON.parse(rd1)
let id=rd2.id    
            let payload={
                emailid:emailid.toString().toLowerCase(),//updated on 17/10/2024 Convert. Email to proper case on email edit 
                mobileno:mobileno,
                id:id
            }
        try {
             const emailverify_data = await httpRequest({
                    endPoint: `/api/mobile/getOTP`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
                if(emailverify_data.status===200)
                {
                    this.setState({edit1:0})
                }
        } catch (error) {
            
        }
    }
    }//end of mobile and email verfication funcation

    AccountActive = async () => {
       
        const {emailid,mobileno}=this.state
        let payload={
            emailid:emailid.toString().toLowerCase(),//updated on 17/10/2024 Convert. Email to proper case on email edit 
            mobileno:mobileno,
        }
        try {
            const emailverify_data = await httpRequest({
                   endPoint: `/api/accountactive`,
                   method: "post",
                   instance: "instanceTwo",
                   requestBody: payload,
               });
       } catch (error) {
        this.setState({ errtype: 1, errorMsg: "Something went wrong try after some time. " })   
       }
      
    }
//authorise eKYC account 
authorise(){
   
    this.setState({ tandcond: !this.state.tandcond ,condbut: !this.state.condbut})
    }//added on 17/09/24 for email and mobile number edit
    ChangeMobileno(e)
    {
        {
            this.setState({ errtype: 0, errorMsg: "", mobileno: e.target.value })
            let obj={}
            let rd1 = localStorage.getItem('reg_data') || '{}'
        
            let maindata=JSON.parse( rd1)
            for (var key in maindata) {
        
        let f=0
        if(key==="mobileno")
        {
            obj[key]= e.target.value
            f=1
        }
        
        
        if(f===0)
         {
                obj[key]=maindata[key]
        }
        
            }
        
            localStorage.removeItem('reg_data')
            localStorage.setItem('reg_data', JSON.stringify(obj))
        }    
    }
    
ChangeEmailid(e)
{
    this.setState({ errtype: 0, errorMsg: "", emailid: e.target.value })
    let obj={}
    let rd1 = localStorage.getItem('reg_data') || '{}'

    let maindata=JSON.parse( rd1)
    for (var key in maindata) {

let f=0
if(key==="emailid")
{
    obj[key]= e.target.value.toString().toLowerCase() //updated on 17/10/2024 Convert. Email to proper case on email edit 
    f=1
}


if(f===0)
 {
        obj[key]=maindata[key]
}

    }

    localStorage.removeItem('reg_data')
    localStorage.setItem('reg_data', JSON.stringify(obj))
}    
    render() {
        const { classes } = this.props;
const {pageno,edit,edit1,ev,ev1, showNriMsg}=this.state // dev-trupti on 22-10-2024
        return (
            <div>
  <DocumentMeta {...meta} />
{pageno===1 && 
  <div className={classes.email_card} >
<div>
<Typography className={classes.card_tit}>Please enter OTP</Typography>
</div>
<div style={{marginTop:'20px'}}>
<Typography className={classes.email_sub_tit}>Please provide us the OTP, Sent your Email ID</Typography>
</div>
{edit===1 && 
    <div>
<div style={{display:'flex',justifyContent:'center',marginTop:'5%',width:'100%'}}>
<div style={{width:'90%',display:'flex',justifyContent:'center'}}> 
<TextField size="small" variant="standard"

label='Enter your Email ID'
fullWidth
value={this.state.emailid}
sx={{
    '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
    '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
    '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
    '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' }

}}
InputLabelProps={{
    sx: {
        // set the color of the label when not shrinked
        color: "#FFFFFF",
        fontFamily: 'cardiuma-regular', fontSize: '14px',
        [`&.${inputLabelClasses.shrink}`]: {
            // set the color of the label when shrinked (usually when the TextField is focused)
            color: "#FFFFFF"
        }
    }
}}
InputProps={{
    classes: { input: classes.input },

    style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },

}}
onChange={(e) =>  this.ChangeEmailid(e)}
></TextField>
</div>
<div style={{width:'25%',display:'flex',justifyContent:'flex-end'}}>
<LoadingButton  
loading={this.state.v1} loadingPosition="start" className={classes.verfy_but} 
                            style={{ marginTop: '5px' }}
                            onClick={() => 
                                {this.emailverify()
                              
                                }
                            }
                        >Verify

                        </LoadingButton>
                        </div>
</div>
</div>
    }
{edit===0 && 
<div>
<div style={{display:'flex',justifyContent:'center',marginTop:'5%'}}>
<Typography className={classes.email_sub_tit}>{this.state.emailid}</Typography>
{ev===0 &&
                        <Typography className={classes.email_edit}
                        
                        onClick={() => this.setState({edit:1})}
                        >Edit</Typography>
    }
</div>
<div style={{display:'flex',justifyContent:'center',marginTop:'5%',width:'100%'}}>
    <div style={{width:'80%',display:'flex',justifyContent:'center'}}> {/* Change it from 65 to 80. To avoid  OTP screen. In mobile web. */}
<OtpInput

    numInputs={4}
    value={this.state.emailotp}
    inputType={window.innerWidth<600 ?  "number":"text"}
    isInputSecure={true}
    shouldAutoFocus={true}
    focusStyle={{
        outline: 'none',
        backgroundColor: 'red !important',
    }}

    inputStyle={{
        backgroundColor: 'transparent',
        width: "35px",
        height: "35px",


        border: "none",
        marginRight: "5px",
        color: "#ffffff ",
        fontSize: "16px",
        fontFamily: "cardiuma-M",
        borderBottom: '1px solid #949A9E',

    }}
    containerStyle={{
        justifyContent: "center",
        borderBottom: '10',

    }}
    renderInput={(props) => <input {...props} />}

    onChange={this.handleOTPChange}

  
/>
</div>
<div style={{width:'2%',display:'flex',justifyContent:'flex-end'}}>
{ev===0 &&
<LoadingButton  loading={this.state.v2} loadingPosition="start" className={classes.verfy_but}
                            style={{ marginTop: '5px'}}
                         
                        >

                        </LoadingButton>
    }

    
                        {ev===1 &&
 
 <img src={verfiy} alt="step_icon" width='30px' height='30px' ></img>
        
    }
      {ev===3 &&
 
 <img src={unverfiy} alt="step_icon" width='30px' height='30px' ></img>
        
    }
                        </div>
</div>
{ev===3 &&
<div style={{display:'flex',justifyContent:'center',marginTop:'5%',width:'100%'}}>
    <Typography className={classes.errtxt}>Invalid OTP </Typography>
    </div>
    }
</div>
    }
<div style={{marginTop:'10%'}}>
<Typography className={classes.email_sub_tit}>Please provide us the OTP, Sent your Phone number</Typography>
</div>

{edit1===1 && 
    <div>
<div style={{display:'flex',justifyContent:'center',marginTop:'5%',width:'100%'}}>
<div style={{width:'90%',display:'flex',justifyContent:'center'}}> 
<TextField size="small" variant="standard"



label='Enter your Mobile Number'
fullWidth

value={this.state.mobileno}
sx={{
    '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
    '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
    '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
    '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' }

}}
InputLabelProps={{
    sx: {
        // set the color of the label when not shrinked
        color: "#FFFFFF",
        fontFamily: 'cardiuma-regular', fontSize: '14px',
        [`&.${inputLabelClasses.shrink}`]: {
            // set the color of the label when shrinked (usually when the TextField is focused)
            color: "#FFFFFF"
        }
    }
}}
InputProps={{
    classes: { input: classes.input },

    style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },

}}
onChange={(e) => this.ChangeMobileno(e)}
></TextField>
</div>
<div style={{width:'10%',display:'flex',justifyContent:'flex-end'}}>
<LoadingButton fullWidth loading={this.state.v1} loadingPosition="start" className={classes.verfy_but}
                            style={{ marginTop: '5px', }}
                            onClick={() =>  {
                                this.mobileverify()
                                }}
                        >Verify

                        </LoadingButton>
                        </div>
</div>
</div>
    }
{edit1===0 && 
<div>
<div style={{display:'flex',justifyContent:'center',marginTop:'5%'}}>
<Typography className={classes.email_sub_tit}>{this.state.mobileno}</Typography>
                        <Typography className={classes.email_edit}
                        
                        onClick={() => this.setState({edit1:1})}
                        >Edit</Typography>
</div>
<div style={{display:'flex',justifyContent:'center',marginTop:'5%',width:'100%'}}>
    <div style={{width:'80%',display:'flex',justifyContent:'center'}}> {/* Change it from 65 to 80. To avoid  OTP screen. In mobile web. */}
<OtpInput

    numInputs={4}
    value={this.state.mobileotp}
    inputType={window.innerWidth<600 ?  "number":"text"}
    isInputSecure={true}
    shouldAutoFocus={this.state.otpautof}
    focusStyle={{
        outline: 'none',
        backgroundColor: 'red !important',
    }}

    inputStyle={{
        backgroundColor: 'transparent',
        width: "35px",
        height: "35px",


        border: "none",
        marginRight: "5px",
        color: "#ffffff ",
        fontSize: "16px",
        fontFamily: "cardiuma-M",
        borderBottom: '1px solid #949A9E',

    }}
    containerStyle={{
        justifyContent: "center",
        borderBottom: '10',

    }}
    renderInput={(props) => <input {...props} />}

    onChange={this.handleOTPChange1}

  
/>
</div>
<div style={{width:'2%',display:'flex',justifyContent:'flex-end'}}>
    {ev1===0 &&
<LoadingButton  loading={this.state.v4} loadingPosition="start" className={classes.verfy_but}
                            style={{ marginTop: '5px', }}
                         
                        >

                        </LoadingButton>
    }
    {ev1===1 &&
        <img src={verfiy}  width='30px' height='30px'  alt="step_icon" ></img>
                         


    }
     {ev1>=2 &&
        <img src={unverfiy}  width='30px' height='30px'  alt="step_icon" ></img>
                         


    }
                        </div>
                      
</div>

{ev1===3 &&
<div style={{display:'flex',justifyContent:'center',marginTop:'5%',width:'100%'}}>
    <Typography className={classes.errtxt}>Invalid OTP </Typography>
    </div>
    }
</div>
    }
    </div>

    }                
{pageno===0 && 
                <Grid className={classes.page1card_1}>
                    {/* dev-trupti on 05-11-2024 */}
                    {window.innerWidth< 900 && 
                    <Grid className={classes.mobilebar}
                        onClick={() => this.ChangeStep()}
                    >
                        <img src={step_icon} alt="step_icon" style={{ width: '16px',height:'16px', marginTop: '-2px' }}></img>
                        <Typography style={{ paddingLeft: '5px', color: '#1B2636', fontSize: '14px', fontFamily: 'cardiuma-M' }}>Account opening steps / Required Documents</Typography>
                    </Grid>
                    }
                    <Grid className={classes.page1card} style={{ padding: '20px 16px' }}>
                        {/* <Typography className={classes.card_tit}>Sign-up now</Typography> */}
                         {/* mui typography replaced with html h1 tag - 27/08/24 - Dev Srini */}
                         <h1 className={classes.card_tit}>Sign-up now</h1>
                        <Grid style={{ marginTop: '2%', display: 'flex' }}>
                            <RadioGroup
                                row
                                defaultValue="upi"
                                value={this.state.citizenvalue}


                                onChange={e =>
                                    this.handlePaymentMode1(e)


                                }
                            >
                                <FormControlLabel value="indian" control={<Radio
                                    disableripple
                                    size='small'
                                    sx={{
                                        color: "#999999",
                                        "&.Mui-checked": {
                                            position: "relative",
                                            "&::before": {
                                                content: '""',
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50%",
                                            },
                                            "&::after": {
                                                content: '""',
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50%",
                                                backgroundColor: "#F74542",

                                                borderColor: "#ffffff",
                                                color: "yellow"
                                            },

                                            '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                            {
                                                stroke: '#ffffff',
                                                strokeWidth: 0.5,
                                            },
                                        }

                                    }}
                                />} label={<Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Resident-Indian</Typography>} />
                                <FormControlLabel value="nri" control={<Radio

                                    size='small'
                                    sx={{
                                        color: "#999999",
                                        "&.Mui-checked": {
                                            position: "relative",
                                            "&::before": {
                                                content: '""',
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50%",
                                                color: "yellow"
                                            },
                                            "&::after": {
                                                content: '""',
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50%",
                                                backgroundColor: "#F74542",

                                                borderColor: "#ffffff",
                                                color: "yellow"
                                            },

                                            '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                            {
                                                stroke: '#ffffff',
                                                strokeWidth: 0.5,
                                            },
                                        }

                                    }}
                                />} label={<Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Non-Resident-Indian</Typography>} />







                            </RadioGroup>
                        </Grid>
                        {/* added in 19/08/24 for nri and nro */}
                       
    {/* end of nri nor */}
                       
                        {/* swapped input field position - 02/09/24 - Dev Srini  */}
                        {/* Added nri msg by dev-trpti on 22-10-2024 */}
                        { showNriMsg === 1 ? <Grid className={classes.nriMsg} > 
                                <Typography className={classes.msgText}>
                                I, the subscriber of Bullforce from the USA (or any other country), affirm that my subscription is entirely out of my own interest and decision. I confirm that I have not been solicited by any person or organization in the USA (or any other country) to subscribe to Bullforce. This decision is based on my independent assessment and voluntary choice.
                                </Typography>
                        </Grid> : <Grid>
                            <Grid style={{ marginTop: '2%' }}>

                                <Grid style={{ width: '100%', marginLeft: '5px', marginTop: '20px' }}>
                                    <FormControl style={{ width: '100%' }} variant="standard">
                                        <TextField size="small" variant="standard"



                                            label='Enter your Email ID'
                                            fullWidth

                                            value={this.state.emailid}
                                            sx={{
                                                '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                                                '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                                '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                                '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' }

                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    // set the color of the label when not shrinked
                                                    color: "#FFFFFF",
                                                    fontFamily: 'cardiuma-regular', fontSize: '14px',
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        // set the color of the label when shrinked (usually when the TextField is focused)
                                                        color: "#FFFFFF"
                                                    }
                                                }
                                            }}
                                            InputProps={{
                                                classes: { input: classes.input },

                                                style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },

                                            }}
                                            onChange={(e) => this.setState({ errtype: 0, errorMsg: "", emailid: e.target.value.toLowerCase() })}
                                        ></TextField>
                                        {this.state.errtype === 1 &&
                                            <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>


                                </Grid>

                                <Grid >

                                <Grid style={{ width: '100%', marginLeft: '5px', marginTop: '30px' }}>
                                    <FormControl style={{ width: '100%' }} variant="standard">
                                        <TextField size="small" variant="standard"
                                            fullWidth
                                            type='number'
                                            label='Enter your phone number'
                                            value={this.state.mobileno}
                                            sx={{
                                                '& .MuiInput-input': {
                                                    '-moz-appearance': 'textfield',
                                                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                                        '-webkit-appearance': 'none',

                                                    }
                                                },
                                                '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                                                '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                                '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                                '& .MuiInputAdornment-root': {

                                                    color: '#ffffff',

                                                },
                                                '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' }
                                            }}

                                            InputLabelProps={{
                                                sx: {

                                                    color: "#FFFFFF",
                                                    fontFamily: 'cardiuma-regular', fontSize: '14px',
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        // set the color of the label when shrinked (usually when the TextField is focused)
                                                        color: "#FFFFFF"
                                                    }
                                                }
                                            }}
                                            InputProps={{
                                                classes: { input: classes.input },
                                                maxLength: 10,
                                                startAdornment: <InputAdornment position="start"


                                                >
                                                    <Typography style={{ color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '16px' }}>
                                                        +91
                                                    </Typography>
                                                </InputAdornment>,
                                                style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '16px', height: '30px' }
                                            }}
                                            onChange={(e) => this.GetMobileNoNumber(e)}
                                        ></TextField>
                                        {this.state.errtype === 2 &&
                                            <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                        }
                                    </FormControl>


                                </Grid>

                                </Grid>

                                <Grid className={classes.page0titcard}>
                                <Typography className={classes.page0tit} >Make sure this phone number is linked with Aadhar</Typography>
                                </Grid>

                                <Grid style={{ marginTop: '2%' }}>

                                    <Grid style={{ width: '100%', marginLeft: '5px', marginTop: '20px' }}>
                                        <FormControl style={{ width: '100%' }} variant="standard">
                                            <TextField size="small" variant="standard"



                                                label='Enter referral code'
                                                placeholder='Enter referral code if you have'
                                                fullWidth

                                                value={this.state.refcode}
                                                sx={{
                                                    '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                                                    '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                                    '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                                    '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' }


                                                }}

                                                InputLabelProps={{
                                                    sx: {
                                                        // set the color of the label when not shrinked
                                                        color: "#FFFFFF",
                                                        fontFamily: 'cardiuma-regular', fontSize: '14px',
                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                            // set the color of the label when shrinked (usually when the TextField is focused)
                                                            color: "#FFFFFF"
                                                        }
                                                    }


                                                }}
                                                InputProps={{
                                                    classes: { input: classes.input },

                                                    style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', }

                                                }}

                                                onChange={(e) => this.setState({refcode:e.target.value})}
                                            ></TextField>
                                            {this.state.errtype === 5 &&
                                                <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid style={{ marginTop: '2%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <WhiteBackgroundCheckbox
                                                        checked={this.state.tandcond}
                                                        onChange={() => this.authorise()}
                                                    >
                                                    </WhiteBackgroundCheckbox>
                                                    <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '16px' }}>I authorise to open eKYC account </Typography>
                                                

                                </Grid>
                        </Grid>}
                                            <Grid style={{ marginTop: '2%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <LoadingButton fullWidth
                        
                        disabled={this.state.condbut}
                        loading={this.state.buttonlodder} loadingPosition="start" className={this.state.condbut? classes.joinDiplomtsBtn_disable: classes.joinDiplomtsBtn}
                            style={{ marginTop: '20px', }}
                            onClick={() => this.Continue1()}
                            //dev-trupti on 22-10-2024
                        >{showNriMsg === 1 ? "I Agree and Continue" : "Continue"}  

                        </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>

            }
                <Snackbar
                    anchorOrigin={{
                        vertical: window. innerWidth >600 ?"bottom":"top",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div>
        )
    }
}
export default (withStyles(styles)((main)));