import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import stepconmp3 from "../../../ui-assets/step_con.mp3";
import cong_box from "../../../ui-assets/Congratulation_Box.png";
import ani_gif from "../../../ui-assets/gif_particles.gif";

// File added by dev-trupti on 12-11-2024
import { Typography, Grid, Button, Modal, Box ,Zoom} from "@mui/material";
let count = 0  //dev-trupti on 03-01-2025
const snackeror = {
  myerror: {
    backgroundColor: "#ffffff",
    borderLeft: "8px solid #b60000",
    flexWrap: "nowrap",
    color: "#005100",
    borderTop: "1px solid #b60000",
    borderRight: "1px solid #b60000",
    borderBottom: "1px solid #b60000",
  },
  mysucc: {
    backgroundColor: "#ffffff",
    borderLeft: "8px solid #8db600",
    flexWrap: "nowrap",
    color: "#005100",
    borderTop: "1px solid #8db600",
    borderRight: "1px solid #8db600",
    borderBottom: "1px solid #8db600",
  },
  formcontrol_width: {
    width: "100%",
    "@media only screen and (max-device-width: 480px)": {
      width: "90%",
      marginTop: "13%",
    },
  },
  webcamdesk: {
    display: "flex",
    justifyContent: "center",
    "@media only screen and (max-device-width: 480px)": {
      display: "none",
    },
  },
  webcammob: {
    display: "none",
    "@media only screen and (max-device-width: 480px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  sigCanvas: {
    background: "#ffffff",
  },
  shutter: {
    marginTop: "50px",
    width: "100%",
    height: "100vh",
    background: "red",
    borderTopLeftRadius: "8px",
    borderTopRightRaduis: "8px",
  },
};
const styles = (theme) => ({
  page1card: {
    width: "100%",
    paddingLeft: "20%",
    background: "#1B2636",
    "@media only screen and (max-device-width: 480px)": {
      width: "100%",
      padding: "90px 16px",
    },
  },
  card_tit: {
    color: "#BCD171",
    fontFamily: "cardiuma-M !important",
    fontSize: "28px !important",

    "@media only screen and (max-device-width: 480px)": {
      fontSize: "24px !important",
    },
  },
  errtxt: {
    color: "#F74542 !important",
    fontFamily: "cardiuma-regular !important",
    fontSize: "12px !important",
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
      WebkitTextFillColor: "#ffffff",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  cong_card: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "auto",

    outline: "none",
    p: 4,
    "@media only screen and (max-device-width: 480px)": {
      top: "12%", //dev-trupti on 03-01-2025
      left: "0%",
      transform: "none",
    },
  },
  cong_tit1: {
    textAlign: "center",
    color: "#ffffff",
    fontFamily: "cardiuma-Bold !important",
    fontSize: "30px !important",
    "@media only screen and (max-device-width: 480px)": {
      fontSize: "30px !important",
    },
  },
  cong_tit2: {
    textAlign: "center",
    color: "#ffffff !important",
    fontFamily: "cardiuma-regular !important",
    fontSize: "16px !important",
    // width: "50%",
    fontWeight: "400",
    "@media only screen and (max-device-width: 480px)": {
      fontSize: "14px !important",  //dev-trupti on 03-01-2025
      marginTop: "10%",
      padding: "0px 20px 0px 20px",
      width:'auto',
    //   width: "70%",
    },
  },
  cong_tit3: {
    textAlign: "center",
    color: "#ffffff !important",
    fontFamily: "cardiuma-regular !important",
    fontSize: "16px !important",
    width: "550px",
    fontWeight: "400",
    padding: '0px 20px 0px 20px',
    "@media only screen and (max-device-width: 480px)": {
      fontSize: "14px !important",
      marginTop: "10%",
      padding: "0px 30px 0px 30px",
      width:'318px',
    //   width: "70%",
    },
  },
  cong_card1: {
    marginTop: "2%",
    width: "100%",
    display: "flex",
    justifyContent: "center",

    "@media only screen and (max-device-width: 480px)": {
      display: "none",
    },
  },
  cong_card2: {
    display: "none",
    "@media only screen and (max-device-width: 480px)": {
      marginTop: "2%",
      width: "100%",
      display: "inline",
    },
  },

  cong_card3: {
    width: "100%",
    marginTop: "1%",
    display: "flex",
    flexDirection:'column !important',  //dev-trupti on 03-01-2025
    justifyContent: "center",
    "@media only screen and (max-device-width: 480px)": {
      width: "100%",
      marginTop: "3%",
    },
  },
 //dev-trupti on 03-01-2025
  cong_card4:{
    display:'flex',
    justifyContent:'center',
  },

  cong_card5:{
    display:'flex',
    justifyContent:'center',
    "@media only screen and (max-device-width: 480px)": {
      display:'none'
    },
  },
  imageStyle:{
    position: "relative !important", 
    // width:'308px !important', 
    // height:'184px !important',
    height:'140px !important',  //dev-trupti on 03-01-2025
    "@media only screen and (max-device-width: 540px)": {
      height:'150px !important',
    },
  },
  freeMsg:{
    fontFamily: "cardiuma-Bold !important",
    fontSize:'24px !important',  //dev-trupti on 03-01-2025
    textAlign:'center !important',
    position: "absolute !important", 
    padding: '0px 10px 0px 10px',  //dev-trupti on 03-01-2025
    background: 'linear-gradient(90deg, #FFEF16 6.09%, #CE7500 94.63%)',
    '-webkit-background-clip': 'text',
    color: 'transparent',
    "@media only screen and (max-device-width: 540px)": {
        padding: '35px 20px 0px 20px',
        fontSize:'20px !important',
    },
    "@media only screen and (max-device-width: 400px)": {
        fontSize:'5.5vw !important',  //dev-trupti on 03-01-2025
    },
  }
});
const beforeUnloadHandler = (event) => {
  // Recommended
  window.open("/");
  event.preventDefault();
  console.log("Clear navigation history. ");
  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true;
};


class congratulations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      audio_stepcon: new Audio(stepconmp3),
      showang: 1,
      showText: 0,
      subAmount:0,  //dev-trupti on 03-01-2025
      expert_days:-1,
      wealth_days:-1,
      num:0,
      fullname:''
    };
  }

  componentDidMount = async () => {
    let rd1 = localStorage.getItem('reg_data') || '{}'
    let rd2 = JSON.parse(rd1)
    const subAmt = rd2.subscription.subscription.amount_per_year || 499  //dev-trupti on 03-01-2025
    const expDays = rd2.subscription.expert_advice?.free_days || -1
    const wvDays = rd2.subscription.Wealth_valut?.free_days || -1
    if(subAmt == 0){
      count++
    }if(expDays>-1){
      count++
    }if(wvDays>-1){
      count++
    }
    this.setState({subAmount:subAmt,expert_days:expDays, wealth_days:wvDays, num:count, fullname:rd2.fullname})

    window.addEventListener("beforeunload", beforeUnloadHandler);
    setTimeout(
      function () {
        this.state.audio_stepcon.play();
      }.bind(this),
      1000
    );
    setTimeout(
      function () {
        this.setState({ showang: 0 });
        this.setState({showText: 1})
      }.bind(this),
      1500
    );
  };

  render() {
    const { classes } = this.props;
    const { subAmount, expert_days, wealth_days, num, fullname} = this.state  //dev-trupti on 03-01-2025
    return (
      <div className={classes.cong_card}>
        <Grid
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            bottom: 0,
            height:'375px',
            alignItems:'end'
          }}
        >
          <img
            className={classes.imageStyle}
            style={{ }}
            src={cong_box}
            alt="congbull"
          ></img>
          {this.state.showang === 1 && (
            <img
              style={{ position: "absolute", top: 0, height:'300px', width:'320px'}}
              src={ani_gif}
              alt="congbull"
            ></img>
          )}
          {this.state.showText === 1 && (
            <Zoom in = {true} >
                <Typography className={classes.freeMsg}  //dev-trupti on 03-01-2025
                sx={{top:window.innerWidth <480? (num===3? '40px':(num===2? '60px' : (num===1? '80px' : '100px'))):num===3? '70px':(num===2? '125px' : (num===1? '150px' : '120px'))}}>
                  You have unlocked <br/>
                  {subAmount == 0 ? (<span style={{fontSize: window.innerWidth <480? (num===1? '3.5vh':null):null }}>{"Free Account"}<br/></span>) : null}
                  {expert_days > -1 && ( <>{`${expert_days} days of Free Expert Recommendation`}<br/></>)}
                  {wealth_days > -1 && ( <>{`${wealth_days} days of Free Wealth Vault`}</>)}
                </Typography>
            </Zoom>
          )}
        </Grid>
        <Grid style={{ width: "100%" }}>
          <Grid style={{ width: "100%", marginTop: "1%" }}>
            <Typography className={classes.cong_tit1}>
              Congratulations!
            </Typography>
          </Grid>
          <Grid className={classes.cong_card3}>
            <Grid className={classes.cong_card4}>
            <Typography className={classes.cong_tit3}> 
            We are excited to have you <span style={{fontWeight:'900'}}>{fullname}</span>.
            </Typography>
            </Grid>
            <Typography className={classes.cong_tit2}>
            Your account opening is under process.
            </Typography>
            <Grid className={classes.cong_card4}>
            <Typography className={classes.cong_tit3}> 
            You will receive an email with your login ID and Password within 24hrs to access our trading platform.
            </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(congratulations);
