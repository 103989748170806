
import { useEffect, useState } from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import './products.css'
import { httpRequest } from '../../ui-utils/api'
import Footer from '../landingpage/fotter'


// Components
import MobileView from './MobileView'
import WebView from './WebView'

const Subscription = () => {

    const isSmallScreen = useMediaQuery('(max-width:960px)')

    const [price, setPrice] = useState([])
    {/* Updated by dev-srini on 03-01-2025 */}
    const [subscription, setSubscription] = useState({})
    const [wealthValut, setWealthValut] = useState({})
    const [expertAdvice, setExpertAdvice] = useState({})
    // Subscription price API integrated by dev-srini on 31-10-2024
    useEffect(() => {
        const productsPrice = async() => {
            try{
                const result = await httpRequest({
                    endPoint: 'api/subscription',
                    method: 'get',
                    instance: 'instanceTwo'
                })
                
                if (result.status === 200) {
                    setPrice(result.Subscription)
                    {/* Updated by dev-srini on 03-01-2025 */}
                    setSubscription(result.Subscription.find(item => item.serivce === 'Subscription'))
                    setWealthValut(result.Subscription.find(item => item.serivce === 'wealth_vault'))
                    setExpertAdvice(result.Subscription.find(item => item.serivce === 'Expert Advice'))
                   
                }
                else{
                    console.log('Error in Subscription API', result)
                }
            }
            catch(error){
                console.log('Error in Subscription API', error)
            }
        }

        productsPrice()
    }, [])

    return (
        <>
        <Grid>
            {/* Updated by dev-srini on 03-01-2025 */}
            {isSmallScreen ? <MobileView subscription={subscription} wealthValut={wealthValut} expertAdvice={expertAdvice}  /> : <WebView subscription={subscription} wealthValut={wealthValut} expertAdvice={expertAdvice} />}
        </Grid>
        
        {/* added by dev-Ayush 16-12-2024 */}
        <Grid style={{ width: '100%',background:'#0B1420' }}>
            <Footer/>
        </Grid>
        </>

    )
}

export default Subscription