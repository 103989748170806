import { Divider, Grid, Card, CardMedia, CardContent, Typography, CardActionArea, useMediaQuery } from '@mui/material'
import { useHistory } from 'react-router-dom'

import imageOne from '../../ui-assets/blog_art4.webp'
import imageTwo from '../../ui-assets/blog_art3.webp'
import imageThree from '../../ui-assets/blog_art6.webp'
import imageFour from '../../ui-assets/blog_art8b.webp'
import imageFive from '../../ui-assets/blog_art9b.webp' // srini - 22-01-2025

function slugify(title) {
    return title
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
}

const BlogListing = () => {

    const isSmallScreen = useMediaQuery('(max-width:900px)')

    const blogs = [
        {
            id: 1,
            title: 'BullForce Registration Process: Open your account in Just 6 Minutes!',
            date: 'October 23, 2024',
            image: imageOne,
        },
        {
            id: 2,
            title: '25 Years of Excellence: How BullForce Redefines Client Experience in Broking',
            date: 'October 28, 2024',
            image: imageTwo,
        },
        {
            id: 3,
            title: 'Navigating the Indian Stock Market: Essential Tips for New Investors',
            date: 'November 13, 2024',
            image: imageThree,
        },
        {
            id: 4,
            title: 'Simplify Investing with BullForce’s Comprehensive Financial Calculators!',
            date: 'November 17, 2024',
            image: imageFour,
        },
        // start, new card, srini - 22-01-2025
        {
            id: 5,
            title: 'Bullforce: The Best Online trading platform in India for Beginners to Kickstart Their Investment Journey',
            date: 'January 03, 2025',
            image: imageFive,
        },
        // end, srini - 22-01-2025
    ]

    const history = useHistory()

    
    const handleCardClick = (blog) => {
        const blogSlug = slugify(blog.title)
        history.push(`blogs/${blogSlug}/${blog.id}`)
        window.scrollTo(0, 0)
    }


    return (
        <Grid container justifyContent="center" alignItems="center">
            {/*start, scroll issue, excessive gap, srini - 22-01-2025  */}
            <Grid container spacing={4} mt="10px" sx={{ width: isSmallScreen ? "95%" : "80%", maxWidth: "1400px" }} justifyContent="center" alignItems="center">
                {blogs.map((blog) => (
                    <Grid item xs={12} sm={12} md={6} lg={6} key={blog.id}>
                        <Card className="wblgcrd" sx={{ height: '100%', display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
                            <CardActionArea onClick={() => handleCardClick(blog)} sx={{ flexGrow: 1 }}>
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    image={blog.image}
                                    alt={blog.title}
                                    className="wblgcrdimg"
                                />
                                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <h1
                                        className="wblgcrdtit"
                                        style={{
                                            marginBlockStart: '0px',
                                            marginBlockEnd: '10px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        {/* end, srini - 22-01-2025 */}
                                        {blog.title}
                                    </h1>
                                    <Grid container alignItems="center">
                                        <Typography className="wblgnrtxt">10 Min read</Typography>
                                        <Divider orientation="vertical" flexItem sx={{ mx: 1, borderColor: '#999', borderWidth: '1px' }} />
                                        <Typography className="wblgnrtxt">{blog.date}</Typography>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default BlogListing