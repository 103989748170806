import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import {signup_funcation} from './signup_funcation'
import { httpRequest } from '../../../ui-utils/api'

import {
    Typography, Grid, Button
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
    page3card: {
        width: '100%',
   
        background: '#1B2636',


        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '52px 16px',
            marginLeft: '0%',

        }
    },
    page3card_1: {
        marginTop: '2%', width: '90%', padding: '1%', display: 'flex', justifyContent: 'space-evenly',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            display: 'inline', padding: '0%',
            marginLeft: '0%',

        }
    },
 
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    occ_card: {
        width: '45%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', marginTop: '15px',
        },

    },

    occ_but: {

        background: "#1B2636 !important",
        padding: "15px 5px !important",
        borderRadius: "8px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        border: '1px solid #DFE0EB !important',
        textTransform: "initial !important",
        fontFamily: "cardiuma-regular !important",

        "&:hover": {
            background: "#F3D45E !important",
            padding: "15px 5px !important",
            borderRadius: "8px !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "14px !important",
            lineHeight: "18px !important",
            color: "#0B1420 !important",
            border: '1px solid #DFE0EB !important',
            textTransform: "initial !important",
            fontFamily: "cardiuma-regular !important",

        },

    },

})

 class preferences extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
          
        
            emailid:'',
        
           
        
            Annual_Income: true,
        }



    }

    tinvestment = async (income) => {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
        let mobileno=rd2.mobileno //added on 30/07/24 Added mobile number. preferences endpoint 
        this.setState({ tinvestment: income })
        let payload = {
            email: emailid,
            option: income,
            optiontype: 3,
            mobileno:mobileno //added on 30/07/24 Added mobile number. preferences endpoint 

        }
        signup_funcation(rd1,'tinvestment',income)
        try {
            const stagevalue = await httpRequest({
                endPoint: `/api/registration/otheroption`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            if (stagevalue.status === 200) {
                window.ChangePageNo (6.1)
            }
        } catch (error) {

        }
    }

  
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid className={classes.page3card} >
                                    <Typography style={{ width: '100%', color: '#BCD171', fontFamily: 'cardiuma-M', fontSize: '24px',marginLeft:'20px' }}>Trading & Investment experiences</Typography>
                                    <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px', marginTop: '1%',marginLeft:'20px' }}>Select below option</Typography>

                                    <Grid className={classes.page3card_1} >
                                        <Grid className={classes.occ_card}>
                                            <Button fullWidth className={classes.occ_but}
                                                onClick={() => this.tinvestment('0 Years')}
                                            >
                                                0 Year
                                            </Button>
                                        </Grid>
                                        <Grid className={classes.occ_card}>
                                            <Button fullWidth className={classes.occ_but}
                                                onClick={() => this.tinvestment('1 Year')} //dev-trupti on 13-12-2024 
                                            >
                                                1 Year
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid className={classes.page3card_1} >
                                        <Grid className={classes.occ_card}>
                                            <Button fullWidth className={classes.occ_but}
                                                onClick={() => this.tinvestment('2 Years')}
                                            >
                                                2 Years
                                            </Button>
                                        </Grid>
                                        <Grid className={classes.occ_card}>
                                            <Button fullWidth className={classes.occ_but}
                                                onClick={() => this.tinvestment('3 Years')}
                                            >
                                                3 Years
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.page3card_1} >
                                        <Grid className={classes.occ_card}>
                                            <Button fullWidth className={classes.occ_but}
                                                onClick={() => this.tinvestment('4 Years')}
                                            >
                                                4 Years
                                            </Button>
                                        </Grid>
                                        <Grid className={classes.occ_card}>
                                            <Button fullWidth className={classes.occ_but}
                                                onClick={() => this.tinvestment('5 Years')}
                                            >
                                                5 Years
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.page3card_1}>
                                        <Grid className={classes.occ_card}>
                                            <Button fullWidth className={classes.occ_but}
                                                onClick={() => this.tinvestment('5 Years-10 Years')}
                                            >
                                                5 Years-10 Years
                                            </Button>
                                        </Grid>
                                        <Grid className={classes.occ_card}>
                                            <Button fullWidth className={classes.occ_but}
                                                onClick={() => this.tinvestment('10 Years and more')}
                                            >
                                                10 Years and more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
      <Typography style={{  textAlign: 'center', color: '#1B2636', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with   phone number linked w aadhar</Typography>
      </div>
    )
  }
}
export default (withStyles(styles)((preferences)));