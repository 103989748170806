import { Box, Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material'

// images
import icon from '../../ui-assets/subscriptionOne.js.svg'
import icon1 from '../../ui-assets/subscriptionTwo.js.svg'

import backendHosts from '../../ui-utils/apiConfig';

{/* Updated by dev-srini on 03-01-2025 */}
const WebView = ({ expertAdvice, wealthValut, subscription }) => {
    const is1449px = useMediaQuery('(min-width:1151px) and (max-width:1449px)')

    return (
        <Grid className='wbgimge' sx={{ minHeight: '100vh' }}>
            <Grid container >
                <Grid item xs={12} padding={2} mt='80px'>
                    <Typography align="center" className='worprod'>
                        Our Products
                    </Typography>

                    <Typography align="center" className='wnwerat'>
                        A new era of trading experience with Bullforce
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent='space-evenly' mt='30px'>
                <Grid item >
                    <Box className="wcrdbox1">
                        <Grid container justifyContent="space-between" alignItems="end"  mt="50px">
                            <Grid item >
                                {/* Updated by dev-srini on 03-01-2025 */}
                                <Typography className="wdmtacc" align="left" ml="25px" >
                                    {subscription?.serivce}
                                </Typography>
                            </Grid>

                            <Grid item>
                                {/* Updated by dev-srini on 03-01-2025 */}
                                <Typography className="wfr9t" align="right" mr="25px">
                                    ₹ {subscription?.amount} <span className="wyert"> / Year </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="10px">
                            <Grid item>
                                <Divider sx={{ background: '#fff', width: is1449px ? '306px' : '406px', height: '1px' }} />
                            </Grid>
                        </Grid>
                        {/* First Plan */}
                        {/* Updated by dev-srini on 03-01-2025 */}
                        <Grid container justifyContent="flex-start" alignItems="center" gap={2} mt="30px"
                            ml={is1449px ? "20px" : "35px"}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={is1449px && 30} />
                            </Grid>
                            <Grid item>
                                <Typography className="wenergt">
                                    25+ years, trusted by investors.
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Second Plan */}
                        {/* Updated by dev-srini on 03-01-2025 */}
                        <Grid container justifyContent="flex-start" alignItems="center" gap={2} mt="10px"
                            ml={is1449px ? "20px" : "35px"}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={is1449px && 30} />
                            </Grid>
                            <Grid item>
                                <Typography className="wenergt" mr='60px'>
                                    Unlock your stock market potential.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Updated by dev-srini on 03-01-2025 */}
                        <Grid container justifyContent="center" mt="30px">
                            <a href={backendHosts.SIGN_UP} target='_blank' rel="noopener noreferrer">
                                {/* On hover button highlight - 26/09/24 - dev-Srin */}
                                <Button className="wsubsbtn">
                                    Subscribe
                                </Button>
                            </a>

                        </Grid>
                    </Box>
                </Grid>

                <Grid item>
                    <Box className="wcrdbox2">
                        <Grid container justifyContent="space-between" mt="35px">
                            <Grid item>
                                {/* Updated by dev-srini on 03-01-2025 */}
                                <Typography className="expertTitle" align="left" ml="25px" mt="-10px">
                                    Expert <br/>Recommendation
                                </Typography>
                            </Grid>

                            <Grid item display="flex" alignItems="end">
                                <Typography className="wfr9t" align="right" mr="25px">
                                    {/* Updated by dev-srini on 03-01-2025 */}
                                    ₹ {expertAdvice?.amount} <span className="wyert"> / Year </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="10px">
                            <Grid item>
                                <Divider sx={{ background: '#fff', width: is1449px ? '306px' : '406px', height: '1px' }} />
                            </Grid>
                        </Grid>

                        {/* First Plan */}
                        {/* Updated by dev-srini on 03-01-2025 */}
                        <Grid container justifyContent="flex-start" alignItems="center" gap={2} mt="30px"
                            ml={is1449px ? "20px" : "25px"}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={is1449px && 30} />
                            </Grid>
                            <Grid item>
                                <Typography className="wenergt1" mt="0px" >
                                    Tailored advice from SEBI registered advisors.
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Second Plan */}
                        <Grid container justifyContent="flex-start" alignItems="center" gap={2} mt="10px"
                            ml={is1449px ? "20px" : "25px"}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={is1449px && 30} />
                            </Grid>
                            <Grid item>
                                <Typography className="wenergt" mt="0px" >
                                    Stay ahead with expert insights.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Updated by dev-srini on 03-01-2025 */}
                        <Grid container justifyContent="center" mt="30px">
                            <a href={backendHosts.EXPURL} target='_blank' rel="noopener noreferrer">
                                {/* On hover button highlight - 26/09/24 - dev-Srin */}
                                <Button className="wsubsbtn">
                                    Subscribe
                                </Button>
                            </a>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item>
                    <Box className="wcrdbox3">

                        <Grid container justifyContent="space-between" alignItems="center" mt={is1449px ? "40px" : "45px"}>

                            <Grid item ml="20px">
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <img src={icon1} alt="Icon" />
                                    </Grid>
                                    <Grid item>
                                        {/* Updated by dev-srini on 03-01-2025 */}
                                        <Typography className="wwelthval">
                                        Wealth Vaults
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Typography className="wfr9t" align="right" mr="25px">
                                    {/* Updated by dev-srini on 03-01-2025 */}
                                    ₹ {wealthValut?.amount} <span className="wyert"> / Year </span>
                                </Typography>
                            </Grid>
                        </Grid>


                        <Grid container justifyContent="center" mt='-13px' >
                            <Grid item>
                                <Divider sx={{ background: '#fff', width: is1449px ? '306px' : '406px', height: '1px', mt: is1449px ? "15px" : "20px" }} />
                            </Grid>
                        </Grid>

                        {/* First Plan */}
                        <Grid container justifyContent="flex-start" alignItems="center" gap={2} mt="30px"
                            ml={is1449px ? "20px" : "25px"} >
                            <Grid item>
                                <img src={icon} alt="Icon" width={is1449px && 30} />
                            </Grid>
                            <Grid item>
                                <Typography className="wenergt" >
                                    Energetic Vault.
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Second Plan */}
                        <Grid container justifyContent="start" alignItems="center" gap={2} mt="10px" ml={is1449px ? "20px" : "25px"}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={is1449px && 30} />
                            </Grid>
                            <Grid item pt="0px !important">
                                <Typography className="wenergt" >
                                    Traditional Vault.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Updated by dev-srini on 03-01-2025 */}
                        <Grid container justifyContent="center" mt="30px">
                            <a href={backendHosts.WEALTH_VAULT_URL} target='_blank' rel="noopener noreferrer">
                                {/* On hover button highlight - 26/09/24 - dev-Srin */}
                                <Button className="wsubsbtn">
                                    Subscribe
                                </Button>
                            </a>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default WebView
