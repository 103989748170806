import React from 'react'
import { Typography, Grid, } from '@mui/material'

const SimplyInvest = ({ blog }) => {
    return (
        <>
            {blog.content?.understandingTheBullForceSIPCalculator && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' mb='25px'>Understanding the BullForce SIP Calculator</h1>
                    <h2 className='wblgintr' mb='25px'>{blog.content.understandingTheBullForceSIPCalculator}</h2>
                </Grid>
            )}

            {blog.content?.sipExample && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' mb='25px'>Example: </h1>
                    <h2 className='wblgintr' mb='25px'>{blog.content.sipExample}</h2>
                </Grid>
            )}


            {/* {blog.content?.stepUpSIPCalculator && (
                <Grid item xs={12}>
                    <Typography className='wblgtit' gutterBottom>StepUp SIP Calculator: Boost Your Investment Strategy </Typography>
                    <Typography className='wblgintr' paragraph>{blog.content.stepUpSIPCalculator}</Typography>
                </Grid>
            )} */}


            {blog.content?.stepUpSIPCalculator && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' mb='25px'>
                        StepUp SIP Calculator: Boost Your Investment Strategy
                    </h1>
                    <h2 className='wblgintr' mb='25px'>
                        {blog.content.stepUpSIPCalculator.split("StepUp SIP Calculator").map((part, index) => (
                            <>
                                {part}
                                {index < blog.content.stepUpSIPCalculator.split("StepUp SIP Calculator").length - 1 && (
                                    <a href="https://bullforce.co/stepup-sip-calculator" target="_blank" rel="noopener noreferrer">
                                        StepUp SIP Calculator
                                    </a>
                                )}
                            </>
                        ))}
                    </h2>
                </Grid>
            )}


            {blog.content?.stepUpSIPExample && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' mb='25px'>Example: </h1>
                    <h2 className='wblgintr' mb='25px'>{blog.content.stepUpSIPExample}</h2>
                </Grid>
            )}

            {blog.content?.lumpsumCalculator && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' mb='25px'>Lumpsum Calculator: One-Time Investments Made Easy </h1>
                    <h2 className='wblgintr' mb='25px'>
                        {blog.content.lumpsumCalculator.split("Lumpsum Calculator").map((part, index) => (
                            <>
                                {part}
                                {index < blog.content.lumpsumCalculator.split("Lumpsum Calculator").length - 1 && (
                                    <a
                                        href="https://bullforce.co/lumpsum-calculator" target="_blank" rel="noopener noreferrer"
                                    >
                                        Lumpsum Calculator
                                    </a>
                                )}
                            </>
                        ))}
                    </h2>
                </Grid>
            )}

            {blog.content?.lumpsumExample && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' mb='25px'>Example: </h1>
                    <h2 className='wblgintr' mb='25px'>{blog.content.lumpsumExample}
                    </h2>
                </Grid>
            )}

            {blog.content?.brokerageCalculator && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' mb='25px'>Brokerage Calculator: Understand Your Costs </h1>
                    <h2 className='wblgintr' mb='25px'>
                        {blog.content.brokerageCalculator.split("Brokerage Calculator").map((part, index) => (
                            <>
                                {part}
                                {index < blog.content.brokerageCalculator.split("Brokerage Calculator").length - 1 && (
                                    <a
                                        href="https://bullforce.co/brokerage" target="_blank" rel="noopener noreferrer"
                                    >
                                        Brokerage Calculator
                                    </a>
                                )}
                            </>
                        ))}
                    </h2>
                </Grid>
            )}


            {blog.content?.brokerageExample && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' mb='25px'>Example: </h1>
                    <h2 className='wblgintr' mb='25px'>{blog.content.brokerageExample}</h2>
                </Grid>
            )}

            {blog.content?.callToAction && (
                <Grid item xs={12}>

                    <h2 className='wblgintr' mb='25px'>
                        {blog.content.callToAction.split("BullForce.co").map((part, index) => (
                            <>
                                {part}
                                {index < blog.content.callToAction.split("BullForce.co").length - 1 && (
                                    <a
                                        href="https://bullforce.co/" target="_blank" rel="noopener noreferrer"
                                    >
                                        BullForce.co
                                    </a>
                                )}
                            </>
                        ))}
                    </h2>
                </Grid>
            )}

        </>
    )
}

export default SimplyInvest