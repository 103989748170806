import React from 'react'
import { Typography, Grid, } from '@mui/material'

const NavigateStock = ({ blog }) => {
    return (
        <>
            {blog.content?.educateYourself && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>1. Educate Yourself</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.educateYourself}</h2>
                </Grid>
            )}

            {blog.content?.setClearInvestmentGoals && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>2. Set Clear Investment Goals</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.setClearInvestmentGoals}</h2>
                </Grid>
            )}


            {blog.content?.understandYourRiskTolerance && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>3. Understand Your Risk Tolerance</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.understandYourRiskTolerance}</h2>
                </Grid>
            )}

            {blog.content?.createADiversifiedPortfolio && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>4. Create a Diversified Portfolio</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.createADiversifiedPortfolio}</h2>
                </Grid>
            )}

            {blog.content?.conductThoroughResearch && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>5. Conduct Thorough Research</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.conductThoroughResearch}</h2>
                </Grid>
            )}

            {blog.content?.adoptALongTermPerspective && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>6. Adopt a Long-Term Perspective</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.adoptALongTermPerspective}</h2>
                </Grid>
            )}

            {blog.content?.utilizeTechnicalAnalysis && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>7. Utilize Technical Analysis</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.utilizeTechnicalAnalysis}</h2>
                </Grid>
            )}

            {blog.content?.implementRiskManagementStrategies && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>8. Implement Risk Management Strategies</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.implementRiskManagementStrategies}</h2>
                </Grid>
            )}

            {blog.content?.stayInformedAboutMarketTrends && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>9. Stay Informed About Market Trends</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.stayInformedAboutMarketTrends}</h2>
                </Grid>
            )}

            {blog.content?.seekProfessionalAdvice && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>10. Seek Professional Advice</h1>
                    <h2 className='wblgintr' paragraph>{blog.content.seekProfessionalAdvice}</h2>
                </Grid>
            )}

            {blog.content?.conclusion && (
                <Grid item xs={12}>
                    <h1 className='wblgtit' gutterBottom>
                        Conclusion
                    </h1>
                    <h2 className='wblgintr' paragraph>
                        {blog.content.conclusion}
                    </h2>
                </Grid>
            )}

            {blog.content?.callAction && (
                <Grid item xs={12}>
                    <h2 className='wblgintr' paragraph>
                        {blog.content.callAction.split("BullForce").map((part, index) => (
                            <>
                                {part}
                                {index < blog.content.callAction.split("BullForce").length - 1 && (
                                    <a
                                        href="https://bullforce.co/lumpsum-calculator" target="_blank" rel="noopener noreferrer"
                                    >
                                        BullForce
                                    </a>
                                )}
                            </>
                        ))}
                    </h2>
                </Grid>
            )}
        </>
    )
}

export default NavigateStock