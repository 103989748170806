import { Grid, Box, Typography, Divider, Button, useMediaQuery } from '@mui/material'

// images
import logo from '../../ui-assets/adcampone.png'
import icon from '../../ui-assets/subscriptionOne.js.svg'
import icon1 from '../../ui-assets/subscriptionTwo.js.svg'

import backendHosts from '../../ui-utils/apiConfig';

{/* Updated by dev-srini on 03-01-2025 */}
const MobileView = ({ expertAdvice, wealthValut, subscription }) => {
    const is440Px = useMediaQuery('(max-width: 440px)')

    return (
        <Grid className='mbgimgev' sx={{ minHeight: '100vh' }}>
            <Grid container bgcolor='#0B1420'>
                <Grid item>
                    <Box className='mbxvew'>
                        <img src={logo} alt='logo' width={160} style={{ marginLeft: '20px' }} />
                    </Box>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} padding={3}>
                    <Typography align="center" className='morprodv'>
                        Our Products
                    </Typography>

                    <Typography align="center" className='mnweratv' mt='15px'>
                        A new era of trading experience with Bullforce
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent='center' className='pacrdboxv'>
                <Grid item mb='5%'>
                    <Box className='mcrdboxv1'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                {/* Updated by dev-srini on 03-01-2025 */}
                                <Typography className="mbulftv" sx={{ mr : "30px" ,ml: '25px', mt: is440Px ? '20px' :'45px' }}>
                                    {subscription?.serivce}
                                </Typography>
                            </Grid>

                            <Grid item>
                                {/* Updated by dev-srini on 03-01-2025 */}
                                <Typography className="mfr9tv" sx={{ mr: is440Px ? '5px' : '25px', mt: is440Px ? '10px' :'45px', ml : is440Px && '25px' }}>
                                    ₹ {subscription?.amount} <span className="myertv"> / Year </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="20px">
                            <Grid item>
                                <Divider className='mdivdev' />
                            </Grid>
                        </Grid>

                        {/* First Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    25+ years, trusted by investors.
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Second Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2} paddingTop={0}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    Unlock your stock market potential.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Updated by dev-srini on 03-01-2025 */}
                        <Grid container justifyContent="center" mt="15px">
                            <a href={backendHosts.SIGN_UP} target='_blank' rel="noopener noreferrer">

                                <Button className="msubsbtnv">
                                    <Typography className="msubsbtntxtv">Subscribe</Typography>
                                </Button>
                            </a>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item mb='5%'>
                    <Box className='mcrdboxv2'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                {/* Updated by dev-srini on 03-01-2025 */}
                                <Typography className="expertTitleMobile" >
                                    Expert <br/>Recommendation
                                </Typography>
                            </Grid>

                            <Grid item display="flex" alignItems="end">
                                {/* Updated Price by dev-srini on 31-10-2024 */}
                                <Typography className="mfr9tv" sx={{ mr: '25px', mt: is440Px ? '20px' : '60px', ml : is440Px && "25px" , fontSize : "20px !important" }}>
                                    ₹  {expertAdvice?.amount} <span className="myertv"> / Year </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="10px">
                            <Grid item>
                                <Divider className='mdivdev' />
                            </Grid>
                        </Grid>

                        {/* First Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv" >
                                    Tailored advice from SEBI registered advisors.
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Second Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2} paddingTop={0}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    Stay ahead with expert insights.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Updated by dev-srini on 03-01-2025 */}
                        <Grid container justifyContent="center" mt={is440Px ? "5px" : "15px"} >
                            <a href={backendHosts.EXPURL} target='_blank' rel="noopener noreferrer">

                                <Button className="msubsbtnv">
                                    <Typography className="msubsbtntxtv">Subscribe</Typography>
                                </Button>
                            </a>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item mb='7%'>
                    <Box className='mcrdboxv3'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item ml='25px' display="flex" flexDirection="column" alignItems="center" mt = "15px">
                                <Grid display="flex" justifyContent="center" alignItems="center">
                                <img src={icon1} alt="Icon" width={34} style={{ margin: '10px 10px 0 0' }} />
                                <Typography className="mwelthvalv" mt='15px'> 
                                   Wealth Vaults
                                </Typography>
                                </Grid>
                            </Grid>

                            <Grid item>
                                {/* Updated Price by dev-srini on 31-10-2024 */}
                                <Typography className="mobileWVAmount" sx={{ mr: '25px' , mt : is440Px ? "10px" : "50px" , mb : is440Px ? "0px" : "20px", ml : is440Px && '25px' }}>
                                    {/* Updated Price by dev-trupti on 25-10-2024 */}
                                    ₹  {wealthValut?.amount} <span className="myertv"> / Year </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="10px">
                            <Grid item>
                                <Divider className='mdivdev' />
                            </Grid>
                        </Grid>

                        {/* First Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2}>
                            <Grid item >
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    Energetic Vault.
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Second Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2} paddingTop={0}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    Traditional Vault.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Updated by dev-srini on 03-01-2025 */}
                        <Grid container justifyContent="center" mt="15px">
                            <a href={backendHosts.WEALTH_VAULT_URL} target='_blank' rel="noopener noreferrer">

                                <Button className="msubsbtnv">
                                    <Typography className="msubsbtntxtv">Subscribe</Typography>
                                </Button>
                            </a>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MobileView