
import { useState, useEffect } from "react";
import { TextField, Button, Modal, Box, Typography, FormControlLabel, Checkbox, Grid, FormControl, InputAdornment, List, ListItem, FormHelperText, } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from "dayjs";
import './css/Modal.css'
import frame from '../../../ui-assets/Frame.png'
import { httpRequest } from '../../../ui-utils/api'

export default function AcademyModal({ setShowModal, setShower, setMySnack, snackeror, setError, defaultBasicCheckBox, defaultCompleteCheckBox, defaultProfessionalCheckBox, setDefaultBasicCheckBox, setDefaultProfessionalCheckBox, setDefaultCompleteCheckBox }) {





    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [language, setLanguage] = useState("");
    const [slot, setSlot] = useState("");
    const [date, setDate] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [errorMsg, setErrorMsg] = useState('');
    const [errtype, setErrtype] = useState(0)
    const [isHoveredHindi, setIsHoveredHindi] = useState(false)
    const [isHoveredEnglish, setIsHoveredEnglish] = useState(false)
    const [isHoveredslot1, setIsHoveredslot1] = useState(false)
    const [isHoveredslot2, setIsHoveredslot2] = useState(false)
    const [options, setOptions] = useState([])
    const [datemodel, setDatemodel] = useState(false)
    const [optError, setOptError] = useState("")


    console.log(defaultBasicCheckBox, defaultProfessionalCheckBox, defaultCompleteCheckBox)

    const basics = [
        'Introduction',
        'Swing Trading v/s Day Trading',
        'How part-time trading works',
        'Finding the best stock to Swing Trade',
        'Stock screening criteria',
        'Analyzing your stocks',
        'Stock analysis websites',
        'When to enter your position',
        'When to exit your position',
        'Trading platform overview and features'
    ]
    const professional = [
        'Trading strategies based on technical indicators',
        'Trading strategies based on patterns',
        'Trading with special patterns',
        'Trading strategies based on Elliot Wave',
        'Fibonacci trend line trading strategy',
        'Trading strategies based on Options strategies',
        'Trading strategy based on | Support resistance | Harmonic patterns | Gan fan concepts |  Candlestick pattern',
        'Trading strategy based on commodity',
        'Pivot points, Fibonacci, Trend line and time frame used by trader',
        'Trading strategies based on Forex',
        'Stock selection and entry exit criteria for intraday trading',
        'Trading phycology and qualities of good trader',
        'Stock selection and entry exit criteria for intraday trading',
        'Trading phycology and qualities of good trader'
    ]
    const complete = [
        'Course Introduction',
        'Options and the Stock Volatility, the VIX and more',
        'The basics of how Options work (Tickers, Call, Put and more)',
        'Pricing a Long or Short Call or Put',
        'Valuation and Analysis of Options',
        'Statistics and Greek formulas to analyze options',
        'How to value options using Binomial pricing',
        'How to value Options using Black Scholes',
        'How to value Options using Monte Carlo simulation',
        'Option Strategies',
        'Creating single option strategies',
        'Creating 1 Stock PLUS Single option strategies',
        'Creating single option strategies',
        'Creating 1 Stock PLUS Single option strategies'
    ]




    useEffect(() => {

        let updateOptions = []

        if (defaultBasicCheckBox) {
            updateOptions.push("Basic")
        }
        if (defaultProfessionalCheckBox) {
            updateOptions.push("Professional")
        }
        if (defaultCompleteCheckBox) {
            updateOptions.push("Complete")
        }

        setOptions(updateOptions)


        if (!defaultBasicCheckBox && !defaultCompleteCheckBox && !defaultProfessionalCheckBox) {
            setOptError('Select atleast one plan')
        } else {
            setOptError('')
        }

    }, [defaultBasicCheckBox, defaultProfessionalCheckBox, defaultCompleteCheckBox])



    const handleBasicPlan = (state) => {
        if (state === "Basic") {
            setDefaultBasicCheckBox(!defaultBasicCheckBox)
            console.log(defaultBasicCheckBox)
        }

    }

    const handleProfessionalPlan = (state) => {
        if (state === "Professional") {
            setDefaultProfessionalCheckBox(!defaultProfessionalCheckBox)
        }
    }

    const handleCompletePlan = (state) => {
        if (state === 'Complete') {
            setDefaultCompleteCheckBox(!defaultCompleteCheckBox)
        }
    }





    const cancelReset = () => {
        setName('')
        setEmail('')
        setPhone('')
        setDate('')
        setLanguage('')
        setSlot('')

        setDefaultBasicCheckBox(true)
        setDefaultProfessionalCheckBox(true)
        setDefaultCompleteCheckBox(true)

        setShowModal(false)

    }




    const openDateModal = async () => {
        setDatemodel(true)

    }







    const handleInputChange = (e) => {
        const value = e.target.value;

        setInputValue(value);

        if (value === "") {
            setDate(null);
            setErrtype(null);
            setErrorMsg("");
        } else {
            const regex = /^\d{4}-\d{2}-\d{2}$/;
            if (!regex.test(value)) {
                setErrtype(6);
                setErrorMsg("Invalid date format. Please use YYYY-MM-DD.");
                return;
            }

            const parsedDate = dayjs(value, "YYYY-MM-DD", true);

            if (parsedDate.isValid()) {
                if (parsedDate.isBefore(dayjs().startOf("day"))) {
                    setErrtype(6);
                    setErrorMsg("You cannot select a date before today.");
                } else {

                    setDate(parsedDate);
                    setErrtype(null);
                    setErrorMsg("");
                }
            } else {
                setErrtype(6);
                setErrorMsg("Invalid date format. Please use YYYY-MM-DD.");
            }
        }
    };



    const handleDateChange = (newDate) => {
        const today = dayjs().startOf("day");
        if (newDate.isBefore(today)) {
            setErrtype(6);
            setErrorMsg("You cannot select a date before today.");
        } else {

            setDate(newDate);

            setInputValue(newDate.format("YYYY-MM-DD"));
            setErrtype(null);


            setErrorMsg("");
            setDatemodel(false)
        }
    };


    const toggleLanguage = (lang) => {
        setLanguage((prev) => (prev === lang ? null : lang))
        setErrtype(0)
    };


    const toggleSlot = (selectedSlot) => {
        setSlot((prev) => (prev === selectedSlot ? null : selectedSlot))
        setErrtype(0)
    };


    const validateName = (value) => {
        if (value.trim() === "") {
            setErrtype(1);
            setErrorMsg("Name is required.");
            return false;
        } else if (!/^[a-zA-Z\s]+$/.test(value)) {
            setErrtype(1);
            setErrorMsg("Name must contain only letters and spaces.");
            return false;
        } else {

            setErrtype(0);
            setErrorMsg("");
            return true;
        }
    };

    const validateEmail = (value) => {
        if (value.trim() === "") {
            setErrtype(2);
            setErrorMsg("Email is required.");
            return false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            setErrtype(2);
            setErrorMsg("Please enter a valid email address.");
            return false;
        } else if (!/[a-zA-Z]/.test(value.split('@')[0])) {
            setErrtype(2);
            setErrorMsg("Email must contain  alphabetic character.");
            return false;
        } else {

            setErrtype(0);
            setErrorMsg("");
            return true;
        }
    };

    const validatePhone = (value) => {
        if (value.trim() === "") {
            setErrtype(3);
            setErrorMsg("Phone number is required.");
            return false;
        } else if (!/^\d{10}$/.test(value)) {
            setErrtype(3);
            setErrorMsg("Phone number must be exactly 10 digits.");
            return false;
        } else if (/^(\d)\1{9}$/.test(value)) {
            setErrtype(3);
            setErrorMsg("Phone number cannot consist of the same digit repeated.");
            return false;
        } else {

            setErrtype(0);
            setErrorMsg("");
            return true;
        }
    };

    const validateLanguage = () => {
        if (!language) {
            setErrtype(4);
            setErrorMsg("Please select a language.");
            return false;
        } else {
            setErrtype(0);
            setErrorMsg("");
            return true;
        }
    };

    const validateSlot = () => {
        if (!slot) {
            setErrtype(5);
            setErrorMsg("Please select a slot.");
            return false;
        } else {
            setErrtype(0);
            setErrorMsg("");
            return true;
        }
    };

    const validateDate = (date) => {
        if (!date) {
            setErrtype(6);
            setErrorMsg("Date is required.");
            return false;
        } else {

            setErrtype(0);
            setErrorMsg("");
            return true;
        }
    };








    const handleSubmit = async (e) => {
        e.preventDefault();


        if (!validateName(name)) return;
        if (!validateEmail(email)) return;
        if (!validatePhone(phone)) return;
        if (!validateDate(date)) return;
        if (!validateLanguage(language)) return;
        if (!validateSlot(slot)) return;

        if (options.length === 0) {
            setErrtype(8)
            setErrorMsg('Please select at least one plan')
            return
        } else {
            setErrtype(0)
            setErrorMsg('');

        }

        let payload = {
            name: name,
            email: email,
            phone: phone,
            tdate: inputValue,
            lang: language,
            slots: slot,
            coursename: options
        }
        console.log(payload)

        try {
            console.log(payload)

            const response = await httpRequest({
                endPoint: '/api/training/submit',
                method: 'post',
                instance: "instanceOne",
                requestBody: payload
            })
            if (response.status === 200) {
                setShower(true)
                setError('Your request has been submitted for review.')
                setMySnack(snackeror.mysucc)

                setDefaultBasicCheckBox(true)
                setDefaultProfessionalCheckBox(true)
                setDefaultCompleteCheckBox(true)
                setName("");
                setEmail("");
                setPhone("");
                setInputValue("");
                setSlot("");
                setLanguage("");
                setDate(null);
                setShowModal(false)
            } else {

                setShower(true)
                console.log(response)
                setError(response.data.message)
                setMySnack(snackeror.myerror)

            }

        } catch (error) {
            setErrtype(0)
            setError("Oops, something went wrong")
            setMySnack(snackeror.myerror)
        }


    };



    return (

        <div>

            <Modal open='true' >

                <Box
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '95%',
                        height: '92%',

                        outline: 'none',
                        background: '#FFFFFF',
                        padding: '10px',
                        borderRadius: '18px',
                        overflow: 'auto',



                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: '70%', padding: '15px', marginRight: '50px', marginTop: '10px' }}>
                            <Typography className="selecttitle" style={{ marginLeft: '20px' }}>
                                Selected programs for you
                                {optError && (
                                    <span className="errtxt"
                                        style={{ marginLeft: "20px", position: "fixed", fontSize: "30" }}>
                                        {optError}
                                    </span>
                                )}
                            </Typography>
                            {errtype === 8 &&
                                <FormHelperText className="errtxt" id="component-error-text">{errorMsg}</FormHelperText>
                            }


                            <div className="selectcard1">
                                <div style={{ display: 'flex', marginBottom: '2px', marginTop: '5px' }}>
                                    <FormControlLabel
                                        style={{ marginLeft: '5px' }}
                                        className="checkboxcard"

                                        control={
                                            <Checkbox

                                                checked={defaultBasicCheckBox}
                                                onClick={() => (handleBasicPlan('Basic'))}

                                                style={{ color: defaultBasicCheckBox && '#F74542' }}


                                            />
                                        }

                                    />
                                    <Grid className="selectbar">
                                        <Typography className="selectheading">
                                            Basic
                                        </Typography>
                                    </Grid>
                                </div>
                                <Grid container style={{ display: 'flex' }} >

                                    <Grid item style={{ flex: '50%' }} className="listone">

                                        <List  >
                                            {basics.slice(0, 5).map((basic, index) => (

                                                <ListItem key={index} style={{ padding: '0px' }} className="listicon">
                                                    <Typography className="listpoints" >{basic}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>


                                    </Grid>

                                    <Grid item style={{ flex: '50%', marginTop: '-10px' }} className="listtwo">
                                        <List >
                                            {basics.slice(5, 10).map((basic, index) => (
                                                <ListItem key={index} style={{ padding: '0px' }} className="listicon">
                                                    <Typography className="listpoints" >{basic}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="selectcard2">
                                <div style={{ display: 'flex', marginBottom: '2px', marginTop: '5px' }}>
                                    <FormControlLabel
                                        style={{ marginLeft: '5px' }}
                                        className="checkboxcard"
                                        control={
                                            <Checkbox
                                                checked={defaultProfessionalCheckBox}
                                                onClick={() => (handleProfessionalPlan('Professional'))}
                                                style={{ color: defaultProfessionalCheckBox && '#F74542' }}

                                            />
                                        }

                                    />
                                    <Grid className="selectbar">
                                        <Typography className="selectheading">
                                            Professional (this takes minimum 3 days of 2 hours each)
                                        </Typography>
                                    </Grid>
                                </div>
                                <Grid container style={{ display: 'flex', height: "50px" }} >

                                    <Grid item style={{ flex: '50%' }} className="listthree"  >
                                        <List>

                                            {professional.slice(0, 6).map((professional, index) => (

                                                <ListItem key={index} style={{ padding: '0px' }} className="listicon">
                                                    <Typography className="listpoints" >{professional}</Typography>
                                                </ListItem>

                                            ))}

                                        </List>
                                    </Grid>
                                    <Grid item style={{ flex: '50%' }} className="listfour"  >

                                        <List>
                                            {professional.slice(6, 12).map((professional, index) => (

                                                <ListItem key={index} style={{ padding: '0px' }} className="listicon">
                                                    <Typography className="prolistpoints" >{professional}</Typography>
                                                </ListItem>

                                            ))}
                                        </List>


                                    </Grid>
                                </Grid>
                            </div>

                            <div className="selectcard3">

                                <div style={{ display: 'flex', marginBottom: '2px', marginTop: '5px' }}  >
                                    <FormControlLabel
                                        style={{ marginLeft: '5px' }}
                                        className="checkboxcard"
                                        control={
                                            <Checkbox
                                                checked={defaultCompleteCheckBox}
                                                onClick={() => (handleCompletePlan('Complete'))}
                                                style={{ color: defaultCompleteCheckBox && '#F74542' }}

                                            />
                                        }

                                    />    <Grid className="selectbar">
                                        <Typography className="selectheading">
                                            Complete (this takes minimum 3 days of 2 hours each)
                                        </Typography>
                                    </Grid>

                                </div>
                                <Grid container style={{ display: 'flex' }} >
                                    <Grid style={{ flex: '50%' }} className="listfive">

                                        <List>
                                            {complete.slice(0, 6).map((complete, index) => (

                                                <ListItem key={index} style={{ padding: '0px' }} className="listicon">
                                                    <Typography className="listpoints" >{complete}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item style={{ flex: '50%' }} className="listsix">
                                        <List>
                                            {complete.slice(6, 12).map((complete, index) => (
                                                <ListItem key={index} style={{ padding: '0px' }} className="listicon">
                                                    <Typography className="listpoints" >{complete}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </div>

                        </div>

                        <div style={{ flex: '30%', padding: '8px' }}>


                            <Typography className="selecttitle" style={{ marginTop: '20px' }} >
                                Bullforce Academy & Learning Centre
                            </Typography>



                            <Grid>
                                <Grid style={{ width: "90%", marginLeft: "5px", marginTop: "10px" }}>
                                    <FormControl style={{ width: "100%" }} variant="standard">
                                        <TextField
                                            size="small"
                                            variant="standard"
                                            fullWidth
                                            sx={{
                                                "& .MuiInput-underline:before": {
                                                    borderBottomColor: "#92A4C1",
                                                    width: "120%",
                                                    height: "1px",
                                                    flexShrink: 0,
                                                    borderRadius: "8px",
                                                },
                                                "& .MuiInput-underline:after": {
                                                    borderBottomColor: "#92A4C1",
                                                    width: "120%",
                                                    height: "1px",
                                                    flexShrink: 0,
                                                    borderRadius: "8px",
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: errtype === 1 ? "#000" : "#333",
                                                    fontFamily: "cardiuma-regular !important",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "20px",
                                                },
                                            }}
                                            label="Name"
                                            value={name}
                                            InputProps={{
                                                style: {
                                                    width: "250px",
                                                    height: "22.561px",
                                                    flexShrink: 0,
                                                    color: "#0B1420",
                                                    fontFamily: "cardiuma-regular !important",
                                                    fontSize: "16px",
                                                    lineHeight: "normal",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                },
                                            }}
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={() => validateName(name)}
                                        />
                                        {errtype === 1 && (
                                            <FormHelperText className="errtxt" id="component-error-text">
                                                {errorMsg}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>



                            <Grid  >

                                <Grid style={{ width: '90%', marginLeft: '5px', marginTop: '10px' }}>
                                    <FormControl style={{ width: '100%' }} variant="standard">
                                        <TextField size="small" variant="standard"

                                            fullWidth
                                            sx={{
                                                '& .MuiInput-underline:before': {
                                                    borderBottomColor: '#92A4C1', width: '120%',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '##92A4C1', width: '120%',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: errtype === 2 ? "#000" : "#333",
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px'
                                                }
                                            }}
                                            label='Email'
                                            value={email}

                                            InputProps={{

                                                maxLength: 12,
                                                style: {
                                                    width: '250px', height: '22.561px',
                                                    flexShrink: 0,
                                                    color: '#0B1420',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '16px',
                                                    lineHeight: 'normal',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                }
                                            }}
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                                setErrtype(0)
                                            }}
                                            onBlur={() => validateEmail(email)}
                                        ></TextField>
                                        {errtype === 2 &&
                                            <FormHelperText className="errtxt" id="component-error-text">{errorMsg}</FormHelperText>
                                        }


                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid >

                                <Grid style={{ width: '90%', marginLeft: '5px', marginTop: '10px' }}>
                                    <FormControl style={{ width: '100%' }} variant="standard">
                                        <TextField size="small" variant="standard"
                                            fullWidth
                                            label='Phone no'
                                            value={phone}
                                            sx={{
                                                '& .MuiInput-underline:before': {
                                                    borderBottomColor: '#92A4C1',
                                                    width: '120%',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px',

                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#92A4C1',
                                                    width: '120%',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px',
                                                },
                                                '& .MuiInputAdornment-root': {

                                                    color: '#ffffff',

                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: errtype === 3 ? "#000" : "#333",
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px'
                                                }
                                            }}

                                            InputProps={{

                                                startAdornment: <InputAdornment position="start">
                                                    <Typography style={{ color: "black", fontFamily: 'cardiuma-regular !important', fontSize: '16px' }}>
                                                        +91
                                                    </Typography>
                                                </InputAdornment>,

                                                style: {
                                                    width: '250px', height: '22.561px',
                                                    flexShrink: 0,
                                                    color: '#0B1420',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '16px',
                                                    lineHeight: 'normal',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                }
                                            }}
                                            onChange={(e) => {
                                                setPhone(e.target.value)
                                                setErrtype(0)
                                            }}
                                            onBlur={() => validatePhone(phone)}
                                        ></TextField>
                                        {errtype === 3 &&
                                            <FormHelperText className="errtxt" id="component-error-text">{errorMsg}</FormHelperText>
                                        }


                                    </FormControl>
                                </Grid>
                            </Grid>



                            <Grid container style={{ width: '90%', marginLeft: '1px', marginTop: '10px' }}>

                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                        <TextField
                                            size="small"
                                            variant="standard"
                                            fullWidth
                                            label=" Select your training date"
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            onBlur={() => { validateDate(date) }}

                                            sx={{
                                                '& .MuiInput-underline:before': {
                                                    marginLeft: '3px',
                                                    borderBottomColor: '#92A4C1',
                                                    width: '300px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'
                                                },
                                                '& .MuiInput-underline:after': {
                                                    marginLeft: '3px',
                                                    borderBottomColor: '#92A4C1',
                                                    width: '300px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'

                                                },
                                                '& .MuiInputAdornment-root': {
                                                    color: '#ffffff',
                                                },
                                            }}

                                            InputLabelProps={{
                                                shrink: true,
                                                style: {

                                                    color: errtype === 7 ? "#000" : "#333",
                                                    marginLeft: '6px',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '17px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px',

                                                }

                                            }}

                                            InputProps={{


                                                style: {

                                                    flexShrink: 0,
                                                    color: '#0B1420 ! important',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '16px',
                                                    marginLeft: '5px',
                                                    lineHeight: 'normal ',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                },

                                            }}

                                        ></TextField>


                                        <img
                                            src={frame}
                                            alt="Calendar Icon"
                                            className="dateframe"
                                            onClick={openDateModal}
                                        />
                                    </div>


                                    {errtype === 6 && (
                                        <FormHelperText className="errtxt" id="component-error-text">
                                            {errorMsg}
                                        </FormHelperText>
                                    )}


                                </FormControl>


                                <Modal
                                    open={datemodel}
                                    onClose={() => setDatemodel(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    className="datemodal"
                                >
                                    <div
                                        style={{
                                            background: '#ffffff',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem style={{ background: '#ffffff' }}>
                                                <DateCalendar
                                                    value={date}
                                                    onChange={handleDateChange}
                                                    minDate={dayjs()}
                                                    sx={{
                                                        "& .MuiPickersDay-root": {
                                                            "&.Mui-selected": {
                                                                backgroundColor: '#F74542 !important',
                                                            },
                                                        },
                                                        ".MuiPickersCalendarHeader-root": {
                                                            fontSize: '1rem',
                                                        },
                                                        ".MuiPickersDay-root": {
                                                            fontSize: '1rem',
                                                        }
                                                    }}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </div>
                                </Modal>
                            </Grid>


                            <FormControl component="fieldset">
                                <Typography
                                    className="buttonheading"
                                    style={{
                                        color: errtype === 4 ? "#000" : "#333",
                                        marginTop: "10px",
                                        marginBottom: "5px",
                                    }}
                                >
                                    Language Selection
                                </Typography>

                                <div style={{ display: "flex", gap: "5px", marginTop: "5px" }}

                                >

                                    <Button
                                        value="hindi"
                                        onClick={() => {
                                            toggleLanguage("Hindi");

                                        }}
                                        className="buttoncard"
                                        style={{
                                            background: (language === "Hindi" || isHoveredHindi) && "#F3D45E",
                                        }}
                                        onMouseEnter={() => setIsHoveredHindi(true)}
                                        onMouseLeave={() => setIsHoveredHindi(false)}
                                        onBlur={validateLanguage}
                                    >
                                        <span className="buttontext">Hindi</span>
                                    </Button>

                                    <Button
                                        value="english"
                                        onClick={() => {
                                            toggleLanguage("English");

                                        }}
                                        className="buttoncard"
                                        style={{
                                            background: (language === "English" || isHoveredEnglish) && "#F3D45E",
                                        }}
                                        onMouseEnter={() => setIsHoveredEnglish(true)}
                                        onMouseLeave={() => setIsHoveredEnglish(false)}
                                        onBlur={validateLanguage}
                                    >
                                        <span className="buttontext">English</span>
                                    </Button>
                                </div>
                                {errtype === 4 && (
                                    <FormHelperText className="errtxt" id="component-error-text">
                                        {errorMsg}
                                    </FormHelperText>
                                )}
                            </FormControl>



                            <Grid container style={{ marginTop: "15px", marginLeft: "5px" }}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <Typography
                                            className="buttonheading"
                                            style={{ color: errtype === 5 ? "#F74542" : "#333" }}
                                        >
                                            Select Your Slots
                                        </Typography>
                                        <div style={{ display: "flex", gap: "5px", marginTop: "5px" }}>
                                            <Button
                                                value="10AM-12PM"
                                                onClick={() => {
                                                    toggleSlot("10AM-12PM");

                                                }}
                                                className="buttoncard"
                                                style={{
                                                    background: (slot === "10AM-12PM" || isHoveredslot1) && "#F3D45E",
                                                }}
                                                onMouseEnter={() => setIsHoveredslot1(true)}
                                                onMouseLeave={() => setIsHoveredslot1(false)}
                                                onBlur={validateSlot}
                                            >
                                                <span className="buttontext">10AM-12PM</span>
                                            </Button>

                                            <Button
                                                value="2PM-3PM"
                                                onClick={() => {
                                                    toggleSlot("2PM-3PM");

                                                }}
                                                className="buttoncard"
                                                style={{
                                                    background: (slot === "2PM-3PM" || isHoveredslot2) && "#F3D45E",
                                                }}
                                                onMouseEnter={() => setIsHoveredslot2(true)}
                                                onMouseLeave={() => setIsHoveredslot2(false)}
                                                onBlur={validateSlot}
                                            >
                                                <span className="buttontext">2PM-3PM</span>
                                            </Button>
                                        </div>


                                        {errtype === 5 && (
                                            <FormHelperText className="errtxt" id="component-error-text">
                                                {errorMsg}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>



                            <Grid container style={{ marginTop: '25px', }}>
                                <Grid item style={{ display: 'flex' }}>
                                    <Button onClick={() => {
                                        cancelReset()
                                        setErrtype(0)
                                        setErrorMsg('')

                                    }}
                                        className="cancelbutton">Back </Button>
                                    <Button className="submitbutton"
                                        onClick={handleSubmit}
                                    >Submit</Button>
                                </Grid>
                            </Grid>
                        </div>

                    </div >

                </Box>
            </Modal >
        </div>

    )
}