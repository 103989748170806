import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import {
    Typography, Grid, Hidden
} from '@mui/material'

import bull from "../../ui-assets/fullbull.svg"
import fotter_in from "../../ui-assets/fotter_in.svg"
import fotter_f from "../../ui-assets/fotter_f.svg"
import fotter_inst from "../../ui-assets/fotter_inst.svg"
import fotter_y from "../../ui-assets/fotter_y.svg"
import backendHosts from '../../ui-utils/apiConfig';  //dev-trupti on 22-11-2024
/*"@media(max-width:960px)": {
            visibility: 'hidden'
        }*/

const styles = theme => ({



    fotter_head: {
        color: '#F3B95B',
        fontFamily: 'cardiuma-M !important',
        fontSize: '20px !important',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: '18px !important',
        },


    },
    fotter_head1: {
        color: '#0B1420',
        fontFamily: 'cardiuma-M !important',
        fontSize: '20px !important',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: '18px !important',
        },

    },
    fotter_label: {
        color: '#ffffff !important',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '16px !important',
        paddingTop: '16px',
        cursor: 'pointer'

    },
    copyright_tit: {
        color: '#ffffff !important',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '16px !important',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: '12px !important',
        },


    },
    mainst: {
        background: '#0B1420', width: '100%', display: 'flex',
        justifyContent: 'space-evenly', padding: '3%',
        "@media(max-width:960px)": {
            background: '#0B1420', 
        }
    },
    fottertxt: {
        color: '#ffffff !important',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '12px !important',

    },
    fottertxtlink: {
        color: '#0083CA !important',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '12px !important',
        cursor:'pointer'

    },
    attinv:{
        color: '#FFFFFF !important',
        fontFamily: 'cardiuma-M !important',
        fontSize: '16px !important',
        

    },
    attcont:{
        color:'#F3D45E !important',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '14px !important',
        
    }


})
class fotter extends Component {

    constructor(props) {
        super(props)


        console.log(props)
        this.state = {


            myfotter: 0,

        }



    }
    componentDidMount = async () => {
        document.body.style.backgroundColor = "#0B1420"
        window.Changemyfotter = (myval) => {

            this.setState({ myfotter: myval });

   }

    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ display: this.state.myfotter === 1 ? 'none' : '' }}>
                <Hidden only={["xs", "sm"]}>
                    <Grid container sx={{backgroundColor:'#0B1420'}}>

                    
                    <Grid className={classes.mainst}>
                        <Grid style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={bull} height='95px' width='139px'></img>
                        </Grid>
                        <Grid style={{ width: '20%', }}>
                            <Typography className={classes.fotter_head} >Platform</Typography>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/diplomats' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Diplomats</a>
                            </div>
                            
                        <Typography className={classes.fotter_label} 
                        //dev-trupti on 22-11-2024
                        onClick={() => {
                            console.log(window.location.pathname)
                            if(window.location.pathname !=="/"){
                                window.location.href = `${backendHosts.WEBSITE}bullforce_franchisee`
                        }else{
                            window.GotoFrancey()
                        }}}
                        
                        >BullForce Franchisee</Typography>

                           
                         
                          {/*  <Typography className={classes.fotter_label} >Back office</Typography>*/}
                            
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='https://trade.bullforce.co/#!/app' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Trading Platform</a>
                            </div>
                         

                        </Grid>
                        <Grid style={{ width: '20%', }}>
                            <Typography className={classes.fotter_head} >Policies</Typography>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/Privacy_Policy' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Privacy Policy</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/refund_policy' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Refund Policy</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/termsandconditions' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Rights & Obligations(Terms and Conditions)</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/cdsl' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Rights & Obligations(CDSL)</a>
                            </div>
                            {/* Risk disclosure route added - 26-07-24 - Dev Srini */}
                            <Grid style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/risk_disclosure' className={classes.fotter_label} style={{ paddingTop: '15px' }}>Risk Disclosure</a>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '20%', }}>
                            <Typography className={classes.fotter_head} >Utilities</Typography>


                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/holiday_calendar' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Holiday Calendar</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/brokerage' className={classes.fotter_label} >Brokerage calculator</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/margin' className={classes.fotter_label} >Margin calculator</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/pricing' className={classes.fotter_label} >Pricing</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/contactus' className={classes.fotter_label} >Contact us</a>
                            </div>


                        </Grid>
                        <Grid style={{ width: '20%', }}>
                            <Typography className={classes.fotter_head1} >-</Typography>


                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/aboutus' className={classes.fotter_label} >About Us</a>
                            </div>

                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/bulletin' className={classes.fotter_label} >Bulletin</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/circular' className={classes.fotter_label} >Circular</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/issue_disclosures' className={classes.fotter_label} >Issue Disclosures</a>
                            </div>
                            <Typography className={classes.fotter_label} 
                              onClick={() => window.open('https://www.cdslindia.com/Investors/InvestorCharter.html', '_blank')}
                            
                            >Investor charter</Typography>
                        </Grid>

                    </Grid>

                    <Grid style={{width:'100%',display:'flex',justifyContent:'center', backgroundColor:'#0B1420'}}>
                    <Grid style={{ width: '85%' }}>
                        <Typography className={classes.attinv}>Attention Investor</Typography>
                        </Grid>
                       
                    </Grid>
                    <Grid style={{marginTop:'10px', width:'100%',display:'flex',justifyContent:'center', backgroundColor:'#0B1420'}}>
                   
                        <Grid style={{ width: '85%',padding:'5px',background:'#132031' }}>
                        <marquee>
                        <Typography className={classes.attcont}>1. For Stock Broking Transaction &#x27;Prevent unauthorised transactions in your account --&gt; Update your mobile numbers/email IDs with your stock brokers. Receive information of your transactions directly from Exchange on your mobile/email at the end of the day...Issued in the interest of Investors.  2. For Depository Transaction &#x27;Prevent Unauthorized Transactions in your demat account --&gt; Update your Mobile Number with your Depository Participant. Receive alerts on your Registered Mobile for all debit and other important transactions in your demat account directly from CDSL/NSDL on the same day...Issued in the interest of investors.  3. KYC is a one time exercise while dealing in securities markets - once KYC is done through a SEBI registered intermediary (Broker, DP, Mutual Fund etc.), you need not undergo the same process again when you approach another intermediary. 4. If you are subscribing to an IPO, there is no need to issue a cheque. Please write the Bank account number and sign the IPO application form to authorize your bank to make payment in case of allotment. In case of non allotment the funds will remain in your bank account.  </Typography>
                        
                        </marquee>
                        </Grid>
                    </Grid>
                    <Grid style={{marginTop:'15px', background: '#0B1420', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Grid style={{ width: '85%' }}>

                            <Typography className={classes.fottertxt}>
                                Maliram Makharia Finstock Private Limited.: Member of NSE and BSE  with NSE Membership No.: 10756, BSE Membership No.: 632, CDSL Registration No.:IN-DP-753-2023, SEBI Registration No.: INZ000295634. Registered office and correspondence office: Maliram Makharia Finstock Pvt. Ltd, 911, Tulsiani Chambers, 9th Floor, Free Press Journal Marg, Nariman Point, MUMBAI, MAHARSHTRA - 400021.
                            </Typography>
                            <Typography className={classes.fottertxt} style={{ marginTop: '3px' }}>
                                For any grievances related to Stock Broking/DP, please write to Grievances@bullforce.co, please ensure you carefully read the Risk Disclosure Document as prescribed by SEBI.
                            </Typography>

                        </Grid>


                    </Grid>
                    <Grid style={{ background: '#0B1420', width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '3px' }}>
                        <Grid style={{ width: '85%', display: 'flex' }}>
                            <Typography className={classes.fottertxt}>
                                Procedure to file a compliant on SEBI  </Typography>

                            <Typography className={classes.fottertxtlink} style={{ marginLeft: '2px', cursor: 'pointer' }}
                                //dev-trupti on 22-11-2024
                                onClick={() => window.open('https://scores.sebi.gov.in/', '_blank')}
                            >
                                SCORES
                            </Typography>
                          
                            <Typography className={classes.fottertxt} style={{ marginLeft: '2px' }}>
                                Register on the SCORES portal. Mandatory details for filing
                                complaints on SCORES: Name, PAN, Address, Mobile Number E-mail ID.
                            </Typography>

                         

                        </Grid>


                    </Grid>
                    <Grid style={{ background: '#0B1420', width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '3px' }}>
                        <Grid style={{ width: '85%', display: 'flex' }}>
                           

                            <Typography className={classes.fottertxt}>
                            Procedure to file Investor Dispute <span className={classes.fottertxtlink}
                                onClick={() => window.open( 'https://smartodr.in/login', '_blank')}
                            > SMARTODR </span>

                            </Typography>

                        </Grid>


                    </Grid>


                    <Grid style={{ background: '#0B1420', width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '3px' }}>
                        <Grid style={{ width: '85%', display: 'flex' }}>
                            <Typography className={classes.fottertxt}>
                                Benefits: Effective Communication, Speedy redressal of the grievances.  </Typography>


                        </Grid>


                    </Grid>
                    <Grid style={{ background: '#0B1420', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Grid style={{ width: '85%' }}>
                            <Grid style={{ display: 'flex', marginTop: '3px' }}>
                                {/* renamed to liabilities  - 26-07-24 - Dev Srini */}
                                <Typography className={classes.fottertxt} >
                                    Maliram Makharia Finstock Private limited makes no liabilities or representations, express or implied, on products
                                    offered through the platform. It accepts no liability for any damages or losses, however caused, in connection with the
                                    use of, or on the reliance of its product or related services. Unless otherwise specified, all returns, expense ratation,
                                    NAV, etc are historical and for illustrative purposes only. Future will vary greatly and depends on personal and market
                                    circumstances. The information provided by our website is educational only and is not investment or tax advice.
                                </Typography>

                            </Grid>
                            <Grid style={{ display: 'flex', marginTop: '3px' }}>
                                <Typography className={classes.fottertxt} >
                                    Investing in equities, derivatives, indices, futures and options are subject to market risks. Please read all scheme related
                                    documents carefully before investing. Past performance of the scheme is neither an indicator nor a guarantee of future
                                    performance.
                                </Typography>

                            </Grid>
                            <Grid style={{ display: 'flex', marginTop: '3px' }}>
                                <Typography className={classes.fottertxt} >

                                    Terms and conditions of the website/app are applicable. Privacy policy of the website is applicable.
                                </Typography>

                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid style={{ background: '#0B1420', width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>


                        <Grid style={{ width: '94%', borderTop: '1px solid #999999' }}>

                        </Grid>




                    </Grid>

                    <Grid style={{ background: '#0B1420', width: '100%', display: 'flex', justifyContent: 'center' }}>




                        <Grid style={{ width: '94%', marginTop: '2%', marginBottom: '2%', display: 'flex' }}>
                            <Grid style={{ width: '18%', display: 'flex', justifyContent: 'space-evenly' }}>
                                <img src={fotter_in} alt='linked in' style={{ cursor: 'pointer' }}
                                    onClick={() => window.open('https://www.linkedin.com/company/bullforce/', '_blank')}
                                >

                                </img>
                                <img src={fotter_f} alt='facebook' style={{ cursor: 'pointer' }}

                                    onClick={() => window.open('https://www.facebook.com/BullForceMMFPL/', '_blank')}
                                >

                                </img>
                                <img src={fotter_inst} alt='int' style={{ cursor: 'pointer' }}
                                    onClick={() => window.open('https://www.instagram.com/bullforce1_1/', '_blank')}
                                >

                                </img>
                                <img src={fotter_y} alt='youtube' style={{ cursor: 'pointer' }}
                                    onClick={() => window.open('https://www.youtube.com/@BullForce1', '_blank')}
                                >

                                </img>

                            </Grid>

                            <Grid style={{ width: '82%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography className={classes.copyright_tit}>Copyright © 2025 bullforce.co All rights reserved.</Typography>
                                <Typography style={{fontSize:'8px',color:'#0B1420'}}>1.6.2</Typography>
                            </Grid>
                           
                        </Grid>
                       
                    </Grid>
                    </Grid>
                </Hidden>
                <Hidden only={["lg", "md", "xl"]}>
                    <Grid style={{ width: '100%', padding: '16px', background: '#0B1420' }}>
                        <Grid style={{ width: '100%', display: 'flex' }}>
                            <Grid style={{ width: '65%', display: 'inline' }}>
                                <Typography className={classes.fotter_head} style={{ marginTop: '20px' }} >Programs</Typography>
                                
                                <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/diplomats' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Diplomats</a>
                            </div>
                            <Typography className={classes.fotter_label} 
                        //dev-trupti on 22-11-2024
                        onClick={() => {
                            console.log(window.location.pathname)
                            if(window.location.pathname !=="/"){
                                window.location.href = `${backendHosts.WEBSITE}bullforce_franchisee`
                        }else{
                            window.GotoFrancey()
                        }}}
                        >BullForce Franchisee</Typography>

                           
                         
                          {/*  <Typography className={classes.fotter_label} >Back office</Typography>*/}
                            
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='https://trade.bullforce.co/#!/app' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Trading Platform</a>
                            </div>
                                 </Grid>
                            <Grid style={{ paddingTop: '25px', width: '35%'}}>
                                <img src={bull} width='95px'></img>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', }}>
                            <Typography className={classes.fotter_head} style={{ marginTop: '20px' }} >Policies</Typography>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/Privacy_Policy' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Privacy Policy</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/refund_policy' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Refund Policy</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/termsandconditions' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Rights & Obligations(Terms and Conditions)</a>
                            </div>
                            
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/cdsl' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Rights & Obligations(CDSL)</a>
                            </div>
                            {/* Risk disclosure route added - 26-07-24 - Dev Srini */}
                            <Grid style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/risk_disclosure' className={classes.fotter_label} style={{ paddingTop: '15px' }}>Risk Disclosure</a>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%', }}>
                            <Typography className={classes.fotter_head} style={{ marginTop: '20px' }}>Utilities</Typography>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/holiday_calendar' className={classes.fotter_label} style={{ paddingTop: '15px' }} >Holiday Calendar</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/brokerage' className={classes.fotter_label} >Brokerage calculator</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/margin' className={classes.fotter_label} >Margin calculator</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/pricing' className={classes.fotter_label} >Pricing</a>
                            </div>



                          {/*  <Typography className={classes.fotter_label} >Margin calculator</Typography>
                            <Typography className={classes.fotter_label} >Pricing</Typography>*/}
                            <div style={{marginTop:'15px'}}>
                            <a href='/contactus' className={classes.fotter_label} >Contact us</a>
                            </div>

                        </Grid>
                        <Grid style={{ width: '100%', }}>
                            <Typography className={classes.fotter_head1} >-</Typography>
                            
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/aboutus' className={classes.fotter_label} >About Us</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/circular' className={classes.fotter_label} >Circular</a>
                            </div>
                            <div style={{ width: '100%', marginTop: '15px' }}>
                                <a href='/issue_disclosures' className={classes.fotter_label} >Issue Disclosures</a>
                            </div>
                            <Typography className={classes.fotter_label} 
                              onClick={() => window.open('https://www.cdslindia.com/Investors/InvestorCharter.html', '_blank')}
                            
                            >Investor charter</Typography>
                            

                        </Grid>
                        <Grid style={{marginTop:'10px', width:'100%',display:'flex',justifyContent:'center'}}>
                   
                   <Grid style={{ width: '95%',padding:'5px',background:'#132031' }}>
                   <marquee>
                   <Typography className={classes.attcont}>1. For Stock Broking Transaction &#x27;Prevent unauthorised transactions in your account --&gt; Update your mobile numbers/email IDs with your stock brokers. Receive information of your transactions directly from Exchange on your mobile/email at the end of the day...Issued in the interest of Investors.  2. For Depository Transaction &#x27;Prevent Unauthorized Transactions in your demat account --&gt; Update your Mobile Number with your Depository Participant. Receive alerts on your Registered Mobile for all debit and other important transactions in your demat account directly from CDSL/NSDL on the same day...Issued in the interest of investors.  3. KYC is a one time exercise while dealing in securities markets - once KYC is done through a SEBI registered intermediary (Broker, DP, Mutual Fund etc.), you need not undergo the same process again when you approach another intermediary. 4. If you are subscribing to an IPO, there is no need to issue a cheque. Please write the Bank account number and sign the IPO application form to authorize your bank to make payment in case of allotment. In case of non allotment the funds will remain in your bank account.  </Typography>
                   
                   </marquee>
                   </Grid>
               </Grid>
                        <Grid style={{ background: '#0B1420', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Grid style={{ width: '98%' }}>
                            <Grid style={{ display: 'flex', marginTop: '10px' }}>
                                {/* renamed to - 26-07-24 - Dev Srini */}
                                <Typography className={classes.fottertxt} >
                                    Maliram Makharia Finstock Private limited makes no liabilities or representations, express or implied, on products
                                    offered through the platform. It accepts no liability for any damages or losses, however caused, in connection with the
                                    use of, or on the reliance of its product or related services. Unless otherwise specified, all returns, expense ratation,
                                    NAV, etc are historical and for illustrative purposes only. Future will vary greatly and depends on personal and market
                                    circumstances. The information provided by our website is educational only and is not investment or tax advice.
                                </Typography>

                            </Grid>
                            <Grid style={{ display: 'flex', marginTop: '3px' }}>
                                <Typography className={classes.fottertxt} >
                                    Investing in equities, derivatives, indices, futures and options are subject to market risks. Please read all scheme related
                                    documents carefully before investing. Past performance of the scheme is neither an indicator nor a guarantee of future
                                    performance.
                                </Typography>

                            </Grid>
                            <Grid style={{ display: 'flex', marginTop: '3px' }}>
                                <Typography className={classes.fottertxt} >

                                    Terms and conditions of the website/app are applicable. Privacy policy of the website is applicable.
                                </Typography>

                            </Grid>
                        </Grid>


                    </Grid>
                        <Grid style={{ width: '94%',marginTop:"16px", borderTop: '1px solid #999999' }}>

</Grid>

<Grid style={{ width: '100%', marginTop: '2%', marginBottom: '2%', display: 'flex' ,justifyContent:'center'}}></Grid>
<Grid style={{ width: '94%',  display: 'flex' ,justifyContent:'space-evenly'}}>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                <img src={fotter_in} alt='linked in' style={{ cursor: 'pointer' }}
                                    onClick={() => window.open('https://www.linkedin.com/company/bullforce/', '_blank')}
                                >

                                </img>
                                <img src={fotter_f} alt='facebook' style={{ cursor: 'pointer' }}

                                    onClick={() => window.open('https://www.facebook.com/BullForceMMFPL/', '_blank')}
                                >

                                </img>
                                <img src={fotter_inst} alt='int' style={{ cursor: 'pointer' }}
                                    onClick={() => window.open('https://www.instagram.com/bullforce1_1/', '_blank')}
                                >

                                </img>
                                <img src={fotter_y} alt='youtube' style={{ cursor: 'pointer' }}
                                    onClick={() => window.open('https://www.youtube.com/@BullForce1', '_blank')}
                                >

                                </img>

                            </Grid>

                         
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center',padding:'16px', backgroundColor:'#0B1420'}}>
                                <Typography className={classes.copyright_tit}>Copyright © 2025 bullforce.co All rights reserved.</Typography>
                                
                            </Grid>
                </Hidden>
            </div>
        )
    }
}
export default (withStyles(styles)((fotter)));