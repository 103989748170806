import React from 'react'
import { Typography, List, ListItem, ListItemText, Divider, Grid, IconButton, ListItemIcon, useMediaQuery } from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material';

const RegistrationBlog = ({ blog }) => {
  return (
    <>
      {/* Section: What is BullForce */}
      {blog.content?.whatIsBullforce && (
        <Grid item xs={12}>
          <h1 className='wblgtit' gutterBottom >
            What is BullForce?
          </h1>
          <h2 className='wblgintr' paragraph>
            {blog.content.whatIsBullforce}
          </h2>
        </Grid>
      )}

      {blog.content?.keyFeatures && blog.content.keyFeatures.length > 0 && (
        <Grid item xs={12}>
          <h1 className='wblgtit' gutterBottom style={{'margin-block-start':'16px', 'margin-block-end':'16px'}}>
            Key Features of BullForce:
          </h1>
          <List>
            {/* start, srini - 22-01-2025 */}
            {blog.content.keyFeatures.map((feature, index) => (
              <ListItem key={index} sx={{ paddingLeft: 0, marginLeft: 0, display: 'flex', alignItems: 'flex-start' }}>
                <ListItemIcon sx={{ minWidth: '20px', paddingLeft: 0, marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                  <FiberManualRecord fontSize="inherit" style={{ color: '#000', fontSize: '10px' }} />
                </ListItemIcon>
                <ListItemText primary={feature} classes={{ primary: 'wblgintr' }} 
                sx={{ paddingLeft: 0, display: 'inline-block', whiteSpace: 'normal',marginTop: -1 }} />
              </ListItem>
              // end, srini - 22-01-2025
            ))}
          </List>
        </Grid>
      )}

      {blog.content?.steps && blog.content.steps.length > 0 && (
        <Grid item xs={12}>
          <h1 className='wblgtit' gutterBottom
            style={{'margin-block-start':'16px', 'margin-block-end':'16px'}}
          >
            The Registration Process: Step-by-Step
          </h1>
          <List>
            {blog.content.steps.map((stepObj, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`Step ${index + 1}: ${stepObj.step}`}
                  secondary={stepObj.description.split(/(BullForce|Register)/).map((part, partIndex) => (
                    <>
                      {part === "BullForce" ? (
                        <a
                          href="https://bullforce.co"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'blue', textDecoration: 'underline' }}
                        >
                          BullForce
                        </a>
                      ) : part === "Register" ? (
                        <a
                          href="https://bullforce.co/signup"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'blue', textDecoration: 'underline' }}
                        >
                          Register
                        </a>
                      ) : (
                        part
                      )}
                    </>
                  ))}
                  classes={{ primary: 'wblgintr', secondary: 'wblgnrmltxt' }}
                  secondaryTypographyProps={{
                    sx: { marginTop: 3, marginLeft: 2 }
                  }}
                />

              </ListItem>
            ))}
          </List>
        </Grid>
      )}

      {blog.content?.benefits && blog.content.benefits.length > 0 && (
        <Grid item xs={12}>
          <h1 className='wblgtit' gutterBottom
            style={{ 'margin-block-start': '16px', 'margin-block-end': '16px' }}
          >
            Benefits of Using BullForce
          </h1>
          <List>
            {/* start, bullet point, srini - 22-01-2025 */}
            {blog.content.benefits.map((benefit, index) => (
              <ListItem key={index} sx={{ paddingLeft: 0, marginLeft: 0, display: 'flex', alignItems: 'flex-start' }}>
                <ListItemIcon sx={{ minWidth: '20px', paddingLeft: 0, marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                  <FiberManualRecord fontSize="inherit" style={{ color: '#000', fontSize: '10px' }} />
                </ListItemIcon>
                <ListItemText primary={
                  benefit.split("registration").map((part, idx) => (
                    <>
                      {part}
                      {idx < benefit.split("registration").length - 1 && (
                        <a
                          href="https://bullforce.co/signup"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'blue', textDecoration: 'underline' }}
                        >
                          registration
                        </a>
                      )}
                    </>
                  ))
                } classes={{ primary: 'wblgintr' }}
                sx={{ paddingLeft: 0, display: 'inline-block', whiteSpace: 'normal',marginTop: -1 }}
                />
                {/* end, srini - 22-01-2025 */}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}

      {blog.content?.whyChoose && (
        <Grid item xs={12}>
          <h1 className='wblgtit' gutterBottom
            style={{'margin-block-start':'16px', 'margin-block-end':'16px'}}
          >
            Why Choose BullForce?
          </h1>
          <h2 className='wblgintr' paragraph style={{'margin-block-start':'16px', 'margin-block-end':'16px'}}>
            {blog.content.whyChoose}
          </h2>
        </Grid>
      )}

      {blog.content?.callAction && (
        <Grid item xs={12}>
          <h1 className='wblgtit' gutterBottom style={{'margin-block-start':'16px', 'margin-block-end':'16px'}}>
            Call to Action
          </h1>
          <h2 className='wblgintr' paragraph style={{'margin-block-start':'16px', 'margin-block-end':'16px'}}>
            {blog.content.callAction.split("BullForce").map((part, index) => (
              <>
                {part}
                {index < blog.content.callAction.split("BullForce").length - 1 && (
                  <a
                    href="https://bullforce.co/lumpsum-calculator" target="_blank" rel="noopener noreferrer"
                  >
                    BullForce
                  </a>
                )}
              </>
            ))}
          </h2>
        </Grid>
      )}

      {blog.content?.conclusion && (
        <Grid item xs={12}>
          <h1 className='wblgtit' gutterBottom style={{'margin-block-start':'16px', 'margin-block-end':'16px'}}>
            Conclusion
          </h1>
          <h2 className='wblgintr' paragraph style={{'margin-block-start':'16px', 'margin-block-end':'16px'}}>
            {blog.content.conclusion.split('BullForce').map((part, index) => (
              <>
                {index === 0 ? (
                  <>
                    <a href="https://bullforce.co" target="_blank" rel="noopener noreferrer">
                      BullForce
                    </a>
                    {part}
                  </>
                ) : (
                  part
                )}
              </>
            ))}

          </h2>
        </Grid>
      )}
    </>
  )
}

export default RegistrationBlog