import React, { Component } from 'react'
import { withStyles } from "@mui/styles";
import { httpRequest } from '../../ui-utils/api'
import { Typography, Grid, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import loadergif from '../../ui-assets/app_downloads_loader.gif'

const styles = theme => ({
    main_tit: {
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "32px !important",
        lineHeight: "18px !important",
        color: "#000000 !important",
        fontFamily: "cardiuma-M !important"
    },
    
    tit: {
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "16px !important",
        lineHeight: "18px !important",
        color: "#000000 !important",
        fontFamily: "cardiuma-M !important"
    },
    
    tit_label: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#333333 !important",
        textAlign: 'center',
        fontFamily: "cardiuma-regular !important",
        padding: '0px 5px'
    },

    tit_label_date: {
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#333333 !important",
        textAlign: 'center',
        fontFamily: "cardiuma-Bold !important",
        padding: '0px 5px',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "12px !important",
        }
    },
    tit_label1: {
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "28px !important",
        color: "#333333 !important",
        cursor: 'pointer',
        fontFamily: "cardiuma-M !important",
        padding: '0px 5px',
        "&:hover": {
            color: "#0088C9 !important",
            textDecoration: 'underline'
        },
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "13px !important",
        }
    },

    cir_card1:{
        width: '90%' ,
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', 
        }
    },

    cir_card2:{
        width: '20%', marginLeft: '1%',
        "@media only screen and (max-device-width: 480px)": {
            width: '20%',  marginLeft: '0%'
        }
    },

    cir_card3:{
        width: '20%', marginLeft: '1%',
        "@media only screen and (max-device-width: 480px)": {
            width: '30%',  marginLeft: '1%'
        }
    },

    cir_card4:{
        width: '80%', marginLeft: '1%',
        "@media only screen and (max-device-width: 480px)": {
            width: '70%',  marginLeft: '1%'
        }
    },
//dev-trupti on 30-12-2024
    buttonGridBig:{
        fontFamily: "cardiuma-M !important",
        fontSize:'16px !important',
        margin:'15px !important',
        color:'#1a3454 !important',
        cursor:'pointer !important',
        "&:hover": {
            color:'#000000 !important',
        },
        "@media only screen and (max-width: 480px)": {
            display: "none !important",
        }
    },

    buttonGridSmall:{
        fontFamily: "cardiuma-M !important",
        fontSize:'16px !important',
        margin:'14px !important',
        color:'#ffffff !important',
        cursor:'pointer !important',
        "@media only screen and (min-width: 481px)": {
            display: "none !important",
        }
    },

    buttons:{
        color:'#ffffff !important',
    }
});

class circular extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userdata: [],
            page: 1, //dev-trupti on 30-12-2024
            totalPages:0,
            hidenext: true,
            hideprev: true,
            load:false
        }
    }

    componentDidMount = async () => {
        this.setState({load:true})
        try {
            const cir_data = await httpRequest({
                endPoint: `api/circulars/list`,
                method: "post",
                instance: "instanceOne",
            });
            // Sorting the data based on dates
            // const sortedData = cir_data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
            this.setState({ userdata: cir_data.data, totalPages:cir_data.totalPages, hidenext: false, load:false})
        } catch (error) {
            this.setState({load:false}) //dev-trupti on 30-12-2024
            console.log("An Error Occured")
        }
    }
    
    componentDidUpdate = async(prevProps, prevState) => {
        if (prevState.page !== this.state.page) {
            try {
                const payload = {
                    page:this.state.page
                }
                console.log(payload,"payload")
                const cir_data = await httpRequest({
                    endPoint: `api/circulars/list`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody:payload
                });
                console.log("Circular data",cir_data)
                // Sorting the data based on dates
                // const sortedData = cir_data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                this.setState({ userdata: cir_data.data })
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  });
            } catch (error) {
                this.setState({load:false}) 
                console.log("An Error Occured", error)
            }
        }
    }

    pagination = (value) => {
        const { page, totalPages } = this.state
        console.log(this.state.page)
        if(value === "previous"){
            if(page === totalPages - 1 ) {
                this.setState(prevState => ({
                    page: prevState.page + 1, hideprev: false
                })); 
            }else if(page < totalPages){
                this.setState(prevState => ({
                    page: prevState.page + 1, hidenext:true
                })); 
            }
            else{
                console.log("over")
                
            }
        }else if(value === "next"){
            if(page === 2){
                this.setState(prevState => ({
                    page: prevState.page - 1, hidenext:false
                }));
            }
            else if(page !== 1){
            this.setState(prevState => ({
                page: prevState.page - 1, hideprev:true
            })); 
            

            }
            else{
                console.log("last page")
            }       
        } 
    }

    



    render() {
        const { classes } = this.props;
        const {hidenext, hideprev, load} = this.state //dev-trupti on 30-12-2024

        return (
            <div id="GridTop" style={{ width: '100%', marginTop: '25px', height: 'auto' }}>
                {load===true? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <img src={loadergif} alt="loading..." style={{ height: '100px', width: '100px' }} />
                </div>
                ):(<>
                
                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px', flexDirection:'column' }}>
                    <Grid className={classes.cir_card1}>
                        {this.state.userdata.map((label, index) => {


                            return (
                                <Grid key={index} style={{ width: '100%', display: 'flex', background: '#ffffff', alignItems: 'center', padding: '20px 2px', borderTop: '1.5px solid #DFE0EB' }}>
                                    <Grid className={classes.cir_card3}>
                                        <Typography className={classes.tit_label_date}>{label.c_date}</Typography>
                                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Grid style={{ width: '100px', padding: '4px', background: '#F2F2F2', borderRadius: '4px', marginTop: '5px' }}>
                                                <Typography className={classes.tit_label}>{label.exchange}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.cir_card4}>
                                        <Typography className={classes.tit_label1}

                                            onClick={(e) => window.open(label.link, '_blank')}

                                        >{label.subject}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        {/* dev-trupti on 30-12-2024 */}
                    <Grid style={{display:'flex', justifyContent:'center', marginTop:'20px'}}> 
                        {hideprev && <Grid><Link display='flex' className={classes.buttonGridBig} onClick={()=>{this.pagination("previous")}}> <ArrowBackIcon style={{ marginRight: '8px' }} /> Previous</Link></Grid>}   
                        {hidenext && <Grid><Link  display='flex' className={classes.buttonGridBig} onClick={()=>{this.pagination("next")}}> Next<ArrowForwardIcon style={{ marginRight: '8px' }} /></Link></Grid>}    
                        {hideprev && <Grid className={classes.buttonGridSmall}><Link display='flex' className={classes.buttons} onClick={()=>{this.pagination("previous")}}> <ArrowBackIcon style={{ marginRight: '8px' }} /> Previous</Link></Grid>}   
                        {hidenext && <Grid className={classes.buttonGridSmall}><Link  display='flex' className={classes.buttons} onClick={()=>{this.pagination("next")}}> Next<ArrowForwardIcon style={{ marginRight: '8px' }} /></Link></Grid>}    
                    </Grid>
                    </Grid>
                </Grid>

                </>)}
                {/* <Grid style={{ background: '#E9F4FF', display: 'flex', justifyContent: 'space-evenly', padding: '8px', marginTop: '35px', }}>
                    <Typography className='escalationhead' style={{ width: '10%' }} >Date</Typography>
                    <Typography className='escalationhead' style={{ width: '40%' }} >Subject</Typography>
                    <Typography className='escalationhead' style={{ width: '20%' }}>Department</Typography>
                    <Typography className='escalationhead' style={{ width: '10%' }} >Circular No</Typography>


                </Grid>
        */}
                

            </div>
        )
    }
}
export default (withStyles(styles)((circular)));